import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

function TitleDropdown({ currentTitle, sendTitle }){
	const titles = ['Mr', 'Mrs', 'Ms', 'Dr'];
	const [title, setTitle] = useState('');

    useEffect(() => {
        if (titles.includes(currentTitle)){
            setTitle(currentTitle);
        }
    }, [currentTitle]);

    const handleSelect = (eventKey) => {
		setTitle(eventKey);
        sendTitle(eventKey);
	};

    return (
        <Dropdown
            id="roleDropdown"
            className='py-1 w100 text-left'
            onSelect={handleSelect}
            defaultValue={currentTitle}
        >
            <Dropdown.Toggle id="titleToggle" className='w100 text-left'>
                {title || 'Select a title'}
            </Dropdown.Toggle>
            <Dropdown.Menu className='w100 text-left'>
                {titles.map((title_) => (
                    <Dropdown.Item key={title_} eventKey={title_}>{title_}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

TitleDropdown.propTypes = {
    currentTitle: PropTypes.string,
	sendTitle: PropTypes.func.isRequired
};

TitleDropdown.defaultProps = {
    currentTitle: 'Select a title'
};

export default TitleDropdown;
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import PropTypes from 'prop-types';


function ReferralStepInfo({ values, changeValue, sendNext, valueKeys }) {
    useEffect(() => {window.scroll(0,0)}, []);
    const { patientHistoryKey, mainDescriptionKey } = valueKeys;
    const [readyForNext, setReadyForNext] = useState(false);
    const maxLength = 1500;

    useEffect(() => {
        setReadyForNext(
            values.patientHistory !== '' && 
            values.mainDescription !== '' &&
            values.patientHistory.length < maxLength && 
            values.mainDescription.length < maxLength
            )
    }, [values.patientHistory, values.mainDescription]);


    const checkNext = () => {
        if (readyForNext) {
            sendNext();
        }
    }

    return (
		<div className='medr-layout text-center ref-info-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
            <Row>
                <Col className="py-1">
                    <h4>Enter referral information</h4>
                </Col>
            </Row>

            <Form className='text-left'>
                <Form.Group controlId="patientHistoryForm" className='mt-3'>
                    <Form.Label className='my-0'>
                        <h4>Patient history </h4>
                        <p className='medr-small'>{values.patientHistory.length} of {maxLength} characters used</p>
                        </Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Enter patient history"
                        rows={4}
                        defaultValue={values.patientHistory}
                        onChange={(e) => changeValue({ key: patientHistoryKey, value: e.target.value })}
                        isInvalid={values.patientHistory.length > maxLength}
                    />
                    <Form.Control.Feedback type="invalid">Max length of {maxLength} characters exceeded</Form.Control.Feedback>
                </Form.Group>
               

                <Form.Group controlId="mainDescriptionForm" className='mt-3'>
                    <Form.Label className='my-0'>
                        <h4>Reason for referral</h4>
                        <p className='medr-small'>{values.mainDescription.length} of {maxLength} characters used</p>
                    </Form.Label>
                    <Form.Control
                        required
                        as="textarea"
                        placeholder="Enter referral description"
                        rows={4}
                        defaultValue={values.mainDescription}
                        onChange={(e) => changeValue({ key: mainDescriptionKey, value: e.target.value })}
                        isInvalid={values.mainDescription.length > maxLength}
                    />
                    <Form.Control.Feedback type="invalid">Max length of {maxLength} characters exceeded</Form.Control.Feedback>

                </Form.Group>

                <Row className="text-center mt-4">
                    <Col>
                        <Button data-testid='next-button' className='sel-button w80' onClick={checkNext} disabled={!readyForNext}>Next</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

ReferralStepInfo.propTypes = {
	values: PropTypes.shape({
		patientHistory: PropTypes.string.isRequired,
		mainDescription: PropTypes.string.isRequired,
	}).isRequired, 
    changeValue: PropTypes.func.isRequired,
    sendNext: PropTypes.func.isRequired,
    valueKeys: PropTypes.shape({
        patientHistoryKey: PropTypes.string,
        mainDescriptionKey: PropTypes.string,
    })
	
}

ReferralStepInfo.defaultProps = {
    valueKeys: {
        patientHistoryKey: "patientHistory",
        mainDescriptionKey: "mainDescription",
    }
}

export default ReferralStepInfo;
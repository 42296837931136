// AUTH
export const CSRF = "api/v1/csrf/token/";
export const LOGIN = "api/v1/login/";
export const CHECK_AUTH = 'api/v1/user/check-auth/';
export const REGISTER = "api/v1/register/";
export const REGISTER_CHECK_EMAIL = "api/v1/register/check-email/";
export const REGISTER_CHECK_CLINIC = "api/v1/register/check-clinic/";
export const VERIFY_EMAIL = "api/v1/register/verify-email/";
export const LOGOUT = "api/v1/logout/";
export const ACC_DELETE = "api/v1/delete/";
export const RESET_PASSWORD = "api/v1/user/password/reset/";
export const NOTIFY_MEDR_FAILED_REGISTRATION = "api/v1/register/notify-medr-failed-registration/";

// USER
export const LOAD_USER = "api/v1/user/clinician-information/";
export const USER_UPDATE = "api/v1/user/update/";
export const USER_UPDATE_NOTIFICATIONS = "api/v1/user/notifications/update/";
export const USER_UPDATE_SUMMARY = "api/v1/user/update-clinician-description/";
export const USER_PROFILE_UPDATE = "api/v1/user/update/";
export const USER_RESET_SET_PASSWORD = "api/v1/user/password/reset/set-new/";
export const USER_UPDATE_PASSWORD = "api/v1/user/password/update/";
export const USER_DELETE = "api/v1/user/delete/";
export const USER_PIC_UPLOAD = "api/v1/user/profile-pic/upload/";
export const USER_SUMMARY_ANALYTICS = "api/v1/user/analytics-data/";
export const UPDATE_REFERRAL_CONTACTS = "api/v1/user/referral-contacts-update/";

// CLINICIANS
export const JOB_ROLES = 'api/v1/job-roles/';
export const INSURANCE = 'api/v1/insurance-agreements/';
export const CLINICS = 'api/v1/clinics/';
export const INSURANCE_UPDATE = 'api/v1/user/clinician-information/update-insurance-agreements/';
export const CRP_USER = "api/v1/user/clinician-information/";
export const CRP = "api/v1/clinician-information/";
export const CRP_USER_CREATE = "api/v1/user/clinician-information/create/";
export const CRP_USER_UPDATE = "api/v1/user/clinician-information/update/";
export const CRP_USER_DEACTIVATE = "api/v1/user/clinician-information/deactivate/";
export const CRP_USER_REMOVE_CLINIC = "api/v1/user/clinician-information/remove-clinic/";
export const CRP_USER_DELETE = "api/v1/user/clinician-information/delete/";
export const CLINICIAN = 'api/v1/clinician-information/';
export const USER_CRPS = 'api/v1/user-clinician-information/';
export const CLINICIANS = 'api/v1/clinician-list/';
export const CLINICIAN_ADD_FAVOURITE = 'api/v1/user/favourite-clinician/add/';
export const CLINICIAN_RM_FAVOURITE = 'api/v1/user/favourite-clinician/remove/';
export const INVITE_CLINICIAN = 'api/v1/user/invite-clinician/';

export const REQUEST_NEW_FEATURE = "api/v1/user/request-feature/";
export const REQUEST_HELP = "api/v1/user/request-help/";
// ADMIN
export const ADMIN_USERS = "api/v1/admin/user-profiles/";
export const ADMIN_CLINICIANS = "api/v1/admin/clinician-role-profiles/";
export const ADMIN_PATIENTS = "api/v1/admin/patients/";
export const ADMIN_REFERRALS = "api/v1/admin/referrals/";
export const ADMIN_USER_VERIFY = "api/v1/admin/set-verification/user-profile/";


// PATIENTS
export const GET_GPS = 'api/v1/get-gps/';
export const PATIENT = 'api/v1/patient/';
export const PATIENTS = 'api/v1/patient/list/';
export const PATIENT_CREATE = 'api/v1/patient/create/';
export const PATIENT_CREATE_BY_IMAGE = 'api/v1/patient/create/by-image/';
export const PATIENT_TOGGLE_ACTIVE = 'api/v1/patient/active-status/';
export const PATIENT_GET_CURRENT = "api/v1/patient/";
export const PATIENT_DELETE = 'api/v1/patient/delete/';
export const PATIENT_UPDATE = 'api/v1/patient/update/';
export const PATIENT_REFERRALS = 'api/v1/patient/referrals/';


// REFERRALS
export const REFERRAL_CREATE = "api/v1/referrals/create/";
export const REFERRAL = "api/v1/referrals/";
export const REFERRALS = "api/v1/referrals/list/";
export const REFERRAL_TOGGLE_ACCEPT = "api/v1/referrals/toggle-accept/";
export const REFERRAL_TOGGLE_COMPLETE = "api/v1/referrals/complete/";
export const REFERRAL_CANCEL = "api/v1/referrals/delete/";

import React, {useEffect, useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { clinicanProfileRanksPropType, funcPropType, boolPropType, numberPropType } from '../../constants/propTypes';
import ClinicianCardSmall from '../clinicians/ClinicianCardSmall';

function ClinicianCardList({ clinicians, onButtonClick, disabled, refreshClinicians, hoveredClinician, hoveredClinicianId }) {
    const [selectedButton, setSelectedButton] = useState();
    const [clinicianList, setClinicianList] = useState([]);

    useEffect(() => {
        if (clinicians===null || clinicians===undefined || clinicians.length===0){
            setClinicianList([]);
            return;
        }
        if (!disabled){
            setClinicianList(clinicians);
        }
    }, [clinicians]);


    /* TODO: review the code  */ 
    const btnCallback = (selectedId) => {
        setSelectedButton(selectedId);
        onButtonClick(selectedId);
    } 
    
	return (
		<div>
            {clinicianList ?
                clinicianList.map((clinician) => (  
                    <ClinicianCardSmall 
                        key={`ccs${uuidv4()}`} 
                        clinician={clinician} 
                        onButtonClick={btnCallback} 
                        disabled={disabled} 
                        selected={selectedButton === clinician.id} 
                        refreshList={refreshClinicians} 
                        hoveredClinician={hoveredClinician}
                        hoveredClinicianId={hoveredClinicianId}
                        />)
                ) :
                <Row className='text-center medr medr-layout'>
                    <Col>
                        <p>No clinicians have been found</p>
                    </Col>
                </Row>
            }
		</div>
	);
}

ClinicianCardList.propTypes = {
    clinicians: clinicanProfileRanksPropType.isRequired,
    onButtonClick: funcPropType.isRequired,
    refreshClinicians: funcPropType.isRequired,
    hoveredClinician: funcPropType.isRequired,
    disabled: boolPropType.isRequired,
    hoveredClinicianId: numberPropType,
};

ClinicianCardList.defaultProps = {
    hoveredClinicianId: null,
};


const mapStateToProps = state => ({
    clinicians: state.clinicians.clinicianList,
})


export default connect(mapStateToProps, {})(ClinicianCardList);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { strPropType } from '../../constants/propTypes';

function Message({ variant, message }) {
	
	if (message===null || message===undefined || variant===null || variant===undefined){
		return null;
	}	
	if (variant!=="success" && variant!=="info" && variant!=="danger"){
		return null;
	}
	const [showModal, setModal] = useState(false);
	
	useEffect(() => {
		setModal(true);
	}, [variant, message]);

	const handleClose = () =>{
		// reset the message and variant
		// dispatch({ type: MESSAGE_RESET });
		setModal(false);
	}	

	return (
		<Modal show={showModal} onHide={handleClose} style={{paddingBottom: '10px'}}>
			<Modal.Header>
				<Modal.Title className='medr-inv' id={`msg${variant}`}>{message}</Modal.Title>
			</Modal.Header>
			<Modal.Footer>
				 <Button variant="info" onClick={handleClose}>
					Hide
				</Button> 
			</Modal.Footer>
		</Modal>

	);
	
	// return <Alert variant={variant} id="appMessageBox" dismissible>	{message}</Alert>;
}


Message.propTypes = {
	variant: strPropType,
	message: strPropType,
};

Message.defaultProps = {
	variant: null,
	message: null,
};

const mapStateToProps = (state) => ({
	variant: state.message.variant,
	message: state.message.message,
});

export default connect(mapStateToProps, {})(Message);


import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clinicanProfilePropType, boolPropType, funcPropType} from "../../../constants/propTypes";
import { addClinicianToFavourites, removeClinicianFromFavourites } from "../../../actions/clinicianActions";
import  { getNameString } from "../../../actions/general";

function FavClinicianStarPopup({ clinician, showPopup, handleClose, requestMade}) {
    const dispatch = useDispatch();

    const { isFavourite, userProfile } = clinician;
    const { id } = userProfile;

    const onClick = () => {
        if (isFavourite===true){
            dispatch(removeClinicianFromFavourites(id));
            handleClose();
        } else {
            dispatch(addClinicianToFavourites(id));
            handleClose();
        }
        if (requestMade!==null){
            requestMade();
        }
    }
    
    return (
        <Modal show={showPopup} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='medr-inv'>{isFavourite ? "Remove" : "Add"} {getNameString(userProfile)} {isFavourite ? "from" : "to"} favourite clinicians?</Modal.Title>
                </Modal.Header>
                    <Modal.Footer>
                        <Button variant={isFavourite ? "danger" : "success"} onClick={onClick}>
                            {isFavourite ? "Remove" : "Add"}
                        </Button>
                        <Button variant="info" onClick={handleClose}>
                            Cancel
                        </Button>
                </Modal.Footer>
            </Modal>
    )
}


FavClinicianStarPopup.propTypes = {
    clinician: clinicanProfilePropType.isRequired,
    showPopup: boolPropType.isRequired,
    handleClose: funcPropType.isRequired,
    requestMade: funcPropType.isRequired,
};


export default FavClinicianStarPopup;
import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { patientPropType } from '../../constants/propTypes';
import PatientCard from '../../components/patient/PatientCard';
import { getCurrentPatient } from '../../actions/patientActions';

function PatientScreen({ currentPatient }) {
	const params = useParams();
	const { id } = params;
    const dispatch = useDispatch();

	const [patient, setPatient ] = useState(currentPatient);

    useEffect(() => {
        if (id!==null && id!==undefined){
            const ID = parseInt(id, 10);
            dispatch(getCurrentPatient(ID));
        }
    }, [id]); 

	useEffect(() => {
        if (currentPatient===null || currentPatient===undefined || currentPatient.id===null){return;}
        setPatient(currentPatient);
    }, [currentPatient]);


	return (
		<>
			<Row className="text-center py-1">
				<h1>Patient Information</h1>
			</Row>
			<Row className="text-center py-1">
				<Col>
					
					<PatientCard patient={patient}/>
				</Col>
			</Row>
		</>
	);
}


PatientScreen.propTypes = {
	currentPatient: patientPropType.isRequired,
};

const mapStateToProps = (state) => ({
	currentPatient: state.patients.currentPatient,
});

export default connect(mapStateToProps, {})(PatientScreen);
import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { funcPropType, strPropType } from "../../constants/propTypes";


function GenderSearchBar({ gender, changeValue, valueKey }){

    const [currentGender, setCurrentGender] = useState(gender)
    const options = [
        {label: "Male", value: "Male"},
        {label: "Female", value: "Female"},
        {label: "Other", value: "Other"},
    ];

    function checkgenderList(){
        const isInLabels = options.some(dict => dict.label === gender);
        if (!isInLabels){return '';};
        return gender;
    }

    useEffect(() => {
        setCurrentGender(checkgenderList());
    }, [gender]);

    const customStyles = {
        singleValue:(provided:any) => ({
          ...provided,
          color:'#247986',
        }),
      }

    return (
        <Select
            id='gender-search-bar'
            options={options}
            onChange={opt => changeValue({key: valueKey, value: opt.value})}
            placeholder={currentGender!=='' ? currentGender : "Select sex"}
            className='form-select'
            styles={customStyles}
        />
    )
}

GenderSearchBar.propTypes = {
    changeValue: funcPropType.isRequired,
    valueKey: strPropType,
    gender: strPropType
};

GenderSearchBar.defaultProps = {
    valueKey: 'sex',
    gender: 'Search sex'
}

export default GenderSearchBar;
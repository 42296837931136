import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { postUserProfilePicture } from '../../actions/userActions';

function ProfilePictureUpload() {
	const dispatch = useDispatch();

	const hiddenFileInput = useRef(null); 
	const [selectedFile, setSelectedFile] = useState(null);
	const [previewImage, setPreviewImage] = useState()

	const handleUploadImageClick = () => {
		hiddenFileInput.current.click();
	};

    const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
		setPreviewImage(URL.createObjectURL(event.target.files[0]));
	};

	const handleUpload = async () => {
		const formData = new FormData();
		formData.append('image', selectedFile);
		dispatch(postUserProfilePicture(formData));
	};

	return (
		<>	
			<Row className='text-left'>
				<Col>
					<h4 className="my-t-pad bluegreen">Upload Profile Picture</h4>
				</Col>
			</Row>
			<Row>
				{ /* TODO: allow only image files selection */ } 
				<input 
					style={{display:'none'}} 
					type="file" 
					ref={hiddenFileInput} 
					accept='image/*'
					onChange={handleFileChange}/>
			</Row>
			<Row className='text-center medr-layout sel-button-row'>
				<Col>
					<Button className='sel-button w80' variant='dark' onClick={handleUploadImageClick}><i className="fa-solid fa-image medr" /> Select image</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<div className='profile-selected-image'>
						{selectedFile!==null ?  <img src={previewImage} alt='Patient details preview' style={{ maxHeight: '250px', maxWidth: '250px'}}/> : null}
					</div>
					<div><small>{selectedFile ? selectedFile.name : null}</small></div>
				</Col>
			</Row>
			<Row className='text-center medr-layout sel-button-row mt-4'>
				<Col>
					<Button className="sel-button w80" type="Button" onClick={handleUpload}>Set new profile picture</Button>
				</Col>
			</Row>
		</>
	);
}

export default ProfilePictureUpload;
import React, { useEffect, useState } from 'react';
import { Table, Tabs, Tab  } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { referralsPropType } from '../../constants/propTypes';
import { getAdminReferralsList } from '../../actions/adminActions';
import { getNameString, reformatDateTime } from '../../actions/general';
import PaginationBar from '../app/PaginationBar';
import useAdminReferralsList from '../../hooks/admin/useAdminReferralsList';

function AdminReferralTable({ referralsList }) {
    const nReferralsWindow = 18
    const start = 0;
    const end = nReferralsWindow;
    const [openRxList, setOpenRxList] = useState([]);
	const [pendingRxList, setPendingRxList] = useState([]);
	const [acceptedRxList, setAcceptedRxList] = useState([]);

    const dispatch = useDispatch();
	const refreshReferrals = () => dispatch(getAdminReferralsList());
	useEffect(() => {refreshReferrals();}, [dispatch]);

    useEffect(() => {
        if (referralsList===null || referralsList===undefined || referralsList.length===0){
            setOpenRxList([]);
            setPendingRxList([]);
            setAcceptedRxList([]);
            return;
        }

		const openRx = referralsList.filter((referral) => !referral.specifiedClinician)
		const selectedRx = referralsList.filter((referral) => referral.specifiedClinician)
        const pendingRx = selectedRx.filter((referral) => !referral.targetHasAccepted)
        const acceptedRx = selectedRx.filter((referral) => referral.targetHasAccepted)
		setOpenRxList(openRx);
		setPendingRxList(pendingRx);
		setAcceptedRxList(acceptedRx);
    }, [referralsList])

	const [
        openReferrals,
        updateOpenReferralsSlice,
        openReferralsLength
    ] = useAdminReferralsList(openRxList, start, end);

	const [
        pendingReferrals,
        updatePendingReferralsSlice,
        pendingReferralsLength
    ] = useAdminReferralsList(pendingRxList, start, end);

	const [
        acceptedReferrals,
        updateAcceptedReferralsSlice,
        acceptedReferralsLength
    ] = useAdminReferralsList(acceptedRxList, start, end);
	
	const noReferralsDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No referrals to display at the moment</h4>
            </div>
        </div>
    )

	const openHeadersRow = (
					<tr>
						<th>Row</th>
						<th>Created On</th>
						<th>Px ID</th>
						<th>Referrer</th>
						<th>Description</th>
					</tr>
	)
	
	const openReferralsRows = openReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index+1}</td>
			<td>{referral.patient.id}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>
			{getNameString(referral.referrer.userProfile)} ({referral.referrer.userProfile.jobRole[0].jobTitle})
				</td>
			<td>{referral.mainDescription}</td>
		</tr>
	))
	const pendingHeadersRow = (
					<tr>
						<th>Row</th>
						<th>Created On</th>
						<th>Px ID</th>
						<th>Referrer</th>
						<th>Target Clinician</th>
					</tr>
	)

	const pendingReferralsRows = pendingReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index+1}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>{referral.patient.id}</td>
			<td>
				{getNameString(referral.referrer.userProfile)} ({referral.referrer.userProfile.jobRole[0].jobTitle})
			</td>
			<td>
				{getNameString(referral.targetClinician.userProfile)} ({referral.targetClinician.userProfile.jobRole[0].jobTitle})
			</td>
		</tr>
	))
	const acceptedHeadersRow = (
		<tr>
						<th>Row</th>
						<th>Created On</th>
						<th>Px ID</th>
						<th>Referrer</th>
						<th>Target Clinician</th>
						<th>Complete?</th>
					</tr>
	)
	const acceptedReferralsRows = acceptedReferrals.map((referral, index) => (
		<tr key={referral.id}>
			<td>{index+1}</td>
			<td>{reformatDateTime(referral.createdOn)}</td>
			<td>{referral.patient.id}</td>
			<td>
				{getNameString(referral.referrer.userProfile)} ({referral.referrer.userProfile.jobRole[0].jobTitle})
			</td>
			<td>
				{getNameString(referral.targetClinician.userProfile)} ({referral.targetClinician.userProfile.jobRole[0].jobTitle})
			</td>
			<td>{referral.completed ? "Y" : "N"}</td>
		</tr>
	))

    return (
			<Tabs
				defaultActiveKey="openReferrals"
				id="custom-tabs"
				className="custom-tabs mb-3"
				>
				<Tab eventKey="openReferrals" title="Open Referrals" className="custom-tabs">
                    {openReferralsLength===0 && noReferralsDisplay}
                    {openReferralsLength>0 && (
                        <Table striped bordered hover responsive className="table-sm">
                            <thead>
                                {openHeadersRow}
                            </thead>
                            <tbody>
                                {openReferralsRows}
                            </tbody>
                        </Table>
                    )}
                    {openReferralsLength > nReferralsWindow && (
                        <div className='mb-3'>
                            <PaginationBar arrayLength={openReferralsLength} setSliceValues={updateOpenReferralsSlice} windowSize={nReferralsWindow}/>
                        </div>
                    )}
				</Tab>
				<Tab eventKey="pendingReferrals" title="Pending Referrals" className="custom-tabs">
                    {pendingReferralsLength===0 && noReferralsDisplay}
                    {pendingReferralsLength>0 && (
                        <Table striped bordered hover responsive className="table-sm">
                            <thead>
                                {pendingHeadersRow}
                            </thead>
                            <tbody>
                                {pendingReferralsRows}
                            </tbody>
                        </Table>
                    )}
                    {pendingReferralsLength > nReferralsWindow && (
                        <div className='mb-3'>
                            <PaginationBar arrayLength={pendingReferralsLength} setSliceValues={updatePendingReferralsSlice} windowSize={nReferralsWindow}/>
                        </div>
                    )}
				</Tab>
				<Tab eventKey="acceptedReferrals" title="Accepted Referrals" className="custom-tabs">
                    {acceptedReferralsLength===0 && noReferralsDisplay}
                    {acceptedReferralsLength>0 && (
                        <Table striped bordered hover responsive className="table-sm">
                            <thead>
                                {acceptedHeadersRow}
                            </thead>
                            <tbody>
                                {acceptedReferralsRows}
                            </tbody>
                        </Table>
                    )}
                    {acceptedReferralsLength > nReferralsWindow && (
                        <div className='mb-3'>
                            <PaginationBar arrayLength={acceptedReferralsLength} setSliceValues={updateAcceptedReferralsSlice} windowSize={nReferralsWindow}/>
                        </div>
                    )}
				</Tab>
			</Tabs>
    )

}

AdminReferralTable.propTypes = {
	referralsList: referralsPropType.isRequired
};

const mapStateToProps = (state) => ({
	referralsList: state.admin.referralsList,
});

export default connect(mapStateToProps, {})(AdminReferralTable);

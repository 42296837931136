import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import ReferralStepPatient from '../../components/referrals/entry/ReferralStepPatient';
import ReferralStepInfo from '../../components/referrals/entry/ReferralStepInfo';
import ReferralStepSelectClinician from '../../components/referrals/entry/ReferralStepSelectClinician';
import ReferralStepReview from '../../components/referrals/entry/ReferralStepReview';
import { getCurrentPatient } from '../../actions/patientActions';
import { getCliniciansList } from '../../actions/clinicianActions';
import { patientPropType, jobRolesPropType, clinicanProfileRanksPropType } from '../../constants/propTypes';
import { SET_POSTCODE_SUCCESS } from '../../constants/searchConstants';
import { CURRENT_PATIENT_RESET } from '../../constants/patientConstants';


function ReferralEntry({ patient, jobRoles, clinicians }) {

	const dispatch = useDispatch();
    const search = useParams();

	const [pxNotPreselectSkip, setPxNotPreselectSkip] = useState(true);
	const [pxId, setPxId] = useState(0);

	const initData = {
		// Step 1
		selectedPatient: false,
		isNewPatient: false,
		patientId:pxId,
		patientSelected: null, // {address:{longitude:0, latitude:0}},
		currentPostcode:"",
		currentLatitude:0,
		currentLongitude:0,
		insuranceProviderId:0,

		// Step 2
		patientHistory: "",
		mainDescription: "",

		// Step 3
		jobRoleId: 0,
		jobRole: {},
		specifiedClinician:null,
		targetClinicianId:null,
		targetClinician:null,
	}

	const [requestData, setRequestData] = useState(initData);

	const {
		selectedPatient,
		isNewPatient,
		patientId,
		jobRoleId,
		insuranceProviderId,
		// targetClinicianId,
		patientSelected,
		currentPostcode,
		currentLatitude,
		currentLongitude,
	} = requestData;

	// STEP MANAGEMENT

	const minStep = 0;
	const maxStep = 3;
	const [activeStep, setActiveStep ] = useState(0);

	const handleNext = () => activeStep < maxStep ? setActiveStep((previousStep) => previousStep + 1) : null;
	const handleNextTo = (step) => setActiveStep(step);

	const handlePreviousButton = () => {
		if (activeStep > minStep){
			if (activeStep===maxStep){
				setRequestData({...requestData, isReview: false});
			}
			setActiveStep((previousStep) => previousStep - 1);   
		}
	};

	const updateTargetClinician = (id) => {
		const clin = id===0 ? null : clinicians.filter((clinician) => clinician.id===id)[0];

		setRequestData({
			...requestData, 
			targetClinicianId: id,
			targetClinician: clin,
			specifiedClinician: id!==0
		});
		handleNextTo(3);
	}

	const changeValue = (e) => {
		if (e.key==='targetClinicianId'){
			updateTargetClinician(e.value);
		}else {
			setRequestData({...requestData, [e.key]: e.value});
		}
	}
	

	// INITIALIZATION

	useEffect(() => {
		if (search.patientId){
			// if a patient id is found in the url, set the pxId
			setPxNotPreselectSkip(false);
			setPxId(parseInt(search.patientId, 10));
		} else {
			setPxId(0);
			dispatch({type: CURRENT_PATIENT_RESET});
		}
	}, []);


	// 	STEP 1 SELECT PATIENT

	const addNewPatientSuccess = (value) => {
		setRequestData({...requestData, isNewPatient: true, selectedPatient: value});
		if (value===true) { handleNextTo(1); }
	}

	useEffect(() => {
		if (isNewPatient===false && patientId!==null && patientId!==undefined && patientId!==0){
			dispatch(getCurrentPatient(patientId));
			setRequestData({...requestData, selectedPatient: true});
		}
	}, [patientId]); // advance after adding patient

	useEffect(() => {
		if (patient.id!==null){
			// if a patient is selected, update the data
			setRequestData({
				...requestData, 
				patientSelected: patient, 
				isNewPatient: false,
				patientId: patient.id,
				currentPostcode: patient.address.postcode, 
				currentLatitude: patient.address.latitude, 
				currentLongitude: patient.address.longitude, 
			})
			dispatch({
				type: SET_POSTCODE_SUCCESS,
				payload: {postcode: patient.address.postcode, lat: patient.address.latitude, lng: patient.address.longitude}
			});
		} else {
			// if a patient is not selected, reset the data
			setRequestData({
				...requestData, 
				patientSelected: initData.patientSelected, 
				isNewPatient: initData.isNewPatient,
				patientId: initData.patientId,
				currentPostcode: initData.currentPostcode, 
				currentLatitude: initData.currentLatitude, 
				currentLongitude: initData.currentLongitude, 
			})
		}
	}, [patient]); // update patient data

	useEffect(() => {
		if (patientSelected!==null && selectedPatient===true && pxNotPreselectSkip===true){
			handleNextTo(1);
		}}, [patientSelected]);

	// MANAGE REQ CLINICIAN

	useEffect(() => {
		if (jobRoleId!==0){
			const jobRole = jobRoles.filter((jr) => jr.id===jobRoleId)[0];
			setRequestData({...requestData, jobRole});
		}
	}, [jobRoleId]);

	const changeGps = (e) => {
		if (!e.searchLatitude || !e.searchLongitude || e.searchLatitude===undefined || e.searchLongitude===undefined || e.searchLatitude===0 || e.searchLongitude) return;
		setRequestData({...requestData, currentLatitude: e.searchLatitude, currentLongitude: e.searchLongitude});
	}


	const updateClinicians = () => {
        dispatch(getCliniciansList(jobRoleId, insuranceProviderId, currentLatitude, currentLongitude));
	}

    useEffect(() => {
		updateClinicians();
    }, [jobRoleId, insuranceProviderId, currentLongitude, currentLatitude]);


	// 	MANAGE STEPS

	useEffect(() => {
		if(pxId!==0 && patientSelected!==null && activeStep===0){handleNext();}
	},[requestData])

	// useEffect(() => {
	// 	console.log("Step: ",activeStep);
	// 	console.log("Req: ",requestData);
	// 	console.log("Reefvwwerfvq: ", targetClinicianId);
	// },[activeStep, requestData])
	
	
	return (
		<>
			{activeStep===0 ? <ReferralStepPatient currentPatientId={patientId} patientSelected={selectedPatient} changeValue={changeValue} addNewPatientSuccess={addNewPatientSuccess} /> : null}
			{activeStep===1 ? <ReferralStepInfo values={requestData} changeValue={changeValue} sendNext={handleNext}/> : null}
			{activeStep===2 ? <ReferralStepSelectClinician currentJrId={jobRoleId} currentPostcode={currentPostcode} changeValue={changeValue} changeGPS={changeGps} refreshClinicians={updateClinicians}/> : null}
			{activeStep===3 ? <ReferralStepReview values={requestData} /> : null}
 			
			<div className='medr-layout text-center ref-info-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
				<Row className='mt-5'>
					<Col>
						{
							activeStep !== 0 
							? <Button className="sel-button w80" variant="dark" onClick={handlePreviousButton}>Previous</Button>
							: null
						}
						{
							activeStep===0 && selectedPatient===true ?
							<Button className="sel-button w80" variant="primary" disabled={!selectedPatient} onClick={handleNext}>Next</Button> 
							: null
						}
					</Col>
				</Row>
			</div>
		</>
	);
}

ReferralEntry.propTypes = {
    clinicians: clinicanProfileRanksPropType.isRequired,
	jobRoles: jobRolesPropType.isRequired,
    patient: patientPropType,
};

ReferralEntry.defaultProps = {
	patient: {id: 0}
};


const mapStateToProps = (state) => ({
	clinicians: state.clinicians.clinicianList,
	jobRoles: state.clinicians.jobRoles,
    patient: state.patients.currentPatient,
})


export default connect(mapStateToProps, {})(ReferralEntry);
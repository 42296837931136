import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { getNameString, getClinicCenterStr } from '../../actions/general';
import { clinicanProfilePropType, boolPropType } from '../../constants/propTypes';
import FavClinicianStar from '../app/parts/FavClinicianStar';
import FavClinicianStarPopup from '../app/parts/FavClinicianStarPopup';


function UserProfileCard({ clinicianProfile, isUser }) {
    const [clinician, setClinician] = useState(clinicianProfile);
    const [displayPopup, setDisplayPopup] = useState(false);

    useEffect(() => {
        setClinician(clinicianProfile);
    }, [clinicianProfile]);
    
    if (clinician===null || clinician===undefined || clinician.id===null){
        return (<p>Profile not found</p>);
    }
    
    const { userProfile, clinic, insuranceAgreements } = clinician;
    const { clinicianDescription, jobRole, user, profilePicture, appointmentsContact } = userProfile;
    const { email } = user;
    
    function getInsurersList(){
        if (insuranceAgreements===null || insuranceAgreements===undefined || insuranceAgreements.length===0){
            return <p>No insurers listed</p>
        }
        return (
            <ul className='providers-list insurance-column-list'>
                {insuranceAgreements.map((agreement) => 
                    <li key={`ia${uuidv4()}`}>{agreement.provider}</li>
                    )}
            </ul>
        )
    }
    
    const getAppointmentsContacts = (ac) => {
        if (ac.email!==''){
            return <li key={`ac${uuidv4()}`}>Email: <a className='bluegreen' href={`mailto:"${ac.email}`}>{ac.email}</a></li>
        }
        if (ac.phoneNumber!==''){
            return <li key={`ac${uuidv4()}`}>Phone: <a className='bluegreen' href={`tel:"${ac.phoneNumber}`}>{ac.phoneNumber}</a></li>
        }
        return null;
    }
    

    function getAppointmentsContactsLi(){
        if (appointmentsContact===null || appointmentsContact===undefined || appointmentsContact.length===0){
            return <p>No appointment contacts listed</p>
        }
        
        return <ul key='app-contact-list'>{appointmentsContact.map((ac) => getAppointmentsContacts(ac))}</ul>
    }
    
    const profilePicturePath = profilePicture!==null ? profilePicture : "/logos/color1_logo_transparent_background.png";
    
    const jrList = jobRole.map((jr) => <h6 className='mt-1 mb-2 bluegreen' key={`jr${uuidv4()}`}>{jr.jobTitle}</h6>);
    
	return (
        <div className='medr-rounded bg-medr-gray p-3'>
            <div className='row'>
                <div className='col-auto'>
                    <img className="profile-picture" src={profilePicturePath} alt='profile' />
                </div>
                <div className='col flex-grow-1'>
                    {displayPopup && <FavClinicianStarPopup clinician={clinicianProfile} showPopup={displayPopup} handleClose={() => setDisplayPopup(false)} requestMade={() => {}} />}
                    <h4 className='mt-2 mb-1 bluegreen'>{isUser ? null : <FavClinicianStar clinician={clinicianProfile} togglePopup={() => setDisplayPopup(true)} />} {getNameString(userProfile)}</h4>
                    {jrList}
                </div>
            </div>
            {clinicianDescription!=='' ? (
                <>
                    <hr />
                    <Row>
                        <Col>
                            <p className='my-2 px-2 text-left bluegreen'>{clinicianDescription}</p>
                        </Col>
                    </Row>
                </>
            ): null}
            <hr />
            <Row>
                {/* TODO: phone number */}
                <Col className='bluegreen text-left'>
                    <h4 className='bluegreen'>Contact details</h4>
                    <div><a className='bluegreen' href={`mailto:"${email}`}>{email}</a></div>
                    <div>{getClinicCenterStr(clinic)}</div>
                </Col>
                <Col className='bluegreen text-left'>
                    <h4 className='bluegreen'>Appointment contacts</h4>
                    {getAppointmentsContactsLi()}
                </Col>
            </Row>
            <Row>
                <Col className='bluegreen text-left mt-2'>
                    <h4 className='bluegreen'>Providers covered</h4>
                </Col>
            </Row>
            <Row>
                <Col className='bluegreen text-left'>
                    {getInsurersList()}
                </Col>
            </Row>

        </div>
	);
}

UserProfileCard.propTypes = {
	clinicianProfile: clinicanProfilePropType.isRequired,
    isUser: boolPropType,
}

UserProfileCard.defaultProps = {
    isUser: false,
}


export default UserProfileCard;

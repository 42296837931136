import React, { useEffect, useState } from "react";
import { Button  } from 'react-bootstrap';
import { clinicanProfileRankPropType, funcPropType, boolPropType, numberPropType } from '../../constants/propTypes';
import { getNameString, getClinicCenterStr } from "../../actions/general";
import FavClinicianStar from "../app/parts/FavClinicianStar";
import FavClinicianStarPopup from "../app/parts/FavClinicianStarPopup";

function ClinicianCardSmall({ clinician, onButtonClick, disabled, selected, refreshList, hoveredClinician, hoveredClinicianId }){

    ClinicianCardSmall.defaultProps = {
        selected: false
    }

    if (clinician===null || clinician===undefined){
        return (
            <div className='medr rounded mb-2'>
                <h4 className='p-2'>Error retrieving clinician details</h4>
            </div>
        )
    };
    const { id, distance } = clinician;

    const clinicianRef = `clinician_car_${id}` 
    
    const [clinicianIsHovered, setClinicianIsHovered] = useState(false);
    const [displayPopup, setDisplayPopup] = useState(false);

    const togglePopup = () => setDisplayPopup(true);
    const onMouseEnter = () => hoveredClinician(clinician);
    const onMouseLeave = () => hoveredClinician(null);

    useEffect(() => {
            setClinicianIsHovered(hoveredClinicianId === clinician.id);
    }, [hoveredClinicianId, clinician.id]);
    
    return (
        <>
            {displayPopup && <FavClinicianStarPopup clinician={clinician} showPopup={displayPopup} handleClose={() => setDisplayPopup(false)} requestMade={refreshList} />}
            <Button className={`medr-rounded-inv bg-medr-gray mb-2 select-clinicial-card ${selected || clinicianIsHovered ? 'active' : ''}`} style={{width:'100%'}}
                    id={clinicianRef} 
                    onClick={() => onButtonClick(id)} 
                    disabled={disabled}>

                <div className='d-flex align-items-start'>
                    <div className='p-1' style={{width:'70px', minWidth:'70px'}}>
                        <div>{distance}km</div>
                        <div className='gap'> </div>
                        <FavClinicianStar togglePopup={togglePopup}  clinician={clinician}/>
                    </div>
    
                    <div className='p-1' style={{width:'10px'}}> </div>
                    <div className='p-1 text-left' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                        <b>{getNameString(clinician.userProfile)}</b>
                        <div className='gap'> </div>
                        <b>{getClinicCenterStr(clinician.clinic)}</b>
                    </div>
                </div>             
            </Button>
        </>
    )
};

ClinicianCardSmall.propTypes = {
    clinician: clinicanProfileRankPropType.isRequired,
    onButtonClick: funcPropType.isRequired,
    disabled: boolPropType.isRequired,
    selected: boolPropType,
    refreshList: funcPropType.isRequired,
    hoveredClinician: funcPropType.isRequired,
    hoveredClinicianId: numberPropType
};

ClinicianCardSmall.defaultProps = {
    hoveredClinicianId: null
};

export default ClinicianCardSmall;
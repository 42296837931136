import React, { useEffect, useState } from "react";
import { Row,  Col, Button  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { patientPropType, jobRolePropType, clinicanProfileRankPropType, clinicianProfilesPropType } from "../../../constants/propTypes";
import { getJobRoleString, getNameString, getClinicCenterStr } from "../../../actions/general";
import { postCreateReferral } from "../../../actions/referralActions";
import ReferralSentPopup from "../ReferralSentPopup";


const validateReferralData = (values) => {
    const errorReturn = {valid: false, data: null};

    if (values===null || values===undefined) return errorReturn;
    
    const { patientSelected, patientHistory, mainDescription, jobRole, targetClinician, specifiedClinician } = values;
    const insuranceProvider = patientSelected?.insurancePolicy?.provider ?? "Self-Payer";

    if (patientSelected===null || patientSelected===undefined ||
        patientHistory===null || patientHistory===undefined ||
        mainDescription===null || mainDescription===undefined ||
        jobRole===null || jobRole===undefined ||
        specifiedClinician===null || specifiedClinician===undefined
        )
     return errorReturn;

     if (specifiedClinician===true && (targetClinician===null || targetClinician===undefined)) return errorReturn;

    const data = {
        patientSelected,
        patientHistory,
        mainDescription,
        jobRole,
        targetClinician,
        specifiedClinician,
        insuranceProvider
    }

    return {valid: true, data};
};

const failedDataValidation = () => (
        <div className='medr-rounded'>
            <Row>
                <Col>
                    <h4>Error missing data</h4>
                </Col>
            </Row>
        </div>
    );

const validateClinicianProfiles = (clinicianProfiles) => {
    if (clinicianProfiles===null || clinicianProfiles===undefined) return false;
    if (clinicianProfiles.length===0) return false;
    return true;
};

const failedClinicianProfilesValidation = () => (
        <div className='medr-rounded'>
            <Row>
                <Col>
                    <h4>Error loading user clinician profiles</h4>
                </Col>
            </Row>
        </div>
);

const getRequestData = (values, crpId) => {
    let requestData = {
        patientId: values.patientSelected.id,
        crpId,
        jobRoleId: values.jobRole.id,
        mainDescription: values.mainDescription,
        patientHistory: values.patientHistory,
        specifiedClinician: values.specifiedClinician,
    };

    if (values.specifiedClinician){
        requestData = {...requestData, receiverCrpId: values.targetClinician.id};
    }
    return requestData;
};


function RegisterReview({ values, refId, clinicianProfiles}){
    useEffect(() => {window.scroll(0,0)}, []);

    const validClinicianProfiles = validateClinicianProfiles(clinicianProfiles);
    if (!validClinicianProfiles){return failedClinicianProfilesValidation();}

    
    const lg = 4;
    const md  = 6;
    const sm  = 12;

    const dispatch = useDispatch();
    const [referralMade, setReferralMade] = useState(false);

    const crp = clinicianProfiles[0];

    const {valid, data} = validateReferralData(values);
    if (!valid) {return failedDataValidation();}

    const {
        patientSelected,
        patientHistory,
        mainDescription,
        jobRole,
        targetClinician,
        specifiedClinician,
        insuranceProvider
    } = data;

    const navigate = useNavigate();

    const createReferralSuccess = (success) => {if (success){setReferralMade(true);}}

    const exitReferral = () => {
        if (refId!==null){
            navigate("/dashboard");
        }
    }
    
    const onSubmit = (crpId) => {
        const formData = getRequestData(values, crpId);
        dispatch(postCreateReferral(formData, createReferralSuccess))
    }
    
    // useEffect(() => {
    //     if (referralMade===true && refId!==null){
    //         navigate("/referrals?tab=sent");
    //     }
    // }, [refId, referralMade]);

    return (
        <div className='medr-layout text-center' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
            <ReferralSentPopup showModal={referralMade} handleClose={exitReferral} />
            <Row>
                <Col>
                    <h4>Review referral data</h4>
                </Col>
            </Row>
            <div className='medr-rounded bg-medr-gray bluegreen mt-4 text-left'>
                <Row>
                    <Col className="text-center">
                        <h4 className="bluegreen">Referral summary</h4>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={lg} md={md} sm={sm}>
                        Patient:
                    </Col>
                    <Col>
                        {getNameString(patientSelected)}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={lg} md={md} sm={sm}>
                        Insurance Provider:
                    </Col>
                    <Col>
                        {insuranceProvider}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={lg} md={md} sm={sm}>
                        History:
                    </Col>
                    <Col>
                        {patientHistory}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={lg} md={md} sm={sm}>
                        Reason for referral:
                    </Col>
                    <Col>
                        {mainDescription}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={lg} md={md} sm={sm}>
                        Clinician required:
                    </Col>
                    <Col>
                        {getJobRoleString(jobRole)}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={lg} md={md} sm={sm}>
                        Clinician selected:
                    </Col>
                    <Col>
                        {specifiedClinician===true ? `${getNameString(targetClinician.userProfile)} - ${getClinicCenterStr(targetClinician.clinic)}` : `Referral open to all ${getJobRoleString(jobRole)}`}
                    </Col>
                </Row>
            </div>
            <Row className="mt-4">
                <Col>
                    <Button className="sel-button w80" variant='success' type='submit' onClick={() => onSubmit(crp.id)}>Send referral</Button>
                </Col>
            </Row>
        </div>
    )
};


RegisterReview.propTypes = {
    values: PropTypes.shape({
        patientSelected: patientPropType.isRequired,
        patientHistory: PropTypes.string.isRequired,
        mainDescription: PropTypes.string.isRequired,
        jobRole: jobRolePropType.isRequired,
        targetClinician: clinicanProfileRankPropType,
        specifiedClinician: PropTypes.bool.isRequired,
    }).isRequired,
    refId: PropTypes.number,  
    clinicianProfiles:clinicianProfilesPropType,
};

RegisterReview.defaultProps = {
    refId: null,
    clinicianProfiles: [],
};

const mapStateToProps = state => ({
	refId: state.referrals.referral.id,
    clinicianProfiles: state.profile.clinicianProfiles,
})


export default connect(mapStateToProps, {})(RegisterReview);

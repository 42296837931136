import { useState, useEffect } from "react";

const usePatientsList = (patientsList, start, end, filter='') => {
    const [patients, setPatients] = useState([]);
    const [startSlice, setStartSlice] = useState(start);
    const [endSlice, setEndSlice] = useState(end);
    const patientsLength = patientsList.length;

    const updateSlice = (startInd, endInd) => {
        setStartSlice(startInd);
        setEndSlice(endInd);
    };

    useEffect(() => {
        if (!patientsList || patientsList.length === 0) {
            setPatients([]);
            return;
        }
        let slice;

        const filterLower = filter.toLowerCase();

        if (filterLower===''){
            slice = patientsList.slice(startSlice, endSlice);
        } else {
            const filtPx = patientsList.filter(
                (patient) => 
                    `${patient.title} ${patient.firstName} ${patient.lastName}`.toLowerCase().includes(filterLower)             
                    )
            slice = filtPx.slice(startSlice, endSlice);
        }

        setPatients(slice);
    }, [patientsList, startSlice, endSlice, filter]);
    
    return [patients, updateSlice, patientsLength];
};

export default usePatientsList;
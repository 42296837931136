import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { referralsPropType } from '../../../constants/propTypes';
// import ReferralCard from './ReferralCard';
import PaginationBar from '../../app/PaginationBar';
import ReferralBar from '../ReferralBar';
import useFilterReferralsList from '../../../hooks/referrals/useFilterReferralsList';

function ReferralSentCardsDisplay({ referralsList }) {
    const nReferralsWindow = 25;
    const start = 0;
    const end = nReferralsWindow;
    const [refPending, setRefPending] = useState([]);
    const [refAccepted, setRefAccepted] = useState([]);
    const [seeAccepted, setSeeAccepted] = useState(false);

    useEffect(() => {
        if (referralsList === null || referralsList === undefined) {
            return;
        }
        const refPend = referralsList.filter(referral => referral.targetHasAccepted === false);
        const refAcc = referralsList.filter(referral => referral.targetHasAccepted === true);
        const sortedPending = refPend; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
        const sortedAccepted = refAcc; // .sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn));
        setRefPending(sortedPending);
        setRefAccepted(sortedAccepted);
    }, [referralsList]);

    const [
        referralsPending, 
        updateReferralsPendingSlice,
        referralsPendingLength
    ] = useFilterReferralsList(refPending, start, end);
    const [
        referralsAccepted, 
        updateReferralsAcceptedSlice,
        referralsAcceptedLength
    ] = useFilterReferralsList(refAccepted, start, end);

    const pendingHeaders = (
        <>
            <h4 className='mt-2 p-1'>Pending referrals</h4>
            <div className="py-1" style={{marginLeft:'12px'}}>
                <div className="align-items-center p-0 referral-bar d-none d-md-flex">
                    <div className='flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-1'>
                        <h4 className='m-0'>Patient</h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2'>
                        <h4 className='m-0'>DOB</h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3'>
                        <h4 className='m-0'>Clinician</h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1'>
                        <h4 className='m-0'> </h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1'>
                        <h4 className='m-0'> </h4>
                    </div>
                </div>
            </div> 
        </>
    );

    const acceptedHeaders = (
        <>
            <h4 className='mt-2 p-1'>Accepted referrals</h4>
            <div className="py-1" style={{marginLeft:'12px'}}>
                <div className="align-items-center p-0 referral-bar d-none d-md-flex">
                    <div className='flex-fill text-start referral-bar-item lines-max-1 referral-bar-item-1'>
                        <h4 className='m-0'>Patient</h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-2'>
                        <h4 className='m-0'>DOB</h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1 referral-bar-item-3'>
                        <h4 className='m-0'>Clinician</h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1'>
                        <h4 className='m-0'> </h4>
                    </div>
                    <div className='flex-fill text-left referral-bar-item lines-max-1'>
                        <h4 className='m-0'> </h4>
                    </div>
                </div>
            </div> 
        </>
    );

    const noReferralsDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No referrals to display at the moment</h4>
            </div>
        </div>
    )

    return (
        <>
            {referralsPendingLength === 0 && referralsAcceptedLength === 0 && noReferralsDisplay}
            {referralsPending.length > 0 && pendingHeaders}
            {referralsPending.map((referral) => (               
                <div className='p-1'>
                    <ReferralBar referral={referral} />
                </div> 
                ))}
            {referralsPendingLength > nReferralsWindow && (
                <>
                    <hr className='medr' />
                    <PaginationBar arrayLength={referralsPendingLength} setSliceValues={updateReferralsPendingSlice} windowSize={nReferralsWindow}/>
                </>
            )}

            {referralsAcceptedLength > 0 && (
                <Row className='text-center mt-4'>
                    <Col>
                        <Button  className="sel-button w50" variant={seeAccepted ? 'info' : 'primary'} onClick={() => setSeeAccepted((prev) => !prev)}>
                            {seeAccepted ? "Hide" : "See"} accepted referrals
                        </Button>
                    </Col>
                </Row>
            )}

            {seeAccepted && referralsAccepted.length > 0 && acceptedHeaders}
            {seeAccepted && referralsAccepted.map((referral) => (               
                <div className='p-1'>
                    <ReferralBar referral={referral} />
                </div> 
                ))}
            {seeAccepted && referralsAcceptedLength > nReferralsWindow && (
                <>
                    <hr className='medr' />
                    <PaginationBar arrayLength={referralsAcceptedLength} setSliceValues={updateReferralsAcceptedSlice} windowSize={nReferralsWindow}/>
                </>
            )}
        </>
    );
}

ReferralSentCardsDisplay.propTypes = {
	referralsList: referralsPropType.isRequired,
};


export default ReferralSentCardsDisplay;
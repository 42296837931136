import * as CONSTS from '../constants/profileConstants';
import { LOGOUT_SUCCESS } from '../constants/authConstants';

const initialState = {
	loading: false,
	userProfile: {
		title: "",
		firstName: "",
		lastName: "",
		jobRole: {
			jobRole: ""
		}
	},
	clinicianProfiles: [], /// update
	notificationSettings: {},
	analytics: {
		number: null, 
		noPatientsMade: null,  
		noPatientsReceived: null, 
        noReferralsMade: null, 
		noReferralsReceived: null, 
		topReferredTo:[], 
        topReferralsFrom:[],  
		favouriteClinicians:[]
	}
};

// eslint-disable-next-line default-param-last
export default function profile(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.UPDATE_USER_NOTIFICATIONS_REQUEST:
		case CONSTS.USER_SUMMARY_ANALYTICS_REQUEST:
			return {
				...state,
				loading: true
			}
		case CONSTS.USER_SUMMARY_ANALYTICS_SUCCESS:
			return {
				...state,
				loading: false,
				analytics:payload
			}
		case CONSTS.LOAD_USER_PROFILE_SUCCESS:
		case CONSTS.UPDATE_USER_PROFILE_SUCCESS:
			return {
				...state,
				userProfile: payload.userProfile,
				clinicianProfiles: payload.clinicianProfiles, /// update
				notificationSettings: payload.notificationSettings,
			};
		case CONSTS.UPDATE_USER_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				notificationSettings: payload.notificationSettings,
			};
		case CONSTS.UPDATE_USER_NOTIFICATIONS_FAIL:
			return {
				...state,
				loading: false,
			};
		case CONSTS.PROFILE_FROM_STORAGE:
			return {
				...state,
				userProfile: payload.userProfile,
				clinicianProfiles: payload.clinicianProfiles, /// update
			};
		case LOGOUT_SUCCESS:
				return {...initialState};
		case CONSTS.LOAD_USER_PROFILE_FAIL:
			return {
				...initialState
			};
		case CONSTS.UPDATE_USER_PROFILE_FAIL:
		case CONSTS.USER_SUMMARY_ANALYTICS_FAIL:
			return {
				...state,
				loading: false
			};
		default:
			return state;
	}
}

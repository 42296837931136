import * as CONSTS from '../constants/referralConstants';
import * as INIT from "../constants/initDataConstants";
import { LOGOUT_SUCCESS } from '../constants/authConstants';

const initialState = {
	loading: false,
	referralList: null,
	referral: INIT.initReferral,
	referralsMade: [],
	referralsReceived: {'accepted':[], 'waiting':[]},
	referralsOpen: []
};

// eslint-disable-next-line default-param-last
export default function referrals(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.CLINICIAN_REFERRALS_REQUEST:
		case CONSTS.REFERRAL_TOGGLE_ACCEPT_REQUEST:
		case CONSTS.CREATE_REFERRAL_REQUEST:
		case CONSTS.REFERRAL_LIST_REQUEST:
		case CONSTS.REFERRAL_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.REFERRAL_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				referralList: payload,
			};
		case CONSTS.REFERRAL_LIST_FAIL:
			return {
				...state,
				loading: false,
				referralList: null,
			};
		case CONSTS.CREATE_REFERRAL_SUCCESS:
			return {
				...state,
				loading: false,
				referral: payload.referral,
			};
		case CONSTS.CREATE_REFERRAL_FAIL:
			return {
				...state,
				loading: false,
				referral: INIT.initReferral,
			};
		case CONSTS.REFERRAL_TOGGLE_ACCEPT_SUCCESS:
			return {
				...state,
				loading: false,
				referral: payload.referral,
			};
		case CONSTS.REFERRAL_TOGGLE_ACCEPT_FAIL:
			return {
				...state,
				loading: false,
			};
		case CONSTS.REFERRAL_SUCCESS:
			return {
				...state,
				loading: false,
				referral: payload.referral,
			};
		case CONSTS.REFERRAL_FAIL:
			return {
				...state,
				loading: false,
				referral: {},
			};
		case CONSTS.CLINICIAN_REFERRALS_SUCCESS:
			return {
				...state,
				loading: false,
				referralsMade: payload.referralsMade,
				referralsReceived: payload.referralsReceived,
				referralsOpen: payload.referralsOpen
			};
		case CONSTS.CLINICIAN_REFERRALS_FAIL:
			return {
				...state,
				loading: false,
				referralsMade: [],
				referralsReceived: [],
				referralsOpen: []
			};
		case LOGOUT_SUCCESS:
			return {...initialState};
		default:
			return state;
	}
}

import React from "react";
import { Row,  Col  } from 'react-bootstrap';
import PropTypes from 'prop-types';
import InsuranceDisplayCard from "./InsuranceDisplayCard"

function InsuranceDisplayBox({ newProviders, editable, removeProvider}){

    let newlyAdded;
    if (newProviders===null || newProviders===undefined){
        newlyAdded = <p>No providers</p>;
    } else {
        newlyAdded = newProviders.map((insurance) => (<InsuranceDisplayCard key={`newPDisplay${insurance.provider}`} values={insurance} isNew editable={editable} removeProvider={removeProvider} />));
    }

    return (
        <div className='medr-rounded bg-medr-gray'>
            <Row>
                <Col className='mt-1 text-center'>
                    <h4 className='bluegreen'> {newlyAdded.length>0 ? "New providers added" : "No new providers added"}</h4>
                </Col>
            </Row>
            {newlyAdded}
        </div>
    )
};

InsuranceDisplayBox.propTypes = {
    newProviders: PropTypes.arrayOf(PropTypes.shape({
        provider: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    editable: PropTypes.bool.isRequired,
    removeProvider: PropTypes.func.isRequired,
};

export default InsuranceDisplayBox;
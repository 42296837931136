import React, { useState } from 'react';
import { Form, Button, Row, Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { isAuthenticatedProptype } from '../../constants/propTypes';
import { resetPassword } from '../../actions/authActions';

function SendResetPassswordScreen({ isAuthenticated }) {

	const [showAlert, setShowAlert] = useState(false);
	const [emailSent, setEmailSent] = useState('');

	if (isAuthenticated) return <Navigate to="/dashboard" />;

    const [formData, setFormData] = useState({
		email: '',
	});

	const { email } = formData;

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	const dispatch = useDispatch();

	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(resetPassword(email));
		setEmailSent(email);
		setShowAlert(true);
		setFormData({ ...formData, email: "" })
	};

	return (
		<div className='medr-layout text-center reset-password-page' style={{maxWidth:'380px', marginLeft: 'auto', marginRight: 'auto'}}>
			<h1>Reset password</h1>
			{showAlert ? <Alert variant='success'>Password reset email sent to {emailSent}</Alert> : null}
			<Form onSubmit={(e) => onSubmit(e)}>
				<Form.Group className='py-2 text-left' controlId="email">
					<Form.Label>Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter Email"
						name="email"
						value={email}
						onChange={(e) => onChange(e)}
					/>
				</Form.Group>
				<Row className='mt-5'>
					<div className='col-12'>
						<Button type="submit" variant="primary" className="sel-button w80">
							Send password reset email
						</Button>
					</div>
				</Row>
			</Form>
		</div>
	);
}

SendResetPassswordScreen.propTypes = {
	isAuthenticated: isAuthenticatedProptype.isRequired
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { })(SendResetPassswordScreen);

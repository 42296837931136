import React, { useEffect, useState } from "react";
import { Row,  Col, Button, Form  } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DobFormField } from "../app/parts/FormFields";
import JobRoleSearchBar from "../clinicians/JobRoleSearchBar";
import ClinicFormDoctor from "../clinicians/ClinicFormDoctor";
import ClinicFormHcp from "../clinicians/ClinicFormHcp";
import ClinicsDisplayBox from "../clinicians/ClinicsDisplayBox";
import UserAppointmentsContactForm from "./UserAppointmentsContactForm";
import { funcPropType, clinicsPropType, jobRolesPropType } from "../../constants/propTypes";

function RegisterClinicianDetailsForm({ values, changeValues, handleNext, doctorIds, jobRoles }){
    const [disableNext, setDisableNext] = useState(true);
    const [isDoctor, setIsDoctor] = useState(false);
    const [registrationBody, setRegistrationBody] = useState('');
    
    useEffect(() => {window.scroll(0,0)}, []);

    useEffect(() => {
        setIsDoctor(doctorIds.indexOf(values.jobRoleId) !== -1);
        const jobRole = jobRoles.filter(jr => jr.id===values.jobRoleId);
        if (jobRole.length===0){
            setRegistrationBody('');
            return;
        }
        setRegistrationBody(jobRole[0].registrationBody);
    }, [values.jobRoleId]);

    useEffect(() => {
        if (values.clinics===null || values.clinics===undefined){
            return;
        }
        const roleSelected = values.jobRoles.length > 0;
        // const roleSelected = values.jobRoleId>0;
        const clinicAdded = values.clinics.length > 0;
        const registrationAdded = values.registrationNumber!=='';
        const dobAdded = values.dob!=='';
        const descriptionAdded = values.clinicianDescription!=='';
        const ready = clinicAdded && roleSelected && registrationAdded && dobAdded && descriptionAdded;
        setDisableNext(!ready);
    }, [values]);

    const handleAddNewClinic = (clinic) => {
        const newList = [...values.clinics, clinic];
        changeValues({key: 'clinics', value: newList});
    }
    
    const handleRemoveClinic = (e) => {
        const newList = values.clinics.filter(item => !(
            item.clinicName === e.clinicName && 
            item.centreName === e.centreName && 
            item.firstLine === e.firstLine && 
            item.city === e.city && 
            item.postcode === e.postcode && 
            item.addressType === e.addressType
        ));
        changeValues({key: 'clinics', value: newList});
    }

    const handleAddJobRole = (e) => {
        const roles = values.jobRoles
        const newRole = e[0]

        if (newRole === null || newRole === undefined) return;
        
        if (roles.filter(item => item.id === newRole.id).length > 0) return;
        roles.push(newRole);
        changeValues({key: 'jobRoles', value: roles});
    }

    const handleRemoveJobRole = (e) => {
        const roles = values.jobRoles.filter(item => item.id !== e.id);
        changeValues({key: 'jobRoles', value: roles});
    }

    function setJobRoles(){
        if (values.jobRoles.length===0) return null;

        const roleList = values.jobRoles.map((role) => (
            <div className='mt-1 medr-rounded-white' style={{paddingTop: '0px', paddingBottom:'0px'}}>
                <Row className='w100'>
                    <Col className='job-role-bar-left'>
                        <p className='medr-text-inv m-2 w100' style={{color: '#247986'}}>{role.jobTitle}</p>
                    </Col>
                    <Col className='mt-1 job-role-bar-right' >
                        <Button className='sel-button p-1' variant='danger' onClick={() => handleRemoveJobRole(role)} style={{fontSize: '14px', maxHeight: '40px'}}>Remove</Button>
                    </Col>
                </Row>
            </div>
        ));
        return (roleList);
    }

    useEffect(() => {setJobRoles()}, [values.jobRoles]);

    return (
        <>
            <div className="register-clinician-page">
                <h4>Enter clinician details</h4>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group controlId="selectedRole" className='mt-2'>
                                <Form.Label className='my-0'>Select a clinician role</Form.Label>
                            </Form.Group>
                            <JobRoleSearchBar currentJobId={values.jobRoleId} changeValues={handleAddJobRole} isRegistration/>
                            
                            {values.jobRoles.length1>0 && (
                                <Form.Group controlId="selectedRoles" className='mt-2'>
                                    <Form.Label className='my-0'>Selected job roles</Form.Label>
                                </Form.Group>
                            )}
                            {setJobRoles()}

                            <Form.Group controlId="registrationNumber" className='mt-2'>
                                <Form.Label className='my-0'>{registrationBody} Registration number</Form.Label>
                                <Form.Control
                                    required
                                    type="name"
                                    placeholder="Enter registration no"
                                    defaultValue={values.registrationNumber}
                                    onChange={(e) => changeValues({key: 'registrationNumber', value: e.target.value})}
                                />
                            </Form.Group>

                            <DobFormField dob={values.dob} dobKey='dob' changeValues={changeValues} />

                            <Form.Group controlId="clinicianDescription" className='mt-2'>
                                <Form.Label className='my-0'>Clinician description</Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    placeholder="Enter clinician description"
                                    rows={8}
                                    defaultValue={values.clinicianDescription}
                                    onChange={(e) => changeValues({key: 'clinicianDescription', value: e.target.value})}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <h4 className='mt-3'>Referral contact details</h4>
                    <UserAppointmentsContactForm values={values} changeValues={changeValues} />
                    <Row className='mt-3'>
                        <Col>
                            {isDoctor ? 
                                <ClinicFormDoctor handleAddClinic={handleAddNewClinic} />
                                : <ClinicFormHcp handleAddClinic={handleAddNewClinic} />   
                            }
                        </Col>
                    </Row>
                </Form>
                {values.clinics.length===0 ? null : (
                    <Row className='mt-3'>
                        <Col>
                            <ClinicsDisplayBox clinics={values.clinics} editable removeClinic={handleRemoveClinic}/>
                        </Col>
                    </Row>
                )}
            </div>
            <Row>
                <Col className='text-center'>
                    <Button className='sel-button w80 my-4' variant='primary' onClick={handleNext} disabled={disableNext}>Next</Button>
                </Col>
            </Row>
        </>

    )
};

RegisterClinicianDetailsForm.propTypes = {
    doctorIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    jobRoles: jobRolesPropType.isRequired,
	values: PropTypes.shape({
        isReview: PropTypes.bool.isRequired,
        jobRoleId: PropTypes.number.isRequired,
        jobRoles: jobRolesPropType.isRequired,
        registrationNumber: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        clinicianDescription: PropTypes.string.isRequired,
        clinics: clinicsPropType.isRequired,
    }).isRequired,
	changeValues: funcPropType.isRequired,
	handleNext: funcPropType.isRequired,
};

const mapStateToProps = state => ({
    doctorIds: state.clinicians.doctorIds,
    jobRoles: state.clinicians.jobRoles,
})

export default connect(mapStateToProps, {})(RegisterClinicianDetailsForm);

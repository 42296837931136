import * as CONSTS from '../constants/profileConstants';
import * as URL from '../constants/urls';
import {  authGetRequest, authPutRequest, anyPostRequest, authPostRequest } from './axiosActions';


export const loadUser = (successfullLoadUser) => async (dispatch) => {

	const inputData = {
		URL: `${URL.LOAD_USER}`,
		GET_REQUEST: CONSTS.LOAD_USER_PROFILE_REQUEST,
		GET_SUCCESS: CONSTS.LOAD_USER_PROFILE_SUCCESS,
		GET_FAIL: CONSTS.LOAD_USER_PROFILE_FAIL,
	};
	
	dispatch(authGetRequest(inputData, successfullLoadUser));
};

export const updateProfile = (payload) => async (dispatch) => {
	const sendIsSuccess = (value) => {
		if (value===true){
			dispatch(loadUser());
		}
	}

	const inputData = {
		URL: `${URL.USER_PROFILE_UPDATE}`,
		GET_REQUEST: CONSTS.UPDATE_USER_PROFILE_REQUEST,
		GET_SUCCESS: CONSTS.UPDATE_USER_PROFILE_SUCCESS,
		GET_FAIL: CONSTS.UPDATE_USER_PROFILE_FAIL,
		payload
	};
	
	dispatch(authPutRequest(inputData, sendIsSuccess));
};

export const updateUserNotifications = (payload) => async (dispatch) => {

	const inputData = {
		URL: `${URL.USER_UPDATE_NOTIFICATIONS}`,
		GET_REQUEST: CONSTS.UPDATE_USER_NOTIFICATIONS_REQUEST,
		GET_SUCCESS: CONSTS.UPDATE_USER_NOTIFICATIONS_SUCCESS,
		GET_FAIL: CONSTS.UPDATE_USER_NOTIFICATIONS_FAIL,
		payload
	};
	
	dispatch(authPutRequest(inputData));
};

export const updateProfileSummary =
	(clinicianDescription) => async (dispatch) => {
		const payload = { clinicianDescription };

		const sendIsSuccess = (value) => {
			if (value===true){
				dispatch(loadUser());
			}
		}

		const inputData = {
			URL: `${URL.USER_UPDATE_SUMMARY}`,
			GET_REQUEST: CONSTS.UPDATE_USER_PROFILE_SUMMARY_REQUEST,
			GET_SUCCESS: CONSTS.UPDATE_USER_PROFILE_SUMMARY_SUCCESS,
			GET_FAIL: CONSTS.UPDATE_USER_PROFILE_SUMMARY_FAIL,
			payload
		};
		
		dispatch(authPutRequest(inputData, sendIsSuccess));
};

export const updateReferralContacts =
	(appointmentsContacts) => async (dispatch) => {
		const payload = { appointmentsContacts };

		const sendIsSuccess = (value) => {
			if (value===true){
				dispatch(loadUser());
			}
		}

		const inputData = {
			URL: `${URL.UPDATE_REFERRAL_CONTACTS}`,
			GET_REQUEST: CONSTS.UPDATE_REFERRAL_CONTACTS_REQUEST,
			GET_SUCCESS: CONSTS.UPDATE_REFERRAL_CONTACTS_SUCCESS,
			GET_FAIL: CONSTS.UPDATE_REFERRAL_CONTACTS_FAIL,
			payload
		};
		
		dispatch(authPutRequest(inputData, sendIsSuccess));
};

export const getUserSummaryAnalytics = (numberResults) => async(dispatch) => {
	const inputData = {
		URL: `${URL.USER_SUMMARY_ANALYTICS}${numberResults}/`,
		GET_REQUEST: CONSTS.USER_SUMMARY_ANALYTICS_REQUEST,
		GET_SUCCESS: CONSTS.USER_SUMMARY_ANALYTICS_SUCCESS,
		GET_FAIL: CONSTS.USER_SUMMARY_ANALYTICS_FAIL,
	};
	
	dispatch(authGetRequest(inputData));
}

export const requestHelp = (payload, authenticated, isSuccess) => async (dispatch) => {
	const inputData = {
		URL: URL.REQUEST_HELP,
		GET_REQUEST: CONSTS.REQUEST_HELP_REQUEST,
		GET_SUCCESS: CONSTS.REQUEST_HELP_SUCCESS,
		GET_FAIL: CONSTS.REQUEST_HELP_FAIL,
		payload

	};
	if (!authenticated){
		dispatch(anyPostRequest(inputData, isSuccess));
	} else {
		dispatch(authPostRequest(inputData, isSuccess));
	}
};
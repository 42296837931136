import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { clinicanProfileRankPropType, numberPropType } from '../../constants/propTypes';

function ClinicianMarker({ clinician, sendHoveredClinician, hoveredClinicianId, disableClinicianLink }){

    if (clinician===null){
        return null;
    }

    const [isHovered, setIsHovered] = useState(false);
    const [clinicianIsHovered, setClinicianIsHovered] = useState(false);

    const href =  `/clinicians/${clinician.id}`
    
    const handleMouseEnter = () => {
        setIsHovered(true);
        if (sendHoveredClinician!==null){
            sendHoveredClinician(clinician);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (sendHoveredClinician!==null){
            sendHoveredClinician(null);
        }
    };

    useEffect(() => {
        setClinicianIsHovered(hoveredClinicianId === clinician.id);
    }, [hoveredClinicianId, clinician.id]);

    return (
        <div>
            {disableClinicianLink ? (
                <div 
                    className={`hoverable-component ${isHovered || clinicianIsHovered ? 'hovered map-pin-box' : ''}`}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    >
                    <i className={`fa-solid fa-map-pin map-marker${isHovered || clinicianIsHovered ? "-hover" : ''}`}/>
                </div>
            ) : (
                <a href={href} aria-disabled={disableClinicianLink}>
                    
                    <div 
                        className={`hoverable-component ${isHovered || clinicianIsHovered ? 'hovered map-pin-box' : ''}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        >
                        <i className={`fa-solid fa-map-pin map-marker${isHovered || clinicianIsHovered ? "-hover" : ''}`}/>
                    </div>
                </a>
            )}
        </div>
    )
}

ClinicianMarker.propTypes = {
    clinician: clinicanProfileRankPropType.isRequired,
    sendHoveredClinician: PropTypes.func,
    hoveredClinicianId: numberPropType.isRequired,
    disableClinicianLink: PropTypes.bool
}

ClinicianMarker.defaultProps = {
    sendHoveredClinician: null,
    disableClinicianLink: false
}

export default ClinicianMarker;

import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { userProfilePropType } from '../../constants/propTypes';
import TitleDropdown from "../TitleDropdown";
import { EmailFormField } from "../app/parts/FormFields";
import { updateProfile } from '../../actions/profileActions';

function UserUpdateRequest({ userProfile }) {
    const dispatch = useDispatch();
    const initData = {
        title: userProfile.title,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.user.email,
        dob: userProfile.dob,
    }

    const [formData, setFormData] = useState(initData);
    const [isDOBValid, setIsDOBValid] = useState(true);
    const { title, firstName, lastName, email, dob } = formData;

	const checkAge = (DOB) => {
		if (!DOB) {
			setIsDOBValid(false); // If no date is selected, consider it not old enough.
		  return;
		}
	
		// Parse the birthDate string to a Date object
		const birthDateObject = new Date(DOB);
		const currentDate = new Date();
	
		// Calculate the difference in years
		const ageDifference = currentDate.getFullYear() - birthDateObject.getFullYear();
	
		// Check if the person is 18 or more years old
		if (ageDifference >= 18 && ageDifference <= 100){
			setIsDOBValid(true);
		} else {
			setIsDOBValid(false);
		}
	  };

	const setDOB = (e) => {
		checkAge(e.target.value);
		setFormData({ ...formData, dob: e.target.value });
	}

    const onSubmit = () => dispatch(updateProfile(formData));

	return (
        <Form className='text-left'>
            <TitleDropdown currentTitle={title} sendTitle={(ti) => setFormData({...formData, title: ti})} />

            <Form.Group controlId="firstName">
                <Form.Label className='my-0 mt-3 text-left'>First name</Form.Label>
                <Form.Control
                    required
                    type="name"
                    placeholder="Enter first name"
                    value={firstName}
                    className='mb-3'
                    onChange={(e) => setFormData({...formData, firstName: e.target.value})}
                />
            </Form.Group>

            <Form.Group controlId="dob" className="mb-3">
                <Form.Label className='my-0'>DOB</Form.Label>
                <Form.Control
                    required
                    type="date"
                    name="dob"
                    value={dob}
                    onChange={(e) => setDOB(e)}
                    isInvalid={!isDOBValid}
                    />
                    <Form.Control.Feedback type="invalid">
                        Check patient is over 18 years old.
                    </Form.Control.Feedback>
            </Form.Group>

            { /* TODO: add birth date */ }

            <Form.Group controlId="lastName">
                <Form.Label className='my-0'>Last name</Form.Label>
                <Form.Control
                    required
                    type="name"
                    placeholder="Enter last name"
                    value={lastName}
                    className='mb-3'
                    onChange={(e) => setFormData({...formData, lastName: e.target.value})}
                />
            </Form.Group>

            <EmailFormField email={email} emailKey='email' changeValues={(e) => setFormData({...formData, email: e.value})} />

            <Row className='mt-4 mb-5'>
                <Col className='text-center'>
                    <Button className="sel-button w80" variant="primary" onClick={onSubmit}>Update user information</Button>
                </Col>
            </Row>
        </Form>
	);
}

UserUpdateRequest.propTypes = {
	userProfile: userProfilePropType.isRequired
}

const mapStateToProps = (state) => ({
	userProfile: state.profile.userProfile,
});

export default connect(mapStateToProps, {})(UserUpdateRequest);
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import { funcPropType, numberPropType, boolPropType, specialtiesPropType, jobRolesPropType } from "../../constants/propTypes";
import { getJobRolesList } from "../../actions/clinicianActions";

function JobRoleSearchBar({ currentJobId, changeValues, jobRoleList, specialties, filtered , isRegistration }){

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getJobRolesList(filtered));
    }, [dispatch]);

    const [showSecondBar, setShowSecondBar] = useState(false);
    const [showThirdBar, setShowThirdBar] = useState(false);

    const [jobRoles, setJobRoles] = useState([]);
    const [jobSpecialties, setJobSpecialties] = useState([]);
    const [jobSubSpecialties, setJobSubSpecialties] = useState([]);

    const [selectedJobRole, setSelectedJobRole] = useState(null);
    const [selectedJobSpecialty, setSelectedJobSpecialty] = useState(null);
    const [selectedJobSubSpecialty, setSelectedJobSubSpecialty] = useState(null);

    const [selectedJobRoleId, setSelectedJobRoleId] = useState(null);

    const clearBoxes = (level) => {
        if (level === 1){
            setSelectedJobRole(null);
            setSelectedJobSpecialty(null);
            setSelectedJobSubSpecialty(null);
            setShowSecondBar(false);
            setShowThirdBar(false);
            return;
        }
        if (level === 2){
            setSelectedJobSpecialty(null);
            setSelectedJobSubSpecialty(null);
            setShowThirdBar(false);
            return;
        }
        setSelectedJobSubSpecialty(null);
    };

    const setJobRolesLevel = () => {
        if (specialties === null || specialties=== undefined) return;
        const jrList = Object.keys(specialties).sort();
        setJobRoles(jrList.map(item => ({label: item, value: item})));

        setShowSecondBar(false);
        setShowThirdBar(false);
    };

    const jobRoleSelected = () => {
        if (selectedJobRole === null || selectedJobRole === undefined) {
            changeValues({key: 'jobRoleSelected', value: ''});
            return;
        }

        const specialtiesList = Object.keys(specialties[selectedJobRole.value]).sort();
        if (specialtiesList.length === 1){
            if (specialtiesList[0] === 'nan' || specialtiesList[0] === 'Undefined' ) {
                const spec = specialtiesList[0]
                const roleId = specialties[selectedJobRole.value][spec][0].id;
                setSelectedJobRoleId({ roleId, level: 1});
                setShowSecondBar(false);
                setShowThirdBar(false);
                return;
            }
        } 

        setJobSpecialties(specialtiesList.map(item => ({label: item, value: item})));
        setSelectedJobSpecialty(null);
        setSelectedJobSubSpecialty(null);
        setShowSecondBar(true);
        changeValues({key: 'jobRoleSelected', value: selectedJobRole.value});
    }

    const specialtiesSelected = () => {
        if (selectedJobSpecialty === null || selectedJobSpecialty === undefined) {
            changeValues({key: 'jobSpecialtySelected', value: ''});
            return;
        }

        const subSpecialtiesList = specialties[selectedJobRole.value][selectedJobSpecialty.value];

        if (subSpecialtiesList.length === 1){
            if (subSpecialtiesList[0].subSpecialty === 'nan' || subSpecialtiesList[0].subSpecialty === 'Undefined') {
                const subSpec = subSpecialtiesList[0]
                const role = specialties[selectedJobRole.value][selectedJobSpecialty.value].filter(item => item.subSpecialty === subSpec.subSpecialty);
                if (role.length === 0) return;
                const roleId = role[0].id;
                setSelectedJobRoleId({ roleId, level: 2});
                setShowThirdBar(false);
                return;
            }
        } 

        setJobSubSpecialties(subSpecialtiesList.map((item) => ({label: item.subSpecialty, value: item.subSpecialty})));
        setShowThirdBar(true);
        changeValues({key: 'jobSpecialtySelected', value: selectedJobSpecialty.value});

    }

    const jobSubSpecialtySelected = () => {
        if (selectedJobSubSpecialty === null || selectedJobSubSpecialty === undefined) return;

        const role = specialties[selectedJobRole.value][selectedJobSpecialty.value].filter(item => item.subSpecialty === selectedJobSubSpecialty.value);
        if (role.length === 0) return;
        setSelectedJobRoleId({ roleId: role[0].id, level: 3});
    }

    const checkSelectedJrId = () => {
        if (selectedJobRoleId && selectedJobRoleId.roleId === currentJobId) return;

        const role = jobRoleList.filter(item => item.id === currentJobId);
        if (role.length === 0) return;

        setSelectedJobRole({label: role[0].jobRole, value: role[0].jobRole});
        setJobRolesLevel();
        setSelectedJobSpecialty({label: role[0].specialty, value: role[0].specialty});

        setSelectedJobSubSpecialty({label: role[0].subSpecialty, value: role[0].subSpecialty});
    }

    useEffect(() => {setJobRolesLevel();}, [specialties]);
    useEffect(() => {jobRoleSelected();}, [selectedJobRole]);
    useEffect(() => {specialtiesSelected();}, [selectedJobSpecialty]);
    useEffect(() => {jobSubSpecialtySelected();}, [selectedJobSubSpecialty]);
    useEffect(() => {
        if (selectedJobRoleId === null || selectedJobRoleId === undefined) return; 

        if (isRegistration){
            const role = jobRoleList.filter(item => item.id === selectedJobRoleId.roleId);
            changeValues(role);

            clearBoxes(selectedJobRoleId.level);
            return;
        } 
        changeValues({key: 'jobRoleId', value: selectedJobRoleId.roleId});
    }, [selectedJobRoleId]);

    useEffect(() => {checkSelectedJrId();}, [currentJobId]);
    const customStyles = {
        singleValue:(provided:any) => ({
          ...provided,
          color:'#247986',
        }),
      }

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Select
                        name="clinicianType"  
                        options={jobRoles}
                        value={selectedJobRole}
                        onChange={opt => setSelectedJobRole(opt)}
                        placeholder="Search for role (e.g. Doctor, Physio, etc.)"
                        styles={customStyles}
                    />
                </Col>
            </Row>
            { showSecondBar ? (
                <Row className="mb-3">
                    <Col>
                        <Select
                            name="specialty"
                            options={jobSpecialties}
                            value={selectedJobSpecialty}
                            onChange={opt => setSelectedJobSpecialty(opt)}
                            placeholder="Search specialty"
                            styles={customStyles}
                        />
                    </Col>
                </Row>
            ) : null} 

            { showThirdBar ? (
                <Row className="mb-3">
                    <Col>
                        <Select
                            name="subSpecialty"
                            options={jobSubSpecialties}
                            value={selectedJobSubSpecialty}
                            onChange={opt => setSelectedJobSubSpecialty(opt)}
                            placeholder="Search sub specialties"
                            styles={customStyles}
                        />
                    </Col>
                </Row>
            ) : null}
        </>
    )
}

JobRoleSearchBar.propTypes = {
    filtered: boolPropType,
    currentJobId: numberPropType,
    changeValues: funcPropType.isRequired,
    jobRoleList: jobRolesPropType.isRequired,
    specialties: specialtiesPropType.isRequired,
    isRegistration: boolPropType
};

JobRoleSearchBar.defaultProps = { 
    currentJobId: null,
    filtered: false,
    isRegistration: false
};

const mapStateToProps = state => ({
    specialties: state.clinicians.specialties,
    jobRoleList: state.clinicians.jobRoles
})

export default connect(mapStateToProps, {})(JobRoleSearchBar);
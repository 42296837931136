import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import InsuranceAgreementSearchBar from '../clinicians/InsuranceAgreementSearchBar';
import GenderSearchBar from '../user/GenderSearchBar';
import {
	getCurrentPatient,
	postCreatePatient,
	updatePatientById,
} from '../../actions/patientActions';
import TitleDropdown from '../TitleDropdown';
import { EmailFormField, PhoneFormField } from '../app/parts/FormFields';
import { patientPropType } from '../../constants/propTypes';
import CheckAgeOver18FromDob from '../../services/AgeCheck';
import { registerCheckClinic } from "../../actions/authActions";

const getBlankFormData = () => ({
    id: 0,
    title: '',
    firstName: '',
    lastName: '',
    patientReference: '',
    sex: '',
    dob: '',
    primaryEmail: '',
    primaryPhoneNumber: '',
    address: {
        firstLine:'', 
        city: '',
        postcode:'',
    },
    insurancePolicy:{ id: -1},
    insuranceProviderId: -1,
    consent: false,
});


function PatientEntryForm({ initNewPatient,  isSuccessful, currentPatient, loadPatient}) {
    const params = useParams();
    const dispatch = useDispatch();

    const [patientToBeLoaded, setPatientToBeLoaded] = useState(loadPatient);

    useEffect(() => {
        if (params.id && params.id!==null && params.id!==undefined && initNewPatient===false){
            const ID = parseInt(params.id, 10);
            setPatientToBeLoaded(true);
            dispatch(getCurrentPatient(ID));
        }
    }, [params]); 

	const [formData, setFormData] = useState(getBlankFormData());
    const [formChecks, setFormChecks] = useState({
        title: true,
        firstName: true,
        lastName: true,
        sex: true,
        dob: true,
        email: true,
        phone: true,
        postcode: true,
        insuranceProviderId: true,
        consent: true,
    });
    const [gpsError, setGpsError] = useState(false);
	const [isDOBValid, setIsDOBValid] = useState(true);

    const {
        title,
        firstName,
        lastName,
        patientReference,
        dob,
        sex,
        primaryEmail,
        primaryPhoneNumber,
        address,
        consent,
        insuranceProviderId,
        // insurancePolicy
    } = formData;
    
    const { firstLine, city, postcode } = address;

    const changeValue = (e) => {
        if (e.key==='insuranceProviderId'){
            setFormData({ ...formData, [e.key]: e.value, insurancePolicy: { id: e.value } });
        } else {
            setFormData({ ...formData, [e.key]: e.value});
        }
    }

	const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onChangeAddress = (e) => {
        setFormData({ ...formData, address: { ...address, [e.target.name]: e.target.value }});
    }

    function loadingCurrentPatient(){
        if (patientToBeLoaded===false || currentPatient===null || currentPatient===undefined || currentPatient.id===null){return {valid: false, px: null};}
        if (currentPatient.isSelfPayer || currentPatient.insurancePolicy===null || currentPatient.insurancePolicy===undefined){
           return {valid: true, px: {...currentPatient, insuranceProviderId: 0}};
        } 
        return {valid: true, px: {...currentPatient, insuranceProviderId: currentPatient.insurancePolicy.id}};
    }

	useEffect(() => {    
        const { valid, px } = loadingCurrentPatient();
        if (valid){setFormData(px);}
    }, [currentPatient]);

	const setDOB = (e) => {
        const { isValid } = CheckAgeOver18FromDob(e.target.value);
		setIsDOBValid(isValid);
		setFormData({ ...formData, dob: e.target.value });
	}

    const checkAddress = async () => {
        if (postcode===''){return;}
        const addressValid = await registerCheckClinic(firstLine, city, postcode);
        setGpsError(!addressValid);
    };

    useEffect(() => {
        checkAddress();
    }, [address]);
    
    const checkFieldsFilledIn =() => {
        const titleCheck = title!=='';
        const firstNameCheck = firstName!=='';
        const lastNameCheck = lastName!=='';
        const dobCheck = dob!=='';
        const sexCheck = sex!=='';
        const emailCheck = primaryEmail!=='';
        const phoneCheck = primaryPhoneNumber!=='';
        const postcodeCheck = postcode!=='';
        const insuranceProviderCheck = insuranceProviderId>-1;
        setFormChecks({
            title: titleCheck,
            firstName: firstNameCheck,
            lastName: lastNameCheck,
            dob: dobCheck,
            sex: sexCheck,
            email: emailCheck,
            phone: phoneCheck,
            postcode: postcodeCheck,
            insuranceProviderId: insuranceProviderCheck,
        });

        const ready = titleCheck && firstNameCheck && lastNameCheck && dobCheck && sexCheck && emailCheck && phoneCheck && postcodeCheck && insuranceProviderCheck; 
        return ready;    
    }

    const handleConsentChange = () => {

        if (formData.consent){
            setFormData({ ...formData, consent: !formData.consent });
            return;
        }

        const isReady = checkFieldsFilledIn();
        
        if (!isReady) return;
        
        setFormData({ ...formData, consent: !formData.consent });
        setFormChecks({...formChecks, consent: true});
    }


	const onSubmit = async (e) => {
        e.preventDefault();
        if (!consent){
            setFormChecks({...formChecks, consent: false});
            return;
        }

        const selfPay = insuranceProviderId===0;

        const requestFormData = new FormData();
    
        requestFormData.append('title', title);
        requestFormData.append('firstName', firstName);
        requestFormData.append('lastName', lastName);
        requestFormData.append('patientReference', patientReference);
        requestFormData.append('dob', dob);
        requestFormData.append('sex', sex);
        requestFormData.append('primaryEmail', primaryEmail);
        requestFormData.append('primaryPhoneNumber', primaryPhoneNumber);
        requestFormData.append('firstLine', firstLine);
        requestFormData.append('city', city);
        requestFormData.append('postcode', postcode);
        requestFormData.append('isSelfPayer', selfPay);
        requestFormData.append('insuranceProviderId', insuranceProviderId);
        requestFormData.append('consent', consent);
        
        // add image
        // if (selectedFile !== null) {
        //     requestFormData.append('image', selectedFile);
        // }

        if (initNewPatient){
            // send request
            await dispatch(postCreatePatient(requestFormData, isSuccessful));
            setFormData(getBlankFormData());
        } else {

            const payload = {
                title,
                firstName,
                lastName, 
                patientReference, 
                dob, 
                sex, 
                primaryEmail, 
                primaryPhoneNumber, 
                firstLine,  
                city,
                postcode,
                isSelfPayer: selfPay,
                insuranceProviderId,
                consent
            };

            const ID = parseInt(formData.id, 10);
            await dispatch(updatePatientById(ID, payload, isSuccessful));
            dispatch(getCurrentPatient(ID));
        }
    };

	return (
        <Form className='text-left'>
            <TitleDropdown currentTitle={title} sendTitle={(e) => changeValue({key: 'title', value: e})}/>
            {!formChecks.title ? <p className='medr-invalid-text'>Please select a title</p> : null}

            <Form.Group controlId="firstName" className="mt-3 mb-3">
                <Form.Label className='my-0'>First name (Required) </Form.Label>
                <Form.Control
                    required
                    type="name"
                    placeholder="Enter first name"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => onChange(e)}
                    isInvalid={!formChecks.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter a first name.
                    </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="lastName" className="mb-3">
                <Form.Label className='my-0'>Last name (Required) </Form.Label>
                <Form.Control
                    required
                    type="name"
                    placeholder="Enter last name"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => onChange(e)}
                    isInvalid={!formChecks.lastName}
                />
                <Form.Control.Feedback type="invalid">
                    Please enter a last name.
                </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="patientReference" className="mb-3">
                <Form.Label className='my-0'>Patient Reference</Form.Label>
                <Form.Control
                    type="name"
                    placeholder='Enter patient reference'
                    name="patientReference"
                    value={patientReference}
                    onChange={(e) => onChange(e)}
                />
            </Form.Group>

            <Form.Group controlId="gender" className="mb-3">
                <Form.Label className='my-0'>Sex</Form.Label>
                <GenderSearchBar gender={sex} changeValue={changeValue} />
            </Form.Group>
            {!formChecks.sex ? <p className='medr-invalid-text'>Please select a sex</p> : null}

            <Form.Group controlId="dob" className="mb-3">
                <Form.Label className='my-0'>DOB (Required) </Form.Label>
                <Form.Control
                    required
                    type="date"
                    name="dob"
                    value={dob}
                    onChange={(e) => setDOB(e)}
                    isInvalid={!isDOBValid || !formChecks.dob}
                    />
                    <Form.Control.Feedback type="invalid">
                        {!isDOBValid ? "Check patient is over 18 years old." : "Please enter a date of birth."}
                    </Form.Control.Feedback>
            </Form.Group>

            <div className="mb-3">
                <EmailFormField email={primaryEmail} emailKey='primaryEmail' changeValues={changeValue}/>
                {!formChecks.email ? <p className='medr-invalid-text'>Please enter email</p> : null}

            </div>
            <div className="mb-3">
                <PhoneFormField currentPhoneNo={primaryPhoneNumber} nameKey='primaryPhoneNumber' nameLabel='Phone number (Required)' changeValues={changeValue} />
                {!formChecks.phone ? <p className='medr-invalid-text'>Please enter phone number</p> : null}

            </div>

            <Form.Group controlId="firstLine" className="mb-3">
                <Form.Label className='my-0'>Address First Line</Form.Label>
                <Form.Control
                    type="name"
                    placeholder='Enter first line of address'
                    name="firstLine"
                    value={firstLine}
                    onChange={(e) => onChangeAddress(e)}
                    />
            </Form.Group>

            <Form.Group controlId="city" className="mb-3">
                <Form.Label className='my-0'>Address City</Form.Label>
                <Form.Control
                    type="name"
                    placeholder='Enter city/town'
                    name="city"
                    value={city}
                    onChange={(e) => onChangeAddress(e)}
                    />
            </Form.Group>

            <Form.Group controlId="postcode" className="mb-3">
                <Form.Label className='my-0'>Address Postcode (Required) </Form.Label>
                <Form.Control
                    required
                    type="name"
                    placeholder='Enter postcode'
                    name="postcode"
                    value={postcode}
                    onChange={(e) => onChangeAddress(e)}
                    isInvalid={!formChecks.postcode || gpsError}
                    />
                    <Form.Control.Feedback type="invalid">
                        {gpsError ? "Address not found" : "Please enter a postcode."}
                    </Form.Control.Feedback>
            </Form.Group>


            <Form.Group controlId="insurance_provider" className="mb-3">
                <Form.Label className='my-0'>Insurance provider (Required)</Form.Label>
                <InsuranceAgreementSearchBar agreement={insuranceProviderId} changeValues={changeValue}/>
            </Form.Group>
            {!formChecks.insuranceProviderId ? <p className='medr-invalid-text'>Please enter insurance status</p> : null}


            <Row className='text-left mt-4'>
                <Col className='my-0'>
                    <Form.Group controlId="consent" className="mb-3">
                        <Form.Label className='my-0 custom-checkbox w100 text-left'>
                            <Form.Check className='custom-hidden-checkbox'>
                                <input type='checkbox' data-testid='consent-checkbox' id='consent' checked={consent} onChange={handleConsentChange}/>
                                <div className='d-flex align-items-center'>
                                    <i className="fa-regular fa-square-check big-checkbox custom-unchecked" />
                                    <i className="fa-solid fa-square-check big-checkbox custom-checked" />
                                     Please confirm patient has given consent 
                                </div>
                            </Form.Check>
                        </Form.Label>
                    </Form.Group>
                </Col>
			</Row>
            {!formChecks.consent ? <p className='medr-invalid-text'>Please confirm patient has given consent</p> : null}
                
            <Row className='text-center mt-4'>
                <Col>
                    <Button className="sel-button w80" variant="primary" onClick={onSubmit}>
                        {initNewPatient ? "Add Patient" : "Update Patient"}
                    </Button>
                </Col>
			</Row>
        </Form>

	);
}

PatientEntryForm.propTypes = {
	initNewPatient: PropTypes.bool.isRequired,
	isSuccessful: PropTypes.func,
    // sendPatientId: PropTypes.func,
    currentPatient: patientPropType,
    loadPatient: PropTypes.bool,
};

PatientEntryForm.defaultProps = {
    isSuccessful: null,
    // sendPatientId: null,
    currentPatient: {
        title: '',
        firstName: '',
        lastName: '',
        patientReference: '',
        sex: '',
		dob: null,
		primaryEmail: '',
		primaryPhoneNumber: '',
        address: {
            firstLine:'', 
            city: '',
            postcode:'',
        },
		isSelfPayer:true,
		insurancePolicy:{ id: 0},
	},
    loadPatient: false,
};

const mapStateToProps = (state) => ({
    currentPatient: state.patients.currentPatient,
});

export default connect(mapStateToProps, {})(PatientEntryForm);
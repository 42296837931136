import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import { funcPropType, strPropType, insuranceAgreementsPropType, numberPropType } from "../../constants/propTypes";
import { getInsuranceAgreementsList } from "../../actions/clinicianActions";


function InsuranceAgreementSearchBar({ agreement, changeValues, insuranceAgreements, valueKey }){
    
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInsuranceAgreementsList());
    }, [dispatch]);
    
    const [currentAgreement, setCurrentAgreement] = useState(0);
    const [options, setOptions] = useState([]);

    function getInsuranceAgreementSearchLabel(insuranceAgreement){
        if (insuranceAgreement===null || insuranceAgreement===undefined) return 'Select insurance agreement';
        if (insuranceAgreement.id===0) return 'Self-payer';
        if (insuranceAgreement.policyName === 'Standard') return `${insuranceAgreement.provider}`;
        return `${insuranceAgreement.provider} (${insuranceAgreement.policyName})`;
    }

    function getInsuranceOptions(){
        if (insuranceAgreements===null || insuranceAgreements===undefined || insuranceAgreements.length===0){
            return { valid: false, providerOptions: []}
        };
        
        const policies = insuranceAgreements.map((insuranceAgreement) => ({
            label: getInsuranceAgreementSearchLabel(insuranceAgreement),
            value: insuranceAgreement.id
        }));

        return { valid: true, providerOptions: [{label: "Self-payer", value: 0}, ...policies]};
    }

    function getInsuranceAgreementStrById(){
        if (agreement==null || agreement===undefined || agreement === -1) {
            return 'Select insurance agreement';
        }
        if (agreement === 0) {return 'Self-payer';}

        const insPol = insuranceAgreements.find((policy) => policy.id === agreement);
        return getInsuranceAgreementSearchLabel(insPol);
    }

    useEffect(() => {
        const { valid, providerOptions } = getInsuranceOptions();
        if (valid) setOptions(providerOptions);
    }, [insuranceAgreements]);

    useEffect(() => {
        setCurrentAgreement(getInsuranceAgreementStrById());
    }, [agreement]);

    const customStyles = {
        singleValue:(provided:any) => ({
          ...provided,
          color:'#247986',
        }),
      }
    return (
        <Row className="mb-2">
            <Col>
                <Select
                    options={options}
                    onChange={opt => changeValues({key: valueKey, value: opt.value})}
                    placeholder={currentAgreement}
                    styles={customStyles}
                />
            </Col>
        </Row>
    )
}

InsuranceAgreementSearchBar.propTypes = {
    agreement: numberPropType,
    changeValues: funcPropType.isRequired,
    insuranceAgreements: insuranceAgreementsPropType,
    valueKey: strPropType
};

InsuranceAgreementSearchBar.defaultProps = {
    agreement: -1,
    valueKey: 'insuranceProviderId',
    insuranceAgreements: []
};

const mapStateToProps = state => ({
    insuranceAgreements: state.clinicians.insuranceAgreements
})

export default connect(mapStateToProps, {})(InsuranceAgreementSearchBar);
import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { EmailFormField, PhoneFormField } from '../app/parts/FormFields';
import { appointmentContactPropType, funcPropType } from '../../constants/propTypes';
import AppointmentsContactBox from '../clinicians/AppointmentsContactBox';

function UserAppointmentsContactForm({ values, changeValues }) {
    
    const [appointmentsContacts, setAppointmentsContacts] = useState(values.appointmentsContacts);
    const [formData, setFormData] = useState({email: '', phoneNumber: '', emailValid: false, phoneValid: false});
    const { email, phoneNumber } = formData;
    
    const updateCurrentValues = (e) => {
        if (e.key==='email'){
            setFormData({...formData, [e.key]: e.value, emailValid: e.isValid});
        } else if (e.key==='phoneNumber'){
            setFormData({...formData, [e.key]: e.value, phoneValid: e.isValid});
        }
    }
    
    const addReferralsEmail = () => {
        if (email==='' || !formData.emailValid){
            return;
        }
        const isInList = appointmentsContacts.some(contact => contact.email === email);
        if (!isInList){
            const newContacts = [...appointmentsContacts, {type: 'email', email, phoneNumber: ''}];
            setAppointmentsContacts(newContacts);
            changeValues({key: 'appointmentsContacts', value: newContacts});
        }
        setFormData({...formData, email: ''});
    }

    const addReferralsPhone = () => {
        if (phoneNumber==='' || !formData.phoneValid){
            return;
        }
        const isInList = appointmentsContacts.some(contact => contact.phoneNumber === phoneNumber);
        if (!isInList){
            const newContacts = [...appointmentsContacts, {type: 'phoneNumber', phoneNumber, email: ''}];
            setAppointmentsContacts(newContacts);
            changeValues({key: 'appointmentsContacts', value: newContacts});
        }
        setFormData({...formData, phoneNumber: ''});
    }
    
    const removeAppointmentsContact = (e) => {
        const newContacts = appointmentsContacts.filter(contact => contact.type !== e.type || contact.email !== e.email || contact.phoneNumber !== e.phoneNumber);
        setAppointmentsContacts(newContacts);
        changeValues({key: 'appointmentsContacts', value: newContacts});
    }


	return (
        <>  
            <div className='medr-rounded mt-3'>
                <div className="mb-3">
                    <EmailFormField email={email} emailKey='email' emailLabel='Referrals email' changeValues={updateCurrentValues} />
                </div>
                <Row>
                    <Col className='text-center'>
                        <Button className='sel-button w100' onClick={addReferralsEmail}>Email for referrals</Button>
                    </Col>
                </Row>
            </div>
            <div className='medr-rounded mt-3'>
                <div className="mb-3">
                    <PhoneFormField currentPhoneNo={phoneNumber} nameKey='phoneNumber' nameLabel='Referrals phone number' changeValues={updateCurrentValues} />
                </div>
                <Row>
                    <Col className='text-center'>
                        <Button className='sel-button w100' onClick={addReferralsPhone}>Phone for referrals</Button>
                    </Col>
                </Row>
            </div>
            <div className="medr-rounded-inv mt-3">
                <h6 className='text-center'>Referrals contacts</h6>
                {appointmentsContacts ? appointmentsContacts.map((contact, index) => <AppointmentsContactBox key={`ref${uuidv4()}`}contact={contact} index={index} removeAppointmentsContact={removeAppointmentsContact}/>) : null}
            </div>
        </>
	);
}
UserAppointmentsContactForm.propTypes = {
	values: appointmentContactPropType.isRequired,
    changeValues: funcPropType.isRequired,
}

export default UserAppointmentsContactForm;

import React from 'react';
import { Form } from 'react-bootstrap';
import { funcPropType } from '../../constants/propTypes';


function PatientFilterInput({ setFilterString }) {
    const updateStrs = (e) => {
        e.preventDefault()
        setFilterString(e.target.value);
    }

	return (
        <Form className='text-left'>
            <Form.Group controlId="pxSearchBar" className="mt-3 mb-3">
                <Form.Control
                    required
                    type="name"
                    placeholder="Search patient by name"
                    name="searchStr"
                    onChange={(e) => updateStrs(e)}
                    />
            </Form.Group>

        
        </Form>

	);
}

PatientFilterInput.propTypes = {
    setFilterString: funcPropType.isRequired
}

export default PatientFilterInput;
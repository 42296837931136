import React, { useState, useEffect } from 'react';
import { Form, Button, Row } from 'react-bootstrap';
import { useNavigate, Navigate,  useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { isAuthenticatedProptype } from '../../constants/propTypes';
import  { resetPasswordSetNew } from '../../actions/authActions';

function SendResetPassswordScreen({ isAuthenticated }) {
	
	if (isAuthenticated) return <Navigate to="/dashboard" />;

	const { userHash, timeHash, verificationCode } = useParams();

	const dispatch = useDispatch();
    const [formData, setFormData] = useState({
		userHash,
		timeHash,
		verificationCode,
        password: '',
		rePassword: '',
	});

    const [passwordsMatch, setPasswordsMatch] = useState(false);
	const { password, rePassword } = formData;


    useEffect(() => {
        setPasswordsMatch(formData.password===formData.rePassword);
    }, [formData.password, formData.rePassword])

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	}

	const navigate = useNavigate();

	const isSuccess = (value)	=> value===true ? navigate('/login') : null;

	const onSubmit = (e) => {
		e.preventDefault();
        if (passwordsMatch) {
			dispatch(resetPasswordSetNew(formData, isSuccess))
        }
	};

	return (
		<div className='medr-layout text-center dashboard-page' style={{maxWidth:'380px', marginLeft: 'auto', marginRight: 'auto'}}>
			<h1>Set new password</h1>
			<Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className='py-2 text-left' controlId="password">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter Password"
						name="password"
						value={password}
						onChange={(e) => onChange(e)}
					/>
				</Form.Group>
                <Form.Group className='py-2 text-left' controlId="rePassword">
					<Form.Label>Re-enter Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter Password"
						name="rePassword"
						value={rePassword}
                        isInvalid={!passwordsMatch}
						onChange={(e) => onChange(e)}
					/>
                    <Form.Control.Feedback type="invalid">
                        Passwords do not match!
                    </Form.Control.Feedback>
				</Form.Group>
				<Row className='mt-5'>
					<div className='col-12'>
						<Button type="submit" variant="primary" className="sel-button w80">
						Set password
						</Button>
					</div>
				</Row>
			</Form>
		</div>
	);
}

SendResetPassswordScreen.propTypes = {
	isAuthenticated: isAuthenticatedProptype.isRequired
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { })(SendResetPassswordScreen);

import React, { useState, useEffect } from 'react';
import { Row,  Col,Form, Button } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import TitleDropdown from "../TitleDropdown";
import { EmailFormField } from "../app/parts/FormFields";
import { registerCheckEmail } from '../../actions/authActions';



function RegisterPersonalDetailsForm({ values, changeValues, handleNext }){
    // const dispatch = useDispatch();
    useEffect(() => {window.scroll(0,0)}, []);

    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [dupEmail, setDupEmail] = useState(false);

    const checkFormReady = () =>{
        const ready = values.title && values.firstName && values.lastName && values.email && values.password && values.rePassword;
        return ready;
    }

    const handleEmailCheck = () => {
        registerCheckEmail(values.email).then((emailValid) => {
            setDupEmail(!emailValid);
        });
    }

    useEffect(() => {
        setPasswordsMatch(values.password===values.rePassword);
    }, [values.password, values.rePassword])

    useEffect(() => {handleEmailCheck()}, [values.email]);

    const handleTitle = (title) => changeValues({key: 'title', value: title});

    const handleNextStep = (e) => {
        e.preventDefault();

        const formComplete = checkFormReady();

        if (!passwordsMatch){return;}
        if (!formComplete){return;}
        if (dupEmail){return;}
        handleNext();
    }

    return (
        <div className='register-user-page'>
            <h4>Personal Details</h4>
            <Row>
                <Col>
                    <Form onSubmit={handleNextStep}>
                        <TitleDropdown currentTitle={values.title} sendTitle={handleTitle} />
                        <Form.Group controlId="firstName" className='mt-2'>
                            <Form.Label className='my-0'>First name</Form.Label>
                            <Form.Control 
                                required
                                type="name"
                                placeholder="Enter first name"
                                defaultValue={values.firstName}
                                onChange={(e) => changeValues({key: 'firstName', value: e.target.value})}
                            />
                        </Form.Group>

                        <Form.Group controlId="lastName" className='mt-2'>
                            <Form.Label className='my-0'>Last name</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter last name"
                                defaultValue={values.lastName}
                                onChange={(e) => changeValues({key: 'lastName', value: e.target.value})}
                            />
                        </Form.Group>

                        <EmailFormField email={values.email} emailKey='email' emailLabel="User contact email" changeValues={changeValues} failedRegistration={dupEmail}/>

                        <Form.Group controlId="password" className='mt-2'>
                            <Form.Label className='my-0'>Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Enter Password"
                                defaultValue={values.password}
                                onChange={(e) => changeValues({key: 'password', value: e.target.value})}
                            />
                        </Form.Group>

                        <Form.Group controlId="rePassword" className='mt-2'>
                            <Form.Label className='my-0'>Confirm Password</Form.Label>
                            <Form.Control
                                required
                                type="password"
                                placeholder="Confirm Password"
                                defaultValue={values.rePassword}
                                onChange={(e) => changeValues({key: 'rePassword', value: e.target.value})}
                                isInvalid={!passwordsMatch}
                            />
                            <Form.Control.Feedback type="invalid">
                                Passwords do not match!
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button 
						className="sel-button mt-4 w100" type="submit" variant="primary" >Next</Button>
                    </Form>
                </Col>
            </Row>
        </div>

    )
};

RegisterPersonalDetailsForm.propTypes = {
	values: PropTypes.shape({
        // jobRoleId: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        rePassword: PropTypes.string.isRequired,
    }).isRequired,
	changeValues: PropTypes.func.isRequired,
	handleNext: PropTypes.func.isRequired,
};

export default RegisterPersonalDetailsForm;
import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { appointmentsContactPropType, funcPropType, numberPropType } from '../../constants/propTypes';


function AppointmentsContactBox({ contact, index, removeAppointmentsContact}){
    if (contact===null || !contact.type ) return null;

    return (
        <Row key={`${index+1}appCont`}>
            <Col className='text-center col-12 col-md-8'>
                {contact.type==='email' ? <p className='m-0 p-1 text-left'>Email: <a className='appointment-box-link' href={`mailto:"${contact.email}`}>{contact.email}</a></p> : null}
                {contact.type==='phoneNumber' ? <p className='m-0 p-1 text-left'>Phone: <a className='appointment-box-link' href={`tel:"${contact.phoneNumber}`}>{contact.phoneNumber}</a></p> : null}
            </Col>
            {removeAppointmentsContact!==null ?
                    <Col className='text-center p-1 col-12 col-md-4 mb-4 mb-md-0'>
                        <Button  className='sel-button w50' variant='danger' onClick={() => removeAppointmentsContact(contact)}>Remove</Button>
                    </Col>
            : null}
        </Row>
    )
}


AppointmentsContactBox.propTypes = {
	contact: appointmentsContactPropType.isRequired,
    index: numberPropType.isRequired,
    removeAppointmentsContact: funcPropType,
}

AppointmentsContactBox.defaultProps = {
    removeAppointmentsContact: null,
}

export default AppointmentsContactBox;

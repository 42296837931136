import * as CONSTS from '../constants/clinicianConstants';
import * as INIT from "../constants/initDataConstants";

const initialState = {
	loading: false,
	clinician:INIT.initCRP,
	clinicianCrps: [],
	cliniciansData: {
		role: 'Any',
		targetCoords: { lat: 0, lng: 0 },
		clinicianList: [],
	},
	jobRoles: [],
	doctorIds: [],
	clinicianTypes: [],
	specialties: {},
	insuranceAgreements: [],
	clinics: [],
	doctor_clinics: [],
	search: {
		jobRoleId: 1,
		insuranceAgreementId:0,
		lat: 0,
		lng: 0,
	},
	clinicianList: []
};

// eslint-disable-next-line default-param-last
export default function clinicians(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.CLINICIAN_LIST_REQUEST:
		case CONSTS.CLINICS_LIST_REQUEST:
		case CONSTS.CLINICIAN_BY_ID_REQUEST:
		case CONSTS.USER_CRPS_GET_REQUEST:
		case CONSTS.CLINICIAN_ROLE_DEACTIVATE_REQUEST:
		case CONSTS.JOB_ROLE_LIST_REQUEST:
		case CONSTS.JOB_ROLE_SET_ID_REQUEST:
		case CONSTS.INSURANCE_AGREEMENT_LIST_REQUEST:
		case CONSTS.INSURANCE_AGREEMENT_SET_ID_REQUEST:
			return {
				...state,
				loading: true,
			};

		case CONSTS.CLINICIAN_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				cliniciansData: payload,
				clinicianList: payload.clinicianList,
			};
		case CONSTS.CLINICS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				clinics: payload.clinics,
				doctor_clinics: payload.doctor_clinics,
			};
		case CONSTS.CLINICIAN_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				clinician: payload.crp,
				clinicianCrps: payload.allCrps,
			};
		case CONSTS.USER_CRPS_GET_SUCCESS:
			return {
				...state,
				loading: false,
				clinician: payload.crp,
				clinicianCrps: payload.allCrps,
			};
		case CONSTS.CLINICIAN_BY_ID_FAIL:
		case CONSTS.USER_CRPS_GET_FAIL:
			return {
				...state,
				loading: false,
				clinician:INIT.initCRP,
				clinicianCrps: [],
			};
		case CONSTS.JOB_ROLE_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				jobRoles: payload.jobRoles,
				doctorIds: payload.doctorIds,
				clinicianTypes: payload.clinicianTypes,
				specialties: payload.specialties,
			};
		case CONSTS.JOB_ROLE_SET_ID_SUCCESS:
			return {
				...state,
				loading: false,
				search: {
					jobRoleId: payload.id
				}
			};
		case CONSTS.INSURANCE_AGREEMENT_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				insuranceAgreements: payload.insuranceAgreements,
			};
		case CONSTS.INSURANCE_AGREEMENT_SET_ID_SUCCESS:
			return {
				...state,
				loading: false,
				search: {
					insuranceAgreementId: payload.insuranceAgreement.id
				}
			};
		case CONSTS.CLINICIAN_LIST_FAIL:
		case CONSTS.CLINICS_LIST_FAIL:
		case CONSTS.JOB_ROLE_LIST_FAIL:
		case CONSTS.CLINICIAN_ROLE_DEACTIVATE_SUCCESS:
		case CONSTS.CLINICIAN_ROLE_DEACTIVATE_FAIL:
		case CONSTS.JOB_ROLE_SET_ID_FAIL:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

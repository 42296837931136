import React from "react";
import { Row, Col, Button  } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { getAddressMultiline } from '../../actions/general';
import { clinicanProfilePropType } from '../../constants/propTypes';


function ClinicsDisplayCard({ values, editable, removeClinic }){
    
    if (values===null || values===undefined){
        return (
            <div className='medr rounded'>
                <Row>
                    <Col>
                        <h4>Error retrieving clinic details</h4>
                    </Col>
                </Row>
            </div>
        )
    };

    const { clinicName, centreName, address } = values;
    const clinicRef =`clinic${uuidv4()}`;

    return (
        <div className='medr-rounded-inv mb-3' id={clinicRef}>
            <Row className='text-left p-3'>
                <Col sm={6}>
                    <h4>{clinicName}</h4>
                    <h6>{centreName}</h6>
                </Col>
                <Col sm={6}>
                    {getAddressMultiline(address)}
                </Col>
            </Row>
            {editable && (
                <Row className='text-center'>
                    <Col className='max-width-clin-col'>
                        <Button variant='danger' className='sel-button p-1' id={values.id} onClick={() => removeClinic(values)}>
                            Remove
                        </Button>
                    </Col>
                </Row>
            )}

        </div>
    )
};

ClinicsDisplayCard.propTypes = {
    values: clinicanProfilePropType.isRequired,
    editable: PropTypes.bool.isRequired,
    removeClinic: PropTypes.func.isRequired,
};

export default ClinicsDisplayCard;
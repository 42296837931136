import * as CONSTS from '../constants/patientConstants';
import * as INIT from "../constants/initDataConstants";
import { LOGOUT_SUCCESS } from '../constants/authConstants';

const initialState = {
	currentPatient: INIT.initPatient,
	currentPatientReferrals: {
		activeReferrals: [],
		inactiveReferrals: []
	},
	patientList: null,
	activePatients: [],
	pastPatients: [],
	acceptedPatients: [],
	waitingPatients: [],
};

// eslint-disable-next-line default-param-last
export default function patients(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.CURRENT_PATIENT_REQUEST:
		case CONSTS.CREATE_PATIENT_REQUEST:
		case CONSTS.CREATE_PATIENT_BY_IMAGE_REQUEST:
		case CONSTS.CLINICIAN_PATIENTS_REQUEST:
		case CONSTS.PATIENT_LIST_REQUEST:
		case CONSTS.PATIENT_REFERRALS_REQUEST:
		case CONSTS.GPS_COORDS_REQUEST:
			return {
				...state,
			};
		case CONSTS.PATIENT_LIST_SUCCESS:
			return {
				...state,
				activePatients: payload,
				pastPatients: payload,
				referredPatients: payload,
			};

		case CONSTS.PATIENT_LIST_FAIL:
			return {
				...state,
				patientList: payload,
			};
		case CONSTS.CLINICIAN_PATIENTS_SUCCESS:
			return {
				...state,
				activePatients: payload.activePatients,
				pastPatients: payload.pastPatients,
				acceptedPatients: payload.acceptedPatients,
				waitingPatients: payload.waitingPatients,
			};
		case CONSTS.CLINICIAN_PATIENTS_FAIL:
			return {
				...state,
				activePatients: [],
				pastPatients: [],
			};
		case CONSTS.GPS_COORDS_SUCCESS:
			return {
				...state,
				currentPatient: payload,
			};
		case CONSTS.GPS_COORDS_FAIL:
			return {
				...state,
				current_patient: {
					post_code: '',
					lat: 0,
					lng: 0,
				},
			};
		case CONSTS.CURRENT_PATIENT_SUCCESS:
			return {
				...state,
				currentPatient: payload.patient,
			};
		case CONSTS.CURRENT_PATIENT_FAIL:
		case CONSTS.CURRENT_PATIENT_RESET:
			return {
				...state,
				currentPatient: INIT.initPatient,
			};
		case CONSTS.PATIENT_REFERRALS_SUCCESS:
			return {
				...state,
				currentPatientReferrals: payload,
			};
		case CONSTS.PATIENT_REFERRALS_FAIL:
			return {
				...state,
			};
		case CONSTS.CREATE_PATIENT_SUCCESS:
			return {
				...state,
				currentPatient: payload.patient,
			};
		case CONSTS.CREATE_PATIENT_FAIL:
			return {
				...state,
			};
		case CONSTS.CREATE_PATIENT_BY_IMAGE_SUCCESS:
			return {
				...state,
				currentPatient: payload.patient,
			};
		case CONSTS.CREATE_PATIENT_BY_IMAGE_FAIL:
			return {
				...state,
			};
		case LOGOUT_SUCCESS:
			return {...initialState};
		default:
			return state;
	}
}

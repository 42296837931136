import React, { useEffect, useState } from 'react';
import { Table, Button, Tabs, Tab } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { userProfilesPropType } from '../../constants/propTypes';
import { getAdminUsersList } from '../../actions/adminActions';
import PaginationBar from '../app/PaginationBar';
import VerifyClinicianPopup from './popups/VerifyClinicianPopup';
import useAdminUsersList from '../../hooks/admin/useAdminUsersList';

function AdminUsersTable({ usersList, unverifiedUsersList }) {
    const nUsersWindow = 18
    const start = 0;
    const end = nUsersWindow;
    const [usrList, setUsrList] = useState([])
    const [usrUnverifiedList, setUsrUnverifiedList] = useState([])
	const [showVerificationPopup, setShowVerificationPopup] = useState(false);
    const [clinicianToChangeVerification, setClinicianToChangeVerification] = useState(null);

    const dispatch = useDispatch();
    const refreshUsersLists = () => dispatch(getAdminUsersList());
	useEffect(() => {refreshUsersLists();}, [dispatch]);

    useEffect(() => {
        if (usersList===null || usersList===undefined || usersList.length===0){
            setUsrList([]);
            return;
        }
        setUsrList(usersList);
    }, [usersList])

    useEffect(() => {
        if (unverifiedUsersList===null || unverifiedUsersList===undefined || unverifiedUsersList.length===0){
            setUsrUnverifiedList([]);
            return;
        }
        setUsrUnverifiedList(unverifiedUsersList);
    }, [unverifiedUsersList])

    const [
        users,
        updateUsersSlice,
        usersLength
    ] = useAdminUsersList(usrList, start, end);
    const [
        unverifiedUsers,
        updateUnverifiedUsersSlice,
        unverifiedUsersLength
    ] = useAdminUsersList(usrUnverifiedList, start, end);

    
    useEffect(() => {
        if (clinicianToChangeVerification === null || clinicianToChangeVerification === undefined) return;
        setShowVerificationPopup(true);
    }, [clinicianToChangeVerification]);

    const handleCloseVerificationPopup = () => {
        setShowVerificationPopup(false);
        setClinicianToChangeVerification(null);
    }

    const unverifiedUsersTableContents = unverifiedUsers ? unverifiedUsers.map((userProfile, index) => (
        <tr key={userProfile.id}>
                <td>{index+1}</td>
                <td>{userProfile.id}</td>
                <td>
                    {userProfile.firstName} {userProfile.lastName}
                </td>
                <td>{userProfile.user.email}</td>
                <td>
                    {userProfile.user.is_staff ? (
                        <i className="fas fa-check" style={{ color: 'green' }} />
                        ) : (
                            <i className="fas fa-check" style={{ color: 'red' }} />
                            )}
                </td>
                <td>			
                    <Button id={`verifyClinician${userProfile.id}`} variant="light" className="btn-sm" onClick={() => setClinicianToChangeVerification({userProfile, setVerified: true})}>
				        <i className="fas fa-user-plus" style={{color:'green'}}/>
                    </Button>
                </td>
            </tr>
        ))
    : null;

    const usersTableContents = users ? users.map((userProfile, index) => (
        <tr key={userProfile.id}>
                <td>{index+1}</td>
                <td>{userProfile.id}</td>
                <td>
                    {userProfile.firstName} {userProfile.lastName}
                </td>
                <td>{userProfile.user.email}</td>
                <td>
                    {userProfile.user.is_staff ? (
                        <i className="fas fa-check" style={{ color: 'green' }} />
                        ) : (
                            <i className="fas fa-check" style={{ color: 'red' }} />
                            )}
                </td>
                <td>
                    <Button  id={`verifyClinician${userProfile.id}`} variant="light" className="btn-sm" onClick={() => setClinicianToChangeVerification({userProfile, setVerified: false})}>
                        <i className="fas fa-user-minus"  style={{color:'red'}}/>
                    </Button>
                </td>
            </tr>
        ))
    : null;

    const unverifiedHeadersRow = (
        <tr>
            <th>Row</th>
            <th>ID</th>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>MEDR STAFF</th>
            <th>Verify</th>
        </tr>
    )

    const verifiedHeadersRow = (
        <tr>
            <th>Row</th>
            <th>ID</th>
            <th>NAME</th>
            <th>EMAIL</th>
            <th>MEDR STAFF</th>
            <th>Unverify</th>
        </tr>
    )

    const noUsersDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No users to display at the moment</h4>
            </div>
        </div>
    )

    return (
        	<Tabs
				defaultActiveKey="unverifiedUsers"
				id="custom-tabs"
				className="custom-tabs mb-3"
				>
				<Tab eventKey="unverifiedUsers" title="Unverified users" className="custom-tabs">
                    <VerifyClinicianPopup userInfo={clinicianToChangeVerification} showModal={showVerificationPopup} handleClose={handleCloseVerificationPopup} />
                    {unverifiedUsersLength===0 && noUsersDisplay}
                    {unverifiedUsersLength>0 && (
                        <Table striped bordered hover responsive className="table-sm">
                            <thead>
                                {unverifiedHeadersRow}
                            </thead>
                            <tbody>
                                {unverifiedUsersTableContents}
                            </tbody>
                        </Table>
                    )}
                    {unverifiedUsersLength > nUsersWindow && (
                        <div className='mb-3'>
                            <PaginationBar arrayLength={unverifiedUsersLength} setSliceValues={updateUnverifiedUsersSlice} windowSize={nUsersWindow}/>
                        </div>
                    )}
				</Tab>
				<Tab eventKey="users" title="Users" className="custom-tabs">
                    {usersLength===0 && noUsersDisplay}
                    {usersLength > 0 && (
                        <Table striped bordered hover responsive className="table-sm">
                            <thead>
                                {verifiedHeadersRow}
                            </thead>
                            <tbody>
                                {usersTableContents}
                            </tbody>
                        </Table>
                    )}
                    {usersLength > nUsersWindow && (
                        <div className='mb-3'>
                            <PaginationBar arrayLength={usersLength} setSliceValues={updateUsersSlice} windowSize={nUsersWindow}/>
                        </div>
                    )}
				</Tab>
			</Tabs>
    )

}

AdminUsersTable.propTypes = {
	usersList: userProfilesPropType.isRequired,
	unverifiedUsersList: userProfilesPropType.isRequired
};

const mapStateToProps = (state) => ({
	usersList: state.admin.usersList,
	unverifiedUsersList: state.admin.unverifiedUsersList,
});

export default connect(mapStateToProps, {})(AdminUsersTable);

import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { isAuthenticatedProptype } from '../../constants/propTypes';
import { verifyUserEmail } from '../../actions/authActions';

function RegistrationProgressScreen({ step, isAuthenticated }) {

	if (isAuthenticated) return <Navigate to="/dashboard" />;
    const [stepHasFailed, setStepHasFailed] = useState(false);

    const step1 = (
            <>
                <Row className='registration-steps text-center mt-5'>
                    <Col>
                        <i className="fa-solid fa-check-circle"/>
                    </Col>
                    <Col>
                        <i className="fa-regular fa-check-circle"/>
                    </Col>
                    <Col>
                        <i className="fa-regular fa-check-circle"/>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-5 text-center'>
                        <p className='registration-step-info'>Check you email for the verification link</p>
                    </Col>
                </Row>
            </>
        )
    const step2 = stepHasFailed ? (
        <>
            <Row className='registration-steps text-center mt-5'>
                <Col>
                    <i className="fa-solid fa-check-circle"/>
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle"/>
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle"/>
                </Col>
            </Row>
            <Row>
                <Col className='mt-5 text-center text-center'>
                    <p className='registration-step-info'>Email verification has failed, MedR team will review your registration request. This can take up to 48 hours. </p>
                </Col>
            </Row>
        </>
    ) : (
        <>
            <Row className='registration-steps text-center mt-5'>
                <Col>
                    <i className="fa-solid fa-check-circle"/>
                </Col>
                <Col>
                    <i className="fa-solid fa-check-circle"/>
                </Col>
                <Col>
                    <i className="fa-regular fa-check-circle"/>
                </Col>
            </Row>
            <Row>
                <Col className='mt-5 text-center text-center'>
                    <p className='registration-step-info'>Email has been verified, please wait for the MedR team to approve you account. This can take up to 48 hours. </p>
                </Col>
            </Row>
        </>
    )

    const step3 = (
        <>
            <Row className='registration-steps mt-5'>
                <Col>
                    <i className="fa-solid fa-check-circle"/>
                </Col>
                <Col>
                    <i className="fa-solid fa-check-circle"/>
                </Col>
                <Col>
                    <i className="fa-solid fa-check-circle"/>
                </Col>
            </Row>
            <Row>
                <Col className='mt-5 text-center'>
                    <p className='registration-step-info'>Your account has been approved!</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button>Please Login</Button>
                </Col>
            </Row>
        </>
    )

    const s2Success = (value) => {
        setStepHasFailed(!value);

        if (value===true) {
            <Navigate to="/registered/verification-pending" />;
        } 
    }

    const dispatch = useDispatch();
    const params = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
        if (step===1) {
            // send verify account
            const { userHash, timeHash, verificationCode } = params;
            dispatch(verifyUserEmail(userHash, timeHash, verificationCode, s2Success));
        }
    }, [])

    
	return (
		<>
            <Row>
                <Col className='text-center'>
                    <h1>Registration step {step+1} of 3</h1>
                </Col>
            </Row>
            {step===0 ? step1 : null}
            {step===1 ? step2 : null}
            {step===2 ? step3 : null}
		</>
	);
}

RegistrationProgressScreen.propTypes = {
    isAuthenticated: isAuthenticatedProptype.isRequired,
    step: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { })(RegistrationProgressScreen);

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import axios from 'axios';
import store from './store';
import "./index.css";
import './bootstrap.min.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { unauthResponseClear } from "./actions/storageActions";


if (window.location.origin === "http://localhost:3000") {
  axios.defaults.baseURL = "http://localhost:8000/";
} else {
  axios.defaults.baseURL = `${window.location.origin}`;
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
	if (error.response && error.response.status === 401) {
	  unauthResponseClear();
	  window.location.href = "/login";

	}
	return Promise.reject(error);
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);

reportWebVitals();
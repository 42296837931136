import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../index.css';

import Header from '../components/app/Header';
import Footer from '../components/app/Footer';
import Message from '../components/app/Message'

 

function Layout({ children }) {
	return (
		<>
			<Header />
			<div id='mainContainer' className="py-3 medr-body">
				<Container>
					<Message />
					{children}
				</Container>
			</div>
			<Footer />
		</>

	);
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
  }

export default connect(null, {})(Layout);

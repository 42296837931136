import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sendResetPassword, login } from '../../actions/authActions';

function ProfileChangePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initData = {
        currentPassword: '',
        password: '',
        rePassword: '',
    }

    const [values, setValues] = useState(initData);
    const [passwordsMatch, setPasswordsMatch] = useState(false);

    const { currentPassword, password, rePassword } = values;

    useEffect(() => {
        setPasswordsMatch(password===rePassword);
    }, [password, rePassword])

    const reAuthSuccess = (value) => {
            if (value) {
                navigate('/dashboard');
            }
        }
    const passwordChangeSuccess = (value) => {
        if (value) {
            const auth = JSON.parse(localStorage.getItem('auth'));
            const { username }  = auth.user.user;
            dispatch(login(username, password, reAuthSuccess));
        } else {
            navigate('/login');
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = {currentPassword, newPassword: password};
        dispatch(sendResetPassword(formData, passwordChangeSuccess));
    }

	return (
        <Form onSubmit={onSubmit} className='text-left'>
            <Form.Group controlId="password" className='pb-3'>
                <Form.Label className='my-0'>Current Password</Form.Label>
                <Form.Control
                    required
                    type="password"
                    placeholder="Enter Current Password"
                    value={currentPassword}
                    onChange={(e) => setValues({...values, currentPassword: e.target.value})}
                    autoComplete="password"
                />
            </Form.Group>

            <Form.Group controlId="newPassword" className='pb-3'>
                <Form.Label className='my-0'>Password</Form.Label>
                <Form.Control
                    required
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setValues({...values, password: e.target.value})}
                    autoComplete="newPassword"
                />
            </Form.Group>

            <Form.Group controlId="reNewPassword" className='pb-3'>
                <Form.Label className='my-0'>Confirm Password</Form.Label>
                <Form.Control
                    required
                    type="password"
                    placeholder="Confirm Password"
                    value={rePassword}
                    onChange={(e) => setValues({...values, rePassword: e.target.value})}
                    isInvalid={!passwordsMatch}
                    autoComplete="reNewPassword"
                />
                <Form.Control.Feedback type="invalid">
                    Passwords do not match!
                </Form.Control.Feedback>
            </Form.Group>   

            <Row className='text-center mt-4'>
				<Col>
					<Button className="sel-button w80" type="submit" variant="primary">Set password</Button>
				</Col>
			</Row>

        </Form>

	);
}

export default ProfileChangePassword;
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import  { userProfilePropType, boolPropType, funcPropType } from '../../constants/propTypes';

function UpdateProfilePopup({ userProfile, showModal, handleClose }){
    if (!userProfile || userProfile===undefined || !userProfile.clinicianDescription || !userProfile.profilePicture) {
        return null;
    }

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Profile updates suggested</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {userProfile.clinicianDescription==='' ? (<p>Your clinician description has not been set yet</p>) : null}
                {userProfile.profilePicture.includes('profile-ppr-default') ? (<p>Your profile picture has not been updated</p>) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" href="/profile">
                    Update profile
                </Button>
                <Button variant="info" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

UpdateProfilePopup.propTypes = {
    userProfile: userProfilePropType.isRequired,
    showModal: boolPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default UpdateProfilePopup;
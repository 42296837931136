import React, { useEffect, useState } from"react";
import { connect } from 'react-redux';
import { Row,  Col, Button  } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { funcPropType, insuranceAgreementsPropType } from "../../constants/propTypes";
import InsuranceDisplayBox from "../clinicians/InsuranceDisplayBox";
import InsuranceProviderForm from "../clinicians/InsuranceProviderForm";
import InsuranceSelectorGrid from "./InsuranceSelectorGrid";

function RegisterInsuranceDetails({ values, changeValues, handleNext, insuranceAgreements }){

    const [agreements, setAgreements] = useState(null);
    const [showNewProvider, setShowNewProvider] = useState(false);

    useEffect(() => {window.scroll(0,0)}, []);
    
    useEffect(() => {setAgreements(insuranceAgreements)}, [insuranceAgreements]);

    const handleAddNewProvider = (e) => {
        const newList = values.newProviders;
        newList.push(e);
        changeValues({key: 'newProviders', value: newList});
    }

    const handleAddExisitngProvider = (e) => {
        if (agreements!==null && agreements!==undefined && agreements.length>0 && e.value!==0){
            const newList = values.existingProviders;
            const isIdNotInList = !newList.some(item => item.id === e.value);
            if(isIdNotInList){
                const insuranceProvider = agreements.filter(item => item.id === e.value)[0];
                newList.push(insuranceProvider);
                changeValues({key: 'existingProviders', value: newList});
            }
        }
    }

    const handleRemoveProvider = (e) => {
        if (e.key==='newProvider'){
            const newList = values.newProviders.filter(item => item.provider !== e.provider);
            changeValues({key: 'newProviders', value: newList});

        } else if (e.key==='existingProvider'){
            const newList = values.existingProviders.filter(item => item.id !== e.value);
            changeValues({key: 'existingProviders', value: newList});
        }
    }

    const toggleProvider = (e) => {
        if (e.isSelected){
            handleAddExisitngProvider({value: e.value})
        } else {
            handleRemoveProvider({key: 'existingProvider', value: e.value})
        }
    }

    return (
        <div className='insurance-page'>
            <Row className='my-3'>
                <Col className='text-center'>
                    <h4>Add accepted insurances</h4>
                </Col>
            </Row>

            <InsuranceSelectorGrid existingProviders={values.existingProviders} toggleProvider={toggleProvider}/>
            
            <Row className='my-3'>
                <Col className='text-center'>
                    <Button className="sel-button w80" variant='primary' onClick={() => setShowNewProvider(!showNewProvider)}>{showNewProvider ? "Hide form" : "Add new provider"}</Button>
                </Col>
            </Row>
            
            {showNewProvider ? <InsuranceProviderForm handleAddProvider={handleAddNewProvider} />:null}
            
            <InsuranceDisplayBox newProviders={values.newProviders} existingProviders={values.existingProviders} editable={!values.isReview} removeProvider={handleRemoveProvider}/>
            
            <Row>
                <Col className='text-center'>
                    <Button className='sel-button w80 my-4' variant='primary' onClick={handleNext}>Next</Button>
                </Col>
            </Row>
        </div>

    )
};

RegisterInsuranceDetails.propTypes = {
    values: PropTypes.shape({
        isReview: PropTypes.bool.isRequired,
        existingProviders: PropTypes.arrayOf(PropTypes.shape({
            provider: PropTypes.string.isRequired,
        })).isRequired,
        newProviders: PropTypes.arrayOf(PropTypes.shape({
            provider: PropTypes.string.isRequired,
        })).isRequired,
    }).isRequired,
	changeValues: funcPropType.isRequired,
	handleNext: funcPropType.isRequired,
    insuranceAgreements: insuranceAgreementsPropType.isRequired,
};

const mapStateToProps = state => ({
    insuranceAgreements: state.clinicians.insuranceAgreements
})

export default connect(mapStateToProps, {})(RegisterInsuranceDetails);
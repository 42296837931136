import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { funcPropType, boolPropType } from '../../constants/propTypes';


function HelpRequestPopup({ showModal, handleClose }) {
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/dashboard')
        handleClose();
    }
	return (
        <Modal show={showModal} onHide={onClick}>
            <Modal.Header closeButton>
                <Modal.Title className='medr-inv'>Help request sent</Modal.Title>
            </Modal.Header>
            <Modal.Body className='medr'>
                <p>Your request has been sent to the MedR team. We will respond as soon as possible.</p>
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={onClick}>
                        Close
                    </Button>
            </Modal.Footer>
        </Modal>
	);
}

HelpRequestPopup.propTypes = {
    showModal: boolPropType.isRequired,
    handleClose: funcPropType.isRequired
}

export default HelpRequestPopup;

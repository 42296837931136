import * as CONSTS from '../constants/searchConstants';

const initialState = {
	loading: false,
	errorMessage:'',
	referrerCrp: {},
	targetCrp: {},
    patient: {},
    jobRole:{id:0},
	postCode: "",
    lat: CONSTS.DEFAULT_LAT,
    lng: CONSTS.DEFAULT_LNG,
	mapsZoom: 10
};

// eslint-disable-next-line default-param-last
export default function search(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.SET_JOB_ROLE_REQUEST:
		case CONSTS.SET_JOB_ROLE_NULL_REQUEST:
		case CONSTS.SET_PATIENT_REQUEST:
		case CONSTS.SET_PATIENT_NULL_REQUEST:
		case CONSTS.SET_CRP_REQUEST:
		case CONSTS.SET_CRP_NULL_REQUEST:
		case CONSTS.SET_CLINICIAN_REQUEST:
		case CONSTS.SET_CLINICIAN_NULL_REQUEST:
		case CONSTS.SET_POSTCODE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.SET_JOB_ROLE_SUCCESS:
			return {
				...state,
				loading: false,
				jobRole: payload.jobRole
			};
		case CONSTS.SET_JOB_ROLE_NULL_SUCCESS:
			return {
				...state,
				loading: false,
				jobRole: {id:0}
			};
		case CONSTS.SET_PATIENT_SUCCESS:
			return {
				...state,
				loading: false,
				patient: payload.patient,
				lat: payload.patient.addressLatitude,
				lng: payload.patient.addressLongitude
			};
		case CONSTS.SET_PATIENT_NULL_SUCCESS:
			return {
				...state,
				loading: false,
				patient: {},
				lat: CONSTS.DEFAULT_LAT,
				lng: CONSTS.DEFAULT_LNG
			};
		case CONSTS.SET_CRP_SUCCESS:
			return {
				...state,
				loading: false,
				referrerCrp: payload.crp
			};
		case CONSTS.SET_CRP_NULL_SUCCESS:
			return {
				...state,
				loading: false,
				referrerCrp: {}
			};
		case CONSTS.SET_CLINICIAN_SUCCESS:
			return {
				...state,
				loading: false,
				targetCrp: payload.crp
			};
		case CONSTS.SET_CLINICIAN_NULL_SUCCESS:
			return {
				...state,
				loading: false,
				targetCrp: {}
			};
		case CONSTS.SET_POSTCODE_SUCCESS:
			return {
				...state,
				loading: false,
				errorMessage: '',
				postCode: payload.postcode,
				lat: parseFloat(payload.lat, 10),
				lng: parseFloat(payload.lng, 10)
			};
		case CONSTS.SET_JOB_ROLE_FAIL:
		case CONSTS.SET_JOB_ROLE_NULL_FAIL:
		case CONSTS.SET_PATIENT_FAIL:
		case CONSTS.SET_PATIENT_NULL_FAIL:
		case CONSTS.SET_CRP_FAIL:
		case CONSTS.SET_CRP_NULL_FAIL:
			return {
				...state,
				loading: false
			};
		case CONSTS.SET_POSTCODE_FAIL:
			return {
				...state,
				loading: false,
				errorMessage: payload.error
			};
		default:
			return state;
	}
};

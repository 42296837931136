import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import UserProfileCard from '../../components/user/UserProfileCard';
import { clinicianProfilesPropType } from '../../constants/propTypes';
import { getClinicianByUserId } from '../../actions/clinicianActions';
import { getClinicCenterStr } from '../../actions/general';

function UserCrpsScreen({ crps }) {

	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		const ID = parseInt(id, 10);
		dispatch(getClinicianByUserId(ID));
	}, []);

	
	const [currentCrp, setCurrentCrp] = useState(null);
	const [crpButtons, setCrpButtons] = useState(null);

	useEffect(() => {
		if (!crps|| crps===undefined|| crps.length === 0) {
			setCrpButtons(<div>Loading...</div>);
			setCurrentCrp(null);
		} else {
			setCrpButtons(crps.map((clinician) => (
				<Row className="text-center py-1" key={`btn${uuidv4()}`}>
					<Col>
						<Button className="text-center py-1 m-1 w80" onClick={() => setCurrentCrp(clinician)}>
							{getClinicCenterStr(clinician.clinic)}
						</Button>
					</Col>
				</Row>
			)));
			setCurrentCrp(crps[0]);
		}
	}, [crps]);
	
	return (
		<div>
			<Row className="text-center py-1">
				<Col>
					<h1>Clinician Information</h1>
				</Col>
			</Row>
			
			{crpButtons}

			{currentCrp === null ? null :
				<Row className="text-center py-1" key={currentCrp.id}>
					<Col>
						<UserProfileCard clinicianProfile={currentCrp}/>
					</Col>
				</Row>
			}
		</div>
	);
}

UserCrpsScreen.propTypes = {
	crps: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	crps: state.clinicians.clinicianCrps,
});

export default connect(mapStateToProps, {})(UserCrpsScreen);

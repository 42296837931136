import * as CONSTS from '../constants/clinicianConstants';
import * as MSG from '../constants/messageConstants';
import * as URL from '../constants/urls';
import { 
	anyPostRequest,
	anyGetRequest,
	authGetRequest,
	authPostRequest,
	authPutRequest, 
	authDeleteRequest	 
} from './axiosActions';

export const getCliniciansList = (roleId, insuranceProviderId, lat, lng, jobRoleSelected='', jobSpecialtySelected='') => async (dispatch, getState) => {
    // Access the isLoggedIn value from the Redux state
    const { isAuthenticated } = getState().auth;

    const inputData = {
        URL: `${URL.CLINICIANS}`,
        GET_REQUEST: CONSTS.CLINICIAN_LIST_REQUEST,
        GET_SUCCESS: CONSTS.CLINICIAN_LIST_SUCCESS,
        GET_FAIL: CONSTS.CLINICIAN_LIST_FAIL,
        payload: { roleId, insuranceProviderId, lat, lng, jobRoleSelected, jobSpecialtySelected }
    };
    
	if (!isAuthenticated) {
		dispatch(anyPostRequest(inputData));
    } else {
		dispatch(authPostRequest(inputData));
	}
};


export const getClinicsList =
	() => async (dispatch) => {

		const inputData = {
			URL: `${URL.CLINICS}`,
			GET_REQUEST: CONSTS.CLINICS_LIST_REQUEST,
			GET_SUCCESS: CONSTS.CLINICS_LIST_SUCCESS,
			GET_FAIL: CONSTS.CLINICS_LIST_FAIL,
		};
		
		dispatch(anyGetRequest(inputData));
	};



export const getClinicianById = (id) => async (dispatch, getState) => {

	const { isAuthenticated } = getState().auth;

	const inputData = {
		URL: `${URL.CRP}${id}/`,
		GET_REQUEST: CONSTS.CLINICIAN_BY_ID_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_BY_ID_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_BY_ID_FAIL
	};

	if (!isAuthenticated) {
		dispatch(anyGetRequest(inputData));
    } else {
		dispatch(authGetRequest(inputData));
	}
};

export const getClinicianByUserId = (id) => async (dispatch, getState) => {

	const { isAuthenticated } = getState().auth;

	const inputData = {
		URL: `${URL.USER_CRPS}${id}/`,
		GET_REQUEST: CONSTS.USER_CRPS_GET_REQUEST,
		GET_SUCCESS: CONSTS.USER_CRPS_GET_SUCCESS,
		GET_FAIL: CONSTS.USER_CRPS_GET_FAIL
	};

	if (!isAuthenticated) {
		dispatch(anyGetRequest(inputData));
    } else {
		dispatch(authGetRequest(inputData));
	}
};

export const getJobRolesList = (filtered=false) => async (dispatch) => {
	const filt = filtered ? 'filtered/' : '';
	const inputData = {
		URL: `${URL.JOB_ROLES}${filt}`,
		GET_REQUEST: CONSTS.JOB_ROLE_LIST_REQUEST,
		GET_SUCCESS: CONSTS.JOB_ROLE_LIST_SUCCESS,
		GET_FAIL: CONSTS.JOB_ROLE_LIST_FAIL
	};
	
	const authStr = localStorage.getItem('auth');
	if (!authStr) {
		dispatch(anyGetRequest(inputData));
	} else {
		dispatch(authGetRequest(inputData));
	}
};

export const setJobRoleID = (id)  => async (dispatch) => {
	dispatch({type: CONSTS.JOB_ROLE_SET_ID_REQUEST});
	try {
		dispatch({
			type: CONSTS.JOB_ROLE_SET_ID_SUCCESS,
			payload: {id},
		});
	} catch (error) {
		dispatch({
			type: CONSTS.JOB_ROLE_SET_ID_FAIL,
		});
		
		const data = {variant:'danger', message:"Unable to set job role"}
		dispatch({
			type: MSG.MESSAGE_RECEIVED,
			payload: data
		});
	}
};

export const getInsuranceAgreementsList = () => async (dispatch) => {
	const inputData = {
		URL: `${URL.INSURANCE}`,
		GET_REQUEST: CONSTS.INSURANCE_AGREEMENT_LIST_REQUEST,
		GET_SUCCESS: CONSTS.INSURANCE_AGREEMENT_LIST_SUCCESS,
		GET_FAIL: CONSTS.INSURANCE_AGREEMENT_LIST_FAIL
	};
	
	dispatch(anyGetRequest(inputData));
	};

export const postCreateCrp = (formData, isSuccessfull) => async (dispatch) => {
	const payload = formData;

	const requestIsSuccessfull = (value) => {
		if (value) {
			dispatch(getCliniciansList());
		}
		isSuccessfull(value);
	}

	const inputData = {
		URL: `${URL.CRP_USER_CREATE}`,
		GET_REQUEST: CONSTS.CLINICIAN_CREATE_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_CREATE_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_CREATE_FAIL,
		payload
	};

	dispatch(authPostRequest(inputData, requestIsSuccessfull));
};

export const postRequestRemoveCrp = (payload, isSuccessfull) => async (dispatch) => {

	const inputData = {
		URL: URL.CRP_USER_REMOVE_CLINIC,
		GET_REQUEST: CONSTS.CLINICIAN_ROLE_REMOVE_REQ_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_ROLE_REMOVE_REQ_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_ROLE_REMOVE_REQ_FAIL,
		payload
	};

	function requestIsSuccessfull(value) {
		isSuccessfull(value);
	}

	dispatch(authPostRequest(inputData, requestIsSuccessfull));
};

export const postDeactivateCrp = (id, isSuccessfull) => async (dispatch) => {

	const inputData = {
		URL: `${URL.CRP_USER_DEACTIVATE}${id}/`,
		GET_REQUEST: CONSTS.CLINICIAN_ROLE_DEACTIVATE_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_ROLE_DEACTIVATE_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_ROLE_DEACTIVATE_FAIL,
		payload:{}
	};

	function requestIsSuccessfull(value) {
		isSuccessfull(value);
	}

	dispatch(authPutRequest(inputData, requestIsSuccessfull));
};

export const updateClinicianInsuranceAgreements = (formData, isSuccessfull) => async (dispatch) => {
	const payload = formData;

	const inputData = {
		URL: `${URL.INSURANCE_UPDATE}`,
		GET_REQUEST: CONSTS.CLINICIAN_INSURANCE_UPDATE_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_INSURANCE_UPDATE_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_INSURANCE_UPDATE_FAIL,
		payload
	};

	dispatch(authPutRequest(inputData, isSuccessfull));
};

export const addClinicianToFavourites = (clinicianId) => async (dispatch) => {
	const inputData = {
		URL: `${URL.CLINICIAN_ADD_FAVOURITE}${clinicianId}/`,
		GET_REQUEST: CONSTS.CLINICIAN_ADD_FAVOURITE_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_ADD_FAVOURITE_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_ADD_FAVOURITE_FAIL,
		payload: {}
	};

	const isSuccessfull = (value) => {
		if (value) {
			dispatch(getClinicianById(clinicianId));
		}
	}

	dispatch(authPostRequest(inputData, isSuccessfull));
}

export const removeClinicianFromFavourites = (clinicianId) => async (dispatch) => {
	const inputData = {
		URL: `${URL.CLINICIAN_RM_FAVOURITE}${clinicianId}/`,
		GET_REQUEST: CONSTS.CLINICIAN_RM_FAVOURITE_REQUEST,
		GET_SUCCESS: CONSTS.CLINICIAN_RM_FAVOURITE_SUCCESS,
		GET_FAIL: CONSTS.CLINICIAN_RM_FAVOURITE_FAIL,
		payload: {}
	};
	
	const isSuccessfull = (value) => {
		if (value) {
			dispatch(getClinicianById(clinicianId));
		}
	}
	dispatch(authDeleteRequest(inputData, isSuccessfull));
}

export const inviteClinicianToMedr = (payload, isSuccessfull) => async (dispatch) => {
	const inputData = {
		URL: `${URL.INVITE_CLINICIAN}`,
		GET_REQUEST: CONSTS.INVITE_CLINICIAN_REQUEST,
		GET_SUCCESS: CONSTS.INVITE_CLINICIAN_SUCCESS,
		GET_FAIL: CONSTS.INVITE_CLINICIAN_FAIL,
		payload
	};

	dispatch(authPostRequest(inputData, isSuccessfull));
}
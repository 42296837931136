import React, { useState } from 'react';
import  { Card, Row, Button } from 'react-bootstrap';
// import InviteUserForm from '../user/InviteUserForm';
import FeatureSuggestionPopup from './FeatureSuggestionPoppup';

function AppUpdatesPanel() {

    // const [displayInvitUserForm, setDisplayInvitUserForm] = useState(false);
    const [displaySuggestionsForm, setDisplaySuggestionsForm] = useState(false);

	return (
        <>
            {/* {displayInvitUserForm ? <InviteUserForm showModal={displayInvitUserForm} handleClose={() => setDisplayInvitUserForm(false)}/> : null} */}
            {displaySuggestionsForm ? <FeatureSuggestionPopup showModal={displaySuggestionsForm} handleClose={() => setDisplaySuggestionsForm(false)}/> : null}
            <div className='medr-layout text-center'style={{marginLeft: 'auto', marginRight: 'auto', width: '100%', height: '100%'}}>
                <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id="analyticsStatsCard" style={{height: 'calc(100% - 46px)'}}> {/* 46px is the height of the card title */}
                    <Card.Title>Recent Updates</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        {/* <Row>
                            <p className='updates-tab-title'>Invite a clinician</p>
                            <p className='updates-tab-content'>Invite a clinician you work closely with to join the medr platform using the button below.</p>
                        </Row>
                        <Row className='mb-2'>
                            <Button className='insurance-check-btn' variant='primary' onClick={() => setDisplayInvitUserForm(true)}>Invite a clinician</Button>          
                        </Row> */}
                        <Row>
                            <p className='updates-tab-title'>Request a feature</p>
                            <p className='updates-tab-content'>If you would like a new feature or specific metrics submit a request using the button below.</p>
                        </Row>
                        <Row className='mb-2'>
                            <Button className='insurance-check-btn' variant='info' onClick={() => setDisplaySuggestionsForm(true)}>Request a feature</Button>          
                        </Row>
                        <div className="mt-auto"> </div>
                    </Card.Body>
                </Card>
            </div>
        </>
	);
}


export default AppUpdatesPanel;

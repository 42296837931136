import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<footer id='footer' className='medr-light' >
			<Container>
				<Row>
					<Col className="text-center">Copyright &copy; 2024 MedR</Col>
				</Row>
				<Row>
					<Col className="text-center">
						<Link to="/policy">Terms and conditions</Link>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default Footer;

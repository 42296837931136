import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import ClinicianMarker from './ClinicianMarker';
import TargetMarker from './TargetMarker';
import { clinicianProfilesPropType, numberPropType, funcPropType, boolPropType } from '../../constants/propTypes';
import { initPostcode } from '../../constants/initDataConstants';

function MapView({ lat, lng, zoom, clinicians, hoveredClinician, hoveredClinicianId, disablePinNavigate} ) {
    const defaultTargetLat = 51.501162;
    const defaultTargetLng = -0.141745;

    const apiKey = process.env.REACT_APP_GMAPS_API_KEY!==null && process.env.REACT_APP_GMAPS_API_KEY!==undefined ? process.env.REACT_APP_GMAPS_API_KEY : 'AIzaSyAv5oKWq0-inoT-sPn1pw052KEA2PYh7aI';

    const [targetLat, setTargetLat] = useState(lat);
    const [targetLng, setTargetLng] = useState(lng);
    const [mapsZoom, setMapsZoom] = useState(zoom);
    const [clinicianList, setClinicianList] = useState(clinicians);

    useEffect(() => {
        setTargetLat(lat);
        setTargetLng(lng);
        setMapsZoom(zoom);
        setClinicianList(clinicians);
    }, [lat, lng, zoom, clinicians]);

    const [markers, setMarkers] = useState([]);
    const [targetMarker, setTargetMarker] = useState(null);

    useEffect(() => {
        if (targetLat!==defaultTargetLat || targetLng!==defaultTargetLng){
            setTargetMarker(
                <TargetMarker key="target_1" lat={targetLat} lng={targetLng}/>
            );
        } else {
            setTargetMarker(null);
        }
    }, [targetLat, targetLng]);

    useEffect(() => {
        const clinicianListRev = clinicianList; // clinicianList.reverse();
        const newMarkers = clinicianListRev.map((clinician) => {
            const { clinic, distanceRank} = clinician;
            const { latitude, longitude } = clinic.address;
            return (
                <ClinicianMarker
                    key={distanceRank} 
                    lat={latitude} 
                    lng={longitude} 
                    clinician={clinician} 
                    sendHoveredClinician={hoveredClinician} 
                    hoveredClinicianId={hoveredClinicianId}
                    disableClinicianLink={disablePinNavigate}/>
                )
            });
        
            setMarkers(newMarkers);
    }, [clinicianList, hoveredClinicianId]);

    return (
        <div>
            <div className='map-view'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: apiKey }}
                    // defaultCzenter={[targetLat, targetLng]}
                    center={[targetLat, targetLng]}
                    defaultZoom={mapsZoom}>
                    {targetMarker}
                    {markers}
                </GoogleMapReact>
            </div>
        </div>
    )
}

MapView.propTypes = {
    lat: numberPropType,
    lng: numberPropType,
    zoom: numberPropType,
    clinicians: clinicianProfilesPropType,
    hoveredClinician: funcPropType,
    hoveredClinicianId: numberPropType,
    disablePinNavigate: boolPropType
}


MapView.defaultProps = {
    lat: initPostcode.lat,
    lng: initPostcode.lng,
    zoom: initPostcode.zoom,
    clinicians: [],
    hoveredClinician: null,
    hoveredClinicianId: null,
    disablePinNavigate: false
}

export default MapView;
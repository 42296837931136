import axios from 'axios';
import Cookies from 'js-cookie';
import * as MSG from '../constants/messageConstants';

export function getMessageUnknownError(error){

	if (!error.response || error.response===undefined || !error.response.data || error.response.data===undefined){
		return {};
	}

	let message;

	try {
		// const status = error.response.statusText;
		const status = error.response.data.errorTitle ? error.response.data.errorTitle : "";
		const description = error.response.data.detail ? error.response.data.detail : "";
		if (status===''){
			return {};
		}
		if (description===''){
			message = `${status}`;
		} else {
			message = `${status}: ${description}`;
		}
	} catch (err2) {
		message = "Error";
	}
	
	return {variant:'danger', message};
};

export const anyNullRunThrough = (inputData) => async (dispatch) => {
	const {REQUEST, SUCCESS, FAIL} = inputData;
    dispatch({type: REQUEST});
    try {
        dispatch({type: SUCCESS});
	} catch (error) {
        dispatch({type: FAIL});
	}
};


export const authGetRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {
		const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL } = inputData; 
        
		dispatch({type: GET_REQUEST});
		
		try {
			const token = Cookies.get('authToken');
			const headers = {headers: {Authorization: `Token ${token}`}};
			
			const response = await axios.get(URL, headers);

			const { data } = response;

			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }

			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}


		} catch (error) {

			dispatch({type: GET_FAIL});

			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};

export const anyGetRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {
        
        const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL } = inputData; 
        
		dispatch({type: GET_REQUEST});

		try {
			const { data } = await axios.get(URL);

			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }

			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}

		} catch (error) {
			dispatch({type: GET_FAIL});
			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};

export const authPostRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {

        const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL, payload } = inputData; 

		dispatch({type: GET_REQUEST});

		try {
			const token = Cookies.get('authToken');
			const headers = {headers: {Authorization: `Token ${token}`}};

			const response = await axios.post(URL, payload, headers);
			const { data } = response;
			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }

			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}

		} catch (error) {


			dispatch({type: GET_FAIL});
			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};

export const anyPostRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {
        
        const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL, payload } = inputData; 
        
		dispatch({type: GET_REQUEST});

		try {
			const { data } = await axios.post(URL, payload);

			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }
			
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}
			
		} catch (error) {
			dispatch({type: GET_FAIL});
			const msg = getMessageUnknownError(error);
			
			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};


export const authPutRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {
        
        const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL, payload } = inputData; 
        
		dispatch({type: GET_REQUEST});

		try {
			const token = Cookies.get('authToken');
			const headers = {headers: {Authorization: `Token ${token}`}};

			const response = await axios.put(URL, payload, headers);
			const { data } = response;

			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }
			
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}

		} catch (error) {

			dispatch({type: GET_FAIL});
			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};

export const anyPutRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {
        
        const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL, payload } = inputData; 
        
		dispatch({type: GET_REQUEST});

		try {
			const { data } = await axios.put(URL, payload);

			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }
			
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}

		} catch (error) {
			dispatch({type: GET_FAIL});
			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};


export const authDeleteRequest =
	(inputData, sendIsSuccessful) => async (dispatch) => {

        const { GET_REQUEST, GET_SUCCESS, GET_FAIL, URL } = inputData; 

		dispatch({type: GET_REQUEST});

		try {
			const token = Cookies.get('authToken');
			const headers = {headers: {Authorization: `Token ${token}`}};

			const response = await axios.delete(URL, headers);
			const { data } = response;
			dispatch({
				type: GET_SUCCESS,
				payload: data,
			});

            if ("message" in data){
                dispatch({
                    type: MSG.MESSAGE_RECEIVED,
                    payload: data.message
                });
            }

			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(true);
			}

		} catch (error) {

			dispatch({type: GET_FAIL});
			const msg = getMessageUnknownError(error);

			dispatch({
				type: MSG.MESSAGE_RECEIVED,
				payload: msg
			});
			if (sendIsSuccessful!==undefined){
				sendIsSuccessful(false);
			}
		}
	};
import React, { useEffect, useState } from 'react';
import  { Card, Row, Col } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSummaryAnalytics } from "../../actions/profileActions";
import { getNameString } from '../../actions/general';
import FavClinicianStar from '../app/parts/FavClinicianStar';
import FavClinicianStarPopup from '../app/parts/FavClinicianStarPopup';


function UserAnalyticsSummary() {
    const lg = 6;
    const md  = 6;
    const sm  = 12;

    const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUserSummaryAnalytics(5))
	}, [dispatch]);

    const userProfile = useSelector((state) => state.profile.userProfile);
    const analytics = useSelector((state) => state.profile.analytics);
    
    if (!userProfile) {
        return <div id='user_analytics_missing_up'>Loading...</div>;
      }
      
    if (!analytics) {
        return <div id='user_analytics_missing_up'>Loading...</div>;
      }

    const { 
        number, noPatientsMade,
        noReferralsMade, noReferralsReceived, topReferredTo, 
        topReferralsFrom
    } = analytics;

    const { favouriteClinicians } = analytics;


    function handleValue(value){
        if (value===undefined || value===null){
            return "NaN"
        }
        return value
    }

    const statsCard = (
        <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id="analyticsStatsCard">
            <Card.Title>Platform Statistics</Card.Title>
            <Card.Body className="d-flex flex-column text-left">
                <ul className='dashboard-analytics-list-p'>
                    <li>
                        <span className='dashboard-analytics-sp'>Patients Added:</span>
                        <span>{handleValue(noPatientsMade)}</span>
                    </li>
                    <li>
                        <span className='dashboard-analytics-sp'>Referrals Sent:</span>
                        <span>{handleValue(noReferralsMade)}</span>
                    </li>
                    <li>
                        <span className='dashboard-analytics-sp'>Referrals Received:</span>
                        <span>{handleValue(noReferralsReceived)}</span>
                    </li>
                </ul>
                <div className="mt-auto"> </div>
            </Card.Body>
        </Card>
    )

    function topReferrersCard(referrers, title, keyId){
        if (referrers===undefined || referrers===null || Object.keys(referrers).length===0){
            return (
                <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id={keyId}>
                    <Card.Title>{title}</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        <p className='dashoard-ana-text text-center'>Top referrers data not available</p>
                    </Card.Body>
                </Card>
            )
        }

        return (
                <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id={keyId}>
                    <Card.Title>{title}</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        <ol className='dashboard-analytics-list-p'>
                            {referrers.map((clinicianInfo) => (
                                <li key={`tc${uuidv4()}`}><a className='medr-text-inv' href={`/clinicians/user/${clinicianInfo.clinician.id}`}>{getNameString(clinicianInfo.clinician)} ({clinicianInfo.count})</a></li>
                            ))}
                        </ol>
                        <div className="mt-auto" />
                    </Card.Body>
                </Card>
        )
    };

    const [displayPopup, setDisplayPopup] = useState(false);
    const [popupClinician, updateFavPopup] = useState({
        isFavourite: true, 
        userProfile: {}
    });

    function togglePopup(clinician) {
        updateFavPopup({
            isFavourite: true, 
            userProfile: clinician
        })
        setDisplayPopup(true);
    }

    const refreshFavClinicians = () =>{
        dispatch(getUserSummaryAnalytics(5));
    } 
    
    function getFavouriteClinicinasCard() {
        if (favouriteClinicians===undefined || favouriteClinicians===null || favouriteClinicians.length===0 || Object.keys(favouriteClinicians).length===0){
            return (
                <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id="favouriteCliniciansCard">
                    <Card.Title>Favourite Clinicians</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        <p className='text-center'>Favourite clinicians have not been selected</p>
                        <div className="mt-auto" />
                    </Card.Body>
                </Card>
            )
        } 

        return (
            <>
                <FavClinicianStarPopup clinician={popupClinician} showPopup={displayPopup} handleClose={() => setDisplayPopup(false)} requestMade={() => {refreshFavClinicians()}} />
                <Card className="my-3 p-3 rounded d-flex flex-fill medr-card" id="favouriteCliniciansCard">
                    {/* {displayPopup && <FavClinicianStarPopup clinician={clinicianProfile} showPopup={displayPopup} handleClose={() => setDisplayPopup(false)} requestMade={() => {}} />}  */}
                    {/* {displayPopup ? <p>ascda</p>: null}  */}
                    <Card.Title>Favourite Clinicians</Card.Title>
                    <Card.Body className="d-flex flex-column text-left">
                        <ul style={{listStyleType: 'none', padding: '0px'}}>
                            {favouriteClinicians.map((favClin) => (
                                <li key={`fc${uuidv4()}`}><FavClinicianStar 
                                    clinician={{isFavourite: true, userProfile: favClin.favouriteUserProfile}} 
                                    togglePopup={() => togglePopup(favClin.favouriteUserProfile)}/> <a className='medr-text-inv' href={`/clinicians/user/${favClin.favouriteUserProfile.id}`}>{getNameString(favClin.favouriteUserProfile)}</a></li>
                            ))}
                        </ul>
                        <div className="mt-auto" />
                    </Card.Body>
                </Card>
            </>
        )
    } 
    
	return (
		<div className='medr-layout text-center' style={{marginLeft: 'auto', marginRight: 'auto'}}>
            <Row>
                <Col className='medr-card-col'  lg={lg} md={md} sm={sm}>
                    {statsCard}
                </Col>
                <Col className='medr-card-col' lg={lg} md={md} sm={sm}>
                    {getFavouriteClinicinasCard()}
                </Col>
            </Row>
            <Row>
                <Col className='medr-card-col' lg={lg} md={md} sm={sm}>
                    {topReferrersCard(topReferralsFrom, `Top ${number} Clinicians Referrals Received`, "topReferrersFromCard")}
                </Col>
                <Col className='medr-card-col' lg={lg} md={md} sm={sm}>
                    {topReferrersCard(topReferredTo, `Top ${number} Clinicians Referrals Sent`, "topReferrersToCard")}
                </Col>
            </Row>
        </div>
	);
}


export default UserAnalyticsSummary;

import React from 'react';
import AdminReferralsTable from '../../components/admin/AdminReferralsTable';

function AdminReferralsListScreen() {
	return (
		<div>
			<h1>Referrals</h1>
			<AdminReferralsTable  />
		</div>
	);
}

export default AdminReferralsListScreen;

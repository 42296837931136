import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { clinicianProfilesPropType } from '../constants/propTypes';
import UserProfileCard from '../components/user/UserProfileCard';
import ProfileInfoUpdate from '../components/user/ProfileInfoUpdate';
import ProfileChangePassword from '../components/user/ProfileChangePassword';
import ProfileAddClinic from '../components/user/ProfileAddClinic';
import ProfileChangeInsurance from '../components/user/ProfileChangeInsurance';
import ProfileChangeNotifications from '../components/user/ProfileChangeNotifications';


function ProfileScreen({ clinicalProfiles }) {

	const [view, setView] = useState('profile');
	const [title, setTitle] = useState('Profile');
    const colSizes = { sm: 12, md: 3 };

	useEffect(() => {
		const titles = {
			profile: 'Profile',
			info: 'Update Information',
			password: 'Change password',
			clinic: 'Add new clinic',
			insurance: 'Update insurance agreements',
			notifications: 'Update notification settings'
		}
		setTitle(titles[view]);
	}, [view]);

	return (
		<div className='medr-layout text-center profile-page' style={{marginLeft: 'auto', marginRight: 'auto'}}>
			<h1>{title}</h1>

			<Row>
				<Col sm={colSizes.sm} md={colSizes.md}>
					<Row>
						<Col>
							<Button className={`sel-button w100 mb-4 ${(view==='profile' ? 'active' : '')}`} onClick={() => setView('profile')}>Profile</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button className={`sel-button w100 mb-4 ${(view==='info' ? 'active' : '')}`} onClick={() => setView('info')}>Update Information</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button className={`sel-button w100 mb-4 ${(view==='password' ? 'active' : '')}`} onClick={() => setView('password')}>Change password</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button className={`sel-button w100 mb-4 ${(view==='clinic' ? 'active' : '')}`} onClick={() => setView('clinic')}>Add new clinic</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button className={`sel-button w100 mb-4 ${(view==='insurance' ? 'active' : '')}`} onClick={() => setView('insurance')}>Update insurance agreements</Button>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button className={`sel-button w100 mb-4 ${(view==='notifications' ? 'active' : '')}`} onClick={() => setView('notifications')}>Notification Settings</Button>
						</Col>
					</Row>
				</Col>


				<Col>
					{view==='profile' ? <UserProfileCard clinicianProfile={clinicalProfiles[0]} isUser/>: null}
					{view==='info' ? <ProfileInfoUpdate /> : null}
					{view==='password' ? <ProfileChangePassword /> : null}
					{view==='clinic' ? <ProfileAddClinic /> : null}
					{view==='insurance' ? <ProfileChangeInsurance /> : null}
					{view==='notifications' ? <ProfileChangeNotifications /> : null}
				</Col>
			</Row>
		</div>
	);
}

ProfileScreen.propTypes = {
	clinicalProfiles: clinicianProfilesPropType.isRequired
}

const mapStateToProps = (state) => ({
	clinicalProfiles: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ProfileScreen);
import React, { useEffect, useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import RegisterPersonalDetailsForm from "../../components/user/RegisterUserDetailsForm";
import RegisterClinicianDetialsForm from "../../components/user/RegisterClinicianDetailsForm";
import RegisterInsuranceDetails from "../../components/user/RegisterInsuranceDetails";
import RegisterReview from "../../components/user/RegisterReview";
import { RedirectIfAuthDetailsAvailable } from "../../actions/navigations";

function SignupScreen(){
	/**
	 * 1. Check for auth token in cookies
	 * 2. If auth token exists, set is authenticated to true and redirect to dashboard
	 * 3  If auth token doesn't exist, set is authenticated to false and render register screen
	 */

    RedirectIfAuthDetailsAvailable();

    const initData = {
        isReview: false,

        // Step 1
        email:"",
        title:'',
        firstName:"",
        lastName:"",
        password:'',
        rePassword:'',
    
        // Step 2
        jobRoleId:0,
        jobRoles: [],
        registrationNumber:'',
        dob: '',
        clinicianDescription: '',
        clinics: [],
        appointmentsContacts: [],

        //  Step 3
        existingProviders: [],
        newProviders: [],

    }

	const [requestData, setRequestData] = useState(initData);

	const minStep = 0;
    const maxStep = 3;
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => activeStep < maxStep ? setActiveStep((previousStep) => previousStep + 1) : null;
    
    const previousButtonClick = () => {
        if (activeStep > minStep){
            if (activeStep===maxStep){
                setRequestData({...requestData, isReview: false});
            }
            setActiveStep((previousStep) => previousStep - 1);   
        }
    };

    useEffect(() => {
        if (activeStep===maxStep){
            setRequestData({...requestData, isReview: activeStep===maxStep})
        }}, [activeStep])

    const handleChanges = (e) => setRequestData({...requestData, [e.key]: e.value});

    // useEffect(() => {
    //     console.log(requestData);
    // }   , [requestData])

    return (
        <div className='medr-layout text-center register-page' style={{maxWidth:'90%', marginLeft: 'auto', marginRight: 'auto'}}>
            <h1>Registration</h1>
            <Row>
                <Col className='py-0'>
                    {activeStep===0 ? <RegisterPersonalDetailsForm values={requestData} changeValues={handleChanges} handleNext={handleNext} /> : null}
                    {activeStep===1 ? <RegisterClinicianDetialsForm values={requestData} changeValues={handleChanges}  handleNext={handleNext}/> : null}
                    {activeStep===2 ? <RegisterInsuranceDetails values={requestData} changeValues={handleChanges} handleNext={handleNext}/> : null}
                    {activeStep===3 ? <RegisterReview values={requestData} /> : null}
                </Col>
            </Row>
            {activeStep!==0 ? 
            <Row>
                <Col className='text-center'>
                    <Button className='sel-button w80' variant='dark' onClick={previousButtonClick}>Previous</Button>
                </Col>
            </Row> : null}
        </div>
    )
}

export default SignupScreen;
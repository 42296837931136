import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import PropTypes from 'prop-types';
import JobRoleSearchBar from "../../clinicians/JobRoleSearchBar";
import ClinicianMapBox from "../../map/ClinicianMapBox";
import PostcodeSearch from "../../clinicians/PostcodeSearch";

function ReferralStepSelectClinician({ currentJrId, currentPostcode, changeValue, changeGPS, refreshClinicians }) {
    useEffect(() => {window.scroll(0,0)}, []);

    const [enableClinicianSelect, setEnableClinicianSelect] = React.useState(currentJrId !== null && currentJrId !== 0);
    // const [readyNext, setReadyNext] = React.useState(false);

    const handleJobRoleSelect = (e) => {
        changeValue(e)
        setEnableClinicianSelect(true);
    }

    const setReferralOpenToAll = () => {
        changeValue({ key: "targetClinicianId", value: 0});
    }
    
    const handleClinicianSelect = (id) => {
        changeValue({ key: "targetClinicianId", value: id});
    }

    // const checkNext = () => {
    //     sendNext();
    //     changeValue({ key: "isReview", value: true });
    // }

    const changePostcodeData = (e) => {
        if (e.key){
            changeValue({ key: "currentPostcode", value: e.value });
        } else if (e.searchLatitude){
            changeGPS(e);
        }
    }

    // useEffect(() => {
    //     const ready = currentJrId > 0 ;
    //     setReadyNext(ready);
    // }, [currentJrId]);

    return (
        <>
            <div className='medr-layout text-center select-clinician-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Row>
                    <Col>
                        <h4>Select clinician</h4>
                    </Col>
                </Row>

                <JobRoleSearchBar currentJobId={currentJrId} changeValues={handleJobRoleSelect} filtered/>

                <Row>
                    <Col>
                        <Button onClick={setReferralOpenToAll} disabled={!enableClinicianSelect}>Set open to all suitable clinicians</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='clinicians-search-postcode'>
                            <PostcodeSearch currentPostcode={currentPostcode} changeValues={changePostcodeData} />
                        </div>
                    </Col>
                </Row>
            </div>
            
            <div className='medr-layout text-center select-clinician-page' style={{maxWidth:'1020px', marginLeft: 'auto', marginRight: 'auto'}}>
                <ClinicianMapBox onClinicianClick={handleClinicianSelect} enableClinicianSelect={enableClinicianSelect} refreshClinicians={refreshClinicians} disablePinNavigate/>
            </div>
{/* 
            <div className='medr-layout text-center select-clinician-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Row className='mt-4'>
                    <Col>
                        <Button className='sel-button w80' onClick={checkNext} disabled={!readyNext}>Next</Button>
                    </Col>
                </Row>
            </div> */}
        </>
    );
};

ReferralStepSelectClinician.propTypes = {
    currentJrId: PropTypes.number,
    currentPostcode: PropTypes.string.isRequired,
    changeValue: PropTypes.func.isRequired,
    changeGPS: PropTypes.func.isRequired,
    // sendNext: PropTypes.func.isRequired,
    refreshClinicians: PropTypes.func.isRequired,
}

ReferralStepSelectClinician.defaultProps = {
    currentJrId: null,
}

export default ReferralStepSelectClinician;
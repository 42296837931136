import React from 'react';
import UserUpdateRequest from './UserUpdateRequest';
import UserUpdateDescription from './UserUpdateDescription';
import ProfilePictureUpload from './UserProfilePicUpload';
import ProfileReferralContactsUpdate from './ProfileReferralContactsUpdate';

function ProfileInfoUpdate() {
	return (
		<>
            <UserUpdateRequest />
            <UserUpdateDescription />
            <ProfilePictureUpload />
			<ProfileReferralContactsUpdate />
		</>
	);
}

export default ProfileInfoUpdate;
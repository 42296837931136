import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { getNameString } from '../actions/general';
import UserAnalyticsSummary from '../components/user/UserAnalyticsSummary';
import UpdateProfilePopup from '../components/user/UpdateProfilePopup';
import AppUpdatesPanel from '../components/app/AppUpdatesPanel';
import { patientPropType } from '../constants/propTypes';


function Dashboard({ userProfile }) {
    /**
     * 1. load user profile from storage
     * 2. check profile pic and description
     * 3. if either is default, display reminder to update
     */

    const navigate = useNavigate();
    const [displayReminder, setDisplayReminder] = useState(false);

    useEffect(() => {
        if (!userProfile && userProfile!==undefined) {
            const { clinicianDescription, profilePicture } = userProfile;
            setDisplayReminder(clinicianDescription==='' || profilePicture.includes('profile-ppr-default'));
        }
	}, [userProfile]);
    
	const handleNavigationNewReferral = () => navigate('/referral/add')
	const handleNavigationViewReferrals = () => navigate('/referrals')

	
	return (
		<div className='medr-layout text-center dashboard-page' style={{maxWidth:'900px', marginLeft: 'auto', marginRight: 'auto'}}>
            <UpdateProfilePopup userProfile={userProfile} showModal={displayReminder} handleClose={() => setDisplayReminder(false)}/>
			<Row className='medr-layout text-center'>
				<Col>
					<h4>Welcome {userProfile ? getNameString(userProfile) : null}</h4>
				</Col>
			</Row>
            <Row className='medr-layout text-center'>
                <Col>
                    <Button type="button" onClick={handleNavigationNewReferral} className="sel-button w100">
                        Make referral
                    </Button>
                </Col>
                <Col>
                    <Button type="button" onClick={handleNavigationViewReferrals} className="sel-button w100">
                        View referrals
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs={12} md={9}>
                    <UserAnalyticsSummary />
                </Col>
                <Col xs={12} md={3}>
                    <AppUpdatesPanel />
                </Col>
            </Row>
		</div>
	);
}


Dashboard.propTypes = {
	userProfile: patientPropType.isRequired
};

const mapStateToProps = (state) => ({
    userProfile: state.profile.userProfile,
});

export default connect(mapStateToProps, {})(Dashboard);

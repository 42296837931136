import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { patientsPropType } from '../../constants/propTypes';
import { getAdminPatientsList } from '../../actions/adminActions';
import { getNameString, reformatDateTime } from '../../actions/general';
import PaginationBar from '../app/PaginationBar';
import useAdminPatientsList from '../../hooks/admin/useAdminPatientsList';

function AdminPatientTable({ patientsList }) {
    const nPatientsWindow = 18
    const start = 0;
    const end = nPatientsWindow;
    const [pxList, setPxList] = useState([])

    const dispatch = useDispatch();
	const refreshPatients = () => dispatch(getAdminPatientsList());
	useEffect(() => {refreshPatients();}, [dispatch]);

    useEffect(() => {
        if (patientsList===null || patientsList===undefined || patientsList.length===0){
            setPxList([]);
            return;
        }
        setPxList(patientsList);
    }, [patientsList])

    const [
        patients,
        updatePatientsSlice,
        patientsLength
    ] = useAdminPatientsList(pxList, start, end);

    const noPatientsDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No patients to display at the moment</h4>
            </div>
        </div>
    )

	const headersRow = (
		<tr>
			<th>Row</th>
			<th>Px Ref</th>
			<th>Created By</th>
			<th>Created At</th>
		</tr>
	)

	const patientsRows = patients.map((patient, index) => (
		<tr key={patient.id}>
			<td>{index+1}</td>
			<td>{patient.patientReference}</td>
			<td>{getNameString(patient.createdBy)}</td>
			<td>{reformatDateTime(patient.createdOn)}</td>
		</tr>
		))

    return (
		<>
			{patientsLength===0 && noPatientsDisplay}
			{patientsLength>0 && (
				<Table striped bordered hover responsive className="table-sm">
					<thead>
						{headersRow}
					</thead>
					<tbody>
						{patientsRows}
					</tbody>
				</Table>
			)}
			{patientsLength > nPatientsWindow && (
				<PaginationBar arrayLength={patientsLength} setSliceValues={updatePatientsSlice} windowSize={nPatientsWindow}/>
			)}
		</>
    )

}

AdminPatientTable.propTypes = {
	patientsList: patientsPropType.isRequired
};

const mapStateToProps = (state) => ({
	patientsList: state.admin.patientsList,
});

export default connect(mapStateToProps, {})(AdminPatientTable);

import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PatientEntryForm from '../../components/patient/PatientEntryForm';

function PatientEntryScreen() {

	const navigate = useNavigate();
	const [refReqSuccessful, setRefReqSuccessful] = useState(false);
	// const pxId = useSelector((state) => state.patients.currentPatient.id);

	useEffect(() => {
		if (refReqSuccessful===true){
			// navigate(`/patients/${pxId}`);
			navigate("/patients");
		}
	}, [refReqSuccessful])

	return (
		<div className='medr-layout text-center newpatient-page' style={{maxWidth:'700px', marginLeft: 'auto', marginRight: 'auto'}}>
			<Row className='medr-layout text-center'>
				<Col>
				<h4>Enter Patient Information</h4>
				</Col>
			</Row>
			
			<PatientEntryForm initNewPatient isSuccessful={setRefReqSuccessful}/>
		</div>
	)
}

export default PatientEntryScreen;

import React, { useEffect, useState } from 'react';
import { Modal, Card, Row, Col, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { strPropType, patientPropType } from '../../constants/propTypes';
import { reformatDateTime, getNameString } from '../../actions/general';
import { hidden } from '../../constants/variables';
import  { deletePatientById, getClinicianPatientList } from '../../actions/patientActions';
import PatientImagePopup from './PatientImagePopup';


function PatientCard({ patient }) {

	if (patient===null || patient===undefined){
		return (
			<Card className="my-3 p-3 rounded sel-card medr-card">
				<Card.Body>
					Loading patient information
				</Card.Body>
			</Card>
		);
	}
	const { displayType, detailsImage } = patient;

	const showPatientImage = detailsImage!==null; 

	const showView ={
		Added:{
			patientTitle: true,
			patientContact: true,
			patientImage: showPatientImage,
			seeReferralButton: false,
			seeMakeReferralButton:true,
			seeUpdatePatientButton: true,
			seeDeletePatientButton: true,
		},
		Sent:{
			patientTitle: true,
			patientContact: true,
			patientImage: showPatientImage,
			seeReferralButton: true,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: true,
			seeDeletePatientButton: true,
		},
		Accepted:{
			patientTitle: true,
			patientContact: true,
			patientImage: showPatientImage,
			seeReferralButton: false,
			seeMakeReferralButton:true,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Pending:{
			patientTitle: false,
			patientContact: false,
			patientImage: false,
			seeReferralButton: false,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Open:{
			patientTitle: false,
			patientContact: false,
			patientImage: false,
			seeReferralButton: false,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Init: {
			patientTitle: false, 
			patientContact: false, 
			patientImage: false, 
			seeReferralButton: false, 
			seeMakeReferralButton: false,
			seeUpdatePatientButton: false, 
			seeDeletePatientButton: false
		}

	}
	const [showEle, setShowEle] = useState(showView.Init);

	useEffect(() => {
		if (displayType in showView){
			setShowEle(showView[displayType]);
		} else {
			setShowEle(showView.Init);
		}
	}, [displayType]);

	const { 
		patientTitle, 
		patientContact, 
		patientImage, 
		seeReferralButton, 
		seeMakeReferralButton,
		seeUpdatePatientButton, 
		seeDeletePatientButton
	} = showEle;

	const idStr = String(patient.id).padStart(3, '0');
	const pxCardId = `px${idStr}`; 	

	const dispatch = useDispatch();
	const navigate = useNavigate();


	function checkUrlPath(){
		const currentPathname = window.location.pathname;
		const pathParts = currentPathname.split('/');
		const lastPart = pathParts[pathParts.length - 1];

		return lastPart==='patients';
	}


	const [deletePxRequestFailed, setDeletePxRequestFailed] = useState(false);

	const deleteFailedPopup = (	
		<Modal show={deletePxRequestFailed} onHide={() => setDeletePxRequestFailed(false)}>
			<Modal.Header closeButton>
				<Modal.Title style={{color:'black'}}>Unable to delete patient, check open referrals!</Modal.Title>
			</Modal.Header>

			<Modal.Footer>
				<Button variant="secondary" onClick={() => setDeletePxRequestFailed(false)}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)

	const [showPatientImagePopup, setShowPatientImagePopup] = useState(false);
	const patientImagePopup = (<PatientImagePopup patient={patient} showModal={showPatientImagePopup} handleClose={() => setShowPatientImagePopup(false)}/>);

	// comps

	function getPatientTitle(isHidden=false) {
		return (
			<Row>
				<Col>
					<div className='patient-name bluegreen'>{isHidden===true ? hidden : getNameString(patient)}</div>
					<div><span className='patient-current-age'>{patient.currentAge}</span> <span className='patient-gender'>{patient.sex}</span></div>
				</Col>
			</Row>
		)
	}

	function getPatientContact(isHidden=false){
		return (
			<Row>
				<Col>
					<div className='patient-email'>{ 
					isHidden===true 
					? "Accept the referral to see patient contact details" 
					: <a className='patient-email bluegreen' href={`mailto:"${patient.primaryEmail}`}>{patient.primaryEmail}</a>
					}</div>
					{isHidden===true ? null : <a className='patient-phone bluegreen' href={`tel:"${patient.primaryPhoneNumber}`}>{patient.primaryPhoneNumber}</a>}
				</Col>
			</Row>
		)
	}

	function getPatientImage(){
		return(
			<Row className='my-4'>
				<Col className='patient-image-link'>
					<Button variant='primary' onClick={() => setShowPatientImagePopup(true)}>See patient details image</Button>
				</Col>
			</Row>
		)
	}

	function getSeeReferralButton(){
		const onClickSeeReferrals = () => navigate(`/patients/${patient.id}`)

		return (
			<Row className='my-4'>
				<Col>
					<Button className='sel-button w80' variant='success' onClick={onClickSeeReferrals}>See Referrals</Button>
				</Col>
			</Row>
		)
	}

	function getMakeReferralButton(){

		const onClickMakeReferral = () => navigate(`/referral/add/patientId/${patient.id}`);

		return (
			<Row className='my-2'>
				<Col>
					<Button className='sel-button w80' variant='success' onClick={onClickMakeReferral}>Refer</Button>
				</Col>
			</Row>
		)
	}

	function getUpdatePatientButton(){

		const onClickUpdatePx = () => navigate(`/patients/${patient.id}/edit`);

		return (
			<Row className='my-2'>
				<Col>
					<Button className='sel-button w80' variant='primary' onClick={onClickUpdatePx}>Update</Button>
				</Col>
			</Row>
		)
	}

	function getDeletePatientButton(){
		const deletePxSuccessful = (value) => {
			setDeletePxRequestFailed(!value)
			if (value===true){
				if (checkUrlPath()){
					dispatch(getClinicianPatientList());
				} else {
					navigate('/patients');
				}
			}
		}
		
		const onClickDeletePx = () => dispatch(deletePatientById(patient.id, deletePxSuccessful));
		return (
			<Row className='my-2'>
				<Col>
					<Button className='sel-button w80 ' variant='danger' onClick={onClickDeletePx}>Delete</Button>
				</Col>
			</Row>
		)
	}

	function getCreatedOnFooter(){
		return (
			<Row>
				<Col>	
					<p className='patient-referal-send-on'>Sent on: {reformatDateTime(patient.createdOn)}</p>
				</Col>
			</Row>
		)
	}

	return (
		<div className='medr-rounded bg-medr-gray' id={pxCardId}>
			{getPatientTitle(!patientTitle)}
			<hr/>
			{getPatientContact(!patientContact)}
			{patientImage===true ? getPatientImage() : null}
			{seeReferralButton===true ? getSeeReferralButton() : null}
			{seeMakeReferralButton===true ? getMakeReferralButton() : null}
			{seeUpdatePatientButton===true ? getUpdatePatientButton() : null}
			{seeDeletePatientButton===true ? getDeletePatientButton() : null}
			{deletePxRequestFailed===true ? deleteFailedPopup : null}
			{showPatientImagePopup===true ? patientImagePopup : null}
			<hr />
			{getCreatedOnFooter()}
		</div>
	)
}

PatientCard.propTypes = {
	id: strPropType.isRequired,
	patient: patientPropType.isRequired
};

export default PatientCard;

import React, { useEffect } from "react";
import { Row,  Col, Button  } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import ClinicsDisplayBox from "../clinicians/ClinicsDisplayBox";
import AppointmentsContactBox from "../clinicians/AppointmentsContactBox";
import  { register } from "../../actions/authActions";
import { jobRolesPropType, appointmentsContactListPropType, clinicsPropType, insuranceAgreementsPropType } from "../../constants/propTypes";
import { reformatDate } from "../../actions/general";
import BetaVersionFreeAccountAlert from "../app/alerts/BetaVersionFreeAccountAlert";

function RegisterReview({ values }){
    
    useEffect(() => {window.scroll(0,0)}, []);

    if (values===null || values===undefined){
        return (
            <div className='medr-rounded'>
                <Row>
                    <Col>
                        <h4>Error missing data</h4>
                    </Col>
                </Row>
            </div>
        );
    }
    const { 
        email, 
        password,
        rePassword,
        title,
        firstName, 
        lastName,  
        // jobRoleId, 
        jobRoles, 
        registrationNumber, 
        dob, 
        clinics,
        newProviders, 
        existingProviders,
        clinicianDescription,
        appointmentsContacts,
    } = values;

    const jobRoleIds = jobRoles.map(item => item.id);

    const jobRoleDisplay = () => {
        if (jobRoles===null || jobRoles===undefined) return null;
        return jobRoles.map((role) => <div key={role.id}>{role.jobTitle}</div>);
    }

    const appContactList = appointmentsContacts ? appointmentsContacts.map((contact, index) => <AppointmentsContactBox contact={contact} index={index} removeAppointmentsContact={null}/>) : null;
    const appContactsBox = (
        <>
            <h5 className='bluegreen'>Appointments contact information</h5>
            <div className='medr-rounded-inv'>
                    {appContactList}
            </div>
        </>
    )

    const name = `${title} ${firstName} ${lastName}`;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const registrationSuccessful = (value) => {
        if (value===true){
            navigate("/registered/check-email");
        }
    }

    const onSubmit = () => {
        const requestData = {
            email,
            password,
            rePassword,
            title,
            firstName,
            lastName,
            dob,
            registrationNumber,
            jobRoleIds,
            clinics,
            existingProviders,
            newProviders,
            clinicianDescription,
            appointmentsContacts,
        }
        dispatch(register(requestData, registrationSuccessful));
    }

    return (
        <>
            <BetaVersionFreeAccountAlert />
            <Row>
                <Col>
                    <h4>Registration summary</h4>
                </Col>
            </Row>
            <div className='medr-rounded mb-4'>
                <Row className="m-2">
                    <Col>
                        <h5 className='bluegreen'>Clinician details</h5>
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Name:
                    </Col>
                    <Col>
                        {name}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Clinician Role:
                    </Col>
                    <Col>
                        {jobRoleDisplay()}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Registration Number:
                    </Col>
                    <Col>
                        {registrationNumber}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        DOB:
                    </Col>
                    <Col>
                        {reformatDate(dob)}
                    </Col>
                </Row>
                <Row className='m-2'>
                    <Col className='width-m45'>
                        Email:
                    </Col>
                    <Col>
                        {email}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <p className='text-center'>Return to previous screen to see insurance providers selected</p>
                    </Col>
                </Row>
                {appointmentsContacts.length>0 ? appContactsBox : null}
            </div>

            <div className='mt-4 mb-4'>
                <ClinicsDisplayBox clinics={clinics} />
            </div>
            
            <Row>
                <Col className='text-center'>
                    <Button className='sel-button w80 my-4' variant='success' type='submit' onClick={onSubmit}>Complete registration</Button>
                </Col>
            </Row>
        </>
    )
};


RegisterReview.propTypes = {
    values: PropTypes.shape({
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        rePassword: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        jobRoleId: PropTypes.number.isRequired,
        jobRoles: jobRolesPropType.isRequired,
        registrationNumber: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        appointmentsContacts: appointmentsContactListPropType.isRequired,
        clinics: clinicsPropType.isRequired,
        newProviders: insuranceAgreementsPropType.isRequired,
        existingProviders: insuranceAgreementsPropType.isRequired,
        clinicianDescription: PropTypes.string.isRequired,
     }).isRequired,
};

export default RegisterReview;
import React, { useState } from "react";
import { Row,  Col, Button, Form  } from 'react-bootstrap';
import PropTypes from 'prop-types';


function InsuranceProviderForm({ handleAddProvider }){
    const initForm = {
        isNewProvider: true,
        provider: '',
    };

    const [formData, setFormData] = useState(initForm);

    const { provider } = formData;

    const handleAdd = () => {
        if (provider==='') return;
        handleAddProvider(formData);
        setFormData(initForm);
    };

    return (
        <div className='medr-rounded bg-medr-gray mb-4 text-left'>
            <h4 className='bluegreen'>Enter insurance provider</h4>
            
            <Row>
                <Col>
                    <Form>
                        <Form.Group controlId="providerName">
                            <Form.Label className='my-0 bluegreen'>Provider</Form.Label>
                            <Form.Control
                                required
                                type="name"
                                placeholder="Enter insurance provider"
                                className='mb-3'
                                value={provider}
                                onChange={(e) => setFormData({...formData, provider: e.target.value})}
                            />
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <Button className='sel-button w80 my-4' onClick={handleAdd}>Save new provider</Button>
                </Col>
            </Row>
        </div>

    )
};

InsuranceProviderForm.propTypes = {
	handleAddProvider: PropTypes.func.isRequired,
};

export default InsuranceProviderForm;
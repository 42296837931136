import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { CheckAuthFromStorage } from '../../actions/navigations';
import { AUTH_FROM_STORAGE } from '../../constants/authConstants';
import { PROFILE_FROM_STORAGE } from '../../constants/profileConstants';
import { getUserAuthFromStorage, getUserProfileFromStorage } from '../../actions/storageActions';

function AuthenticatedRoutes() {
	const dispatch = useDispatch();

	const isAuthenticated = CheckAuthFromStorage();
	const auth = getUserAuthFromStorage();
	const profile = getUserProfileFromStorage();
	
	if (isAuthenticated===true){
		if (auth && auth!==undefined) {
			dispatch({type:AUTH_FROM_STORAGE, payload:auth});
		}
		if (profile && profile!==undefined) {
			dispatch({type:PROFILE_FROM_STORAGE, payload:profile});
		}
	}

	return isAuthenticated===true ? <Outlet /> : <Navigate to='/login' />;
}

export default AuthenticatedRoutes;

export const LOAD_USER_PROFILE_REQUEST = 'LOAD_USER_PROFILE_REQUEST';
export const LOAD_USER_PROFILE_SUCCESS = 'LOAD_USER_PROFILE_SUCCESS';
export const LOAD_USER_PROFILE_FAIL = 'LOAD_USER_PROFILE_FAIL';

export const UPDATE_USER_PROFILE_REQUEST = 'UPDATE_USER_PROFILE_REQUEST';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const UPDATE_USER_NOTIFICATIONS_REQUEST = 'UPDATE_USER_NOTIFICATIONS_REQUEST';
export const UPDATE_USER_NOTIFICATIONS_SUCCESS = 'UPDATE_USER_NOTIFICATIONS_SUCCESS';
export const UPDATE_USER_NOTIFICATIONS_FAIL = 'UPDATE_USER_NOTIFICATIONS_FAIL';

export const UPDATE_USER_PROFILE_SUMMARY_REQUEST = 'UPDATE_USER_PROFILE_SUMMARY_REQUEST';
export const UPDATE_USER_PROFILE_SUMMARY_SUCCESS = 'UPDATE_USER_PROFILE_SUMMARY_SUCCESS';
export const UPDATE_USER_PROFILE_SUMMARY_FAIL = 'UPDATE_USER_PROFILE_SUMMARY_FAIL';

export const UPDATE_REFERRAL_CONTACTS_REQUEST = 'UPDATE_REFERRAL_CONTACTS_REQUEST';
export const UPDATE_REFERRAL_CONTACTS_SUCCESS = 'UPDATE_REFERRAL_CONTACTS_SUCCESS';
export const UPDATE_REFERRAL_CONTACTS_FAIL = 'UPDATE_REFERRAL_CONTACTS_FAIL';

export const USER_SUMMARY_ANALYTICS_REQUEST = 'USER_SUMMARY_ANALYTICS_REQUEST';
export const USER_SUMMARY_ANALYTICS_SUCCESS = 'USER_SUMMARY_ANALYTICS_SUCCESS';
export const USER_SUMMARY_ANALYTICS_FAIL = 'USER_SUMMARY_ANALYTICS_FAIL';

export const REQUEST_HELP_REQUEST = 'REQUEST_HELP_REQUEST';
export const REQUEST_HELP_SUCCESS = 'REQUEST_HELP_SUCCESS';
export const REQUEST_HELP_FAIL = 'REQUEST_HELP_FAIL';

export const PROFILE_FROM_STORAGE = 'PROFILE_FROM_STORAGE';
import *  as CONSTS from '../constants/authConstants';
// import PropTypes from 'prop-types';

const initialState = {
	loading: false,
	error: '',
	isAuthenticated: false,
	isAdmin: false,
	user: {
		profile_picture: "",
		title: "",
		firstName: "",
		lastName: "",
		jobRole: ""
	},
	token:''
};

// eslint-disable-next-line default-param-last
export default function auth(state = initialState, action) {

	const { type, payload } = action;

	switch (type) {
		case CONSTS.REGISTER_REQUEST:
		case CONSTS.VERIFY_EMAIL_REQUEST:
		case CONSTS.LOGIN_REQUEST:
		case CONSTS.CHECK_AUTH_REQUEST:
		case CONSTS.LOGOUT_REQUEST:
		case CONSTS.RESET_PASSWORD_REQUEST:
		case CONSTS.RESET_PASSWORD_SET_NEW_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.REGISTER_SUCCESS:
		case CONSTS.VERIFY_EMAIL_SUCCESS:
		case CONSTS.RESET_PASSWORD_SUCCESS:
		case CONSTS.RESET_PASSWORD_SET_NEW_SUCCESS:
			return {...state,};
		case CONSTS.LOGIN_SUCCESS:
		case CONSTS.CHECK_AUTH_SUCCESS:
			return {
				...state,
				loading: false,
				isAuthenticated: true,
				isAdmin: payload.isAdmin,
				user: payload.user,
				token:payload.token
			};
		case CONSTS.AUTH_FROM_STORAGE:
			return {
				...state,
				loading: false,
				isAuthenticated: true,
				isAdmin: payload.isAdmin,
				user: payload.user
			};
		case CONSTS.LOGOUT_SUCCESS:
			return {...initialState};
		case CONSTS.REGISTER_FAIL:
		case CONSTS.VERIFY_EMAIL_FAIL:
		case CONSTS.LOGIN_FAIL:
		case CONSTS.CHECK_AUTH_FAIL:
		case CONSTS.LOGOUT_FAIL:
		case CONSTS.RESET_PASSWORD_FAIL:
		case CONSTS.RESET_PASSWORD_SET_NEW_FAIL:
			return {
				...state,
				error: payload
			};
		default:
			return state;
	}
};

// auth.propTypes = {
// 	type: PropTypes.string.isRequired,
// 	payload: PropTypes.node.isRequired
// };

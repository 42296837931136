import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { userProfilePropType } from '../../constants/propTypes';
import { updateProfileSummary } from '../../actions/profileActions';

function UserUpdateRequest({ userProfile }) {
    const dispatch = useDispatch();

    const { clinicianDescription } = userProfile;
    const [description, setDescription] = useState(clinicianDescription);

    const onSubmit = () => {
        if (description !== clinicianDescription) {
            dispatch(updateProfileSummary(description));
        }
    }

	return (
        <Form className='text-left'>
            <Form.Group controlId="clinicianDescription">
                <Form.Label className='my-0'>Clinician description</Form.Label>
                <Form.Control
                    required
                    as="textarea"
                    placeholder="Enter clinician description"
                    rows={8}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Form.Group>
            
            <Row className='mt-4 mb-5'>
                <Col className='text-center'>
                    <Button className="sel-button w80" variant="primary" onClick={onSubmit}>Update summary</Button>
                </Col>
            </Row>
        </Form>
	);
}

UserUpdateRequest.propTypes = {
	userProfile: userProfilePropType.isRequired
}

const mapStateToProps = (state) => ({
	userProfile: state.profile.userProfile,
});

export default connect(mapStateToProps, {})(UserUpdateRequest);
import React, { useState } from "react";

function TargetMarker(){

    const [isHovered, setIsHovered] = useState(false);
    
    return (
        <div 
            className={`hoverable-component ${isHovered ? 'hovered map-pin-box' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            <i className={`fa-solid fa-bullseye map-target-marker${isHovered ? "-hover" : ''}`}/>
        </div>  
    )
}

export default TargetMarker;

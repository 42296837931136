import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import { referralsPropType } from '../../constants/propTypes';
import { getClinicianReferralsList } from '../../actions/referralActions';
import ReferralCardsDisplay from '../../components/referrals/ReferralCardsDisplay';
import ReferralSentCardsDisplay from '../../components/referrals/lists/ReferralSentCardsDisplay';
import ReferralAcceptedCardsDisplay from '../../components/referrals/lists/ReferralAcceptedCardsDisplay';

function ReferralsListScreen({ referralsMade, accepted, waiting, referralsOpen}) {
    const [defaultTab, setDefaultTab] = React.useState('referralsReceivedWaiting');
    const tabKey = {
        pending: 'referralsReceivedWaiting',
        accepted: 'referralsReceivedAccepted',
        sent: 'referralsMade',
        open: 'referralsOpen'
    };
    const [searchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    useEffect(() => {
        if (tab!==null && tabKey[tab]!==null) {
            setDefaultTab(tabKey[tab.toLowerCase()]);
        }
    }, [tab]);

    const dispatch = useDispatch();
    useEffect(() => {window.scroll(0,0)}, []);

	useEffect(() => {
        const fetchData = async () => {
            await dispatch(getClinicianReferralsList());
        }
        fetchData();
	}, [dispatch]);

	return (
        <div>
            <Tabs
                activeKey={defaultTab}
                onSelect={setDefaultTab}
                id="referrals-tab"
                className="custom-tabs mb-3"
                >
                <Tab eventKey="referralsReceivedWaiting" title="Referrals Received" className="custom-tabs">
                    <ReferralCardsDisplay referralsList={waiting} />
                </Tab>
                <Tab eventKey="referralsReceivedAccepted" title="Referrals Accepted" className="custom-tabs">
                    <ReferralAcceptedCardsDisplay referralsList={accepted} />
                </Tab>
                <Tab eventKey="referralsMade" title="Referrals Sent" className="custom-tabs">
                    <ReferralSentCardsDisplay referralsList={referralsMade} />
                </Tab>
                <Tab eventKey="referralsOpen" title="Referrals Open" className="custom-tabs">
                    <ReferralCardsDisplay referralsList={referralsOpen} />
                </Tab>
            </Tabs>
		</div>
	);
}

ReferralsListScreen.propTypes = {
	referralsMade: referralsPropType.isRequired,
    accepted: referralsPropType.isRequired,
    waiting: referralsPropType.isRequired,
	referralsOpen: referralsPropType.isRequired
};

const mapStateToProps = (state) => ({
    referralsMade: state.referrals.referralsMade,
	accepted: state.referrals.referralsReceived.accepted,
	waiting: state.referrals.referralsReceived.waiting,
	referralsOpen: state.referrals.referralsOpen
});

export default connect(mapStateToProps, {})(ReferralsListScreen);
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Modal, Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useDispatch, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { strPropType, referralPropType, clinicianProfilesPropType } from '../../constants/propTypes';
import { 
	getNameString, 
	getJobRoleString,
	getClinicCenterStr,
	reformatDateTime
} from '../../actions/general';
import { hidden } from '../../constants/variables';
import { postReferralToggleAccept, getClinicianReferralsList, getReferralById, postReferralCancel } from '../../actions/referralActions';
import ReferralDetailsPopup from './ReferralDetailsPopup';

function ReferralCard({ eleId, referral, crps }) {

	if (referral===null || referral===undefined){
		return (
			<Card className="my-3 p-3 rounded sel-card medr-card">
				<Card.Body>
					Loading referral information
				</Card.Body>
			</Card>
		);
	}

	const idStr = String(referral.id).padStart(3, '0');
	const rxCardId = `rx${idStr}`; 	
	const { displayType, patient, specifiedClinician, referrer, targetClinician } = referral;
	
	const showView = {
		Sent: {
			seePxDetails: true,
			seeReferrerDetails: false,
			seeTargetClinicianDetails: true,
			seeCancelReferralButton: true,
			seeAcceptReferralButton: false,
			seeRejectReferralButton: false,
		},
		Pending: {
			seePxDetails: false,
			seeReferrerDetails: true,
			seeTargetClinicianDetails: false,
			seeCancelReferralButton: false,
			seeAcceptReferralButton: true,
			seeRejectReferralButton: true,
		},
		Accepted: {
			seePxDetails: true,
			seeReferrerDetails: true,
			seeTargetClinicianDetails: false,
			seeCancelReferralButton: false,
			seeAcceptReferralButton: false,
			seeRejectReferralButton: true,
		},
		Open: {
			seePxDetails: false,
			seeReferrerDetails: true,
			seeTargetClinicianDetails: false,
			seeCancelReferralButton: false,
			seeAcceptReferralButton: true,
			seeRejectReferralButton: false,
		},
	}

	const [showEle, setShowEle] = useState(showView[displayType]);

	useEffect(() => {
		setShowEle(showView[displayType]);
	}, [displayType]);

	const {
		seePxDetails,
		seeReferrerDetails,
		seeTargetClinicianDetails,
		seeCancelReferralButton,
		seeAcceptReferralButton,
		seeRejectReferralButton,
	} = showEle;

	const [deleteRxRequestShow, setDeleteRxRequestShow] = useState(false);

	const deleteFailedPopup = (	
		<Modal show={deleteRxRequestShow} onHide={() => setDeleteRxRequestShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title style={{color:'black'}}>Unable to cancel referral as the target has accepted!</Modal.Title>
			</Modal.Header>

			<Modal.Footer>
				<Button variant="secondary" onClick={() => setDeleteRxRequestShow(false)}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	)

	const [selectCrpOpenShow, setSelectCrpOpenShow] = useState(false);

	const isAcceptToggleSuccessfull = (value) => {
		if(value===true){
			updateReferralData();
		}
	}

	const setCrpOpenRxAccept = (crpId) => {
		dispatch(postReferralToggleAccept(referral.id, {toAccept: true, crpId}, isAcceptToggleSuccessfull));
		setSelectCrpOpenShow(false);
	}


	const selectCrpOpenPopup = (
		<Modal show={selectCrpOpenShow} onHide={() => setSelectCrpOpenShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title style={{color:'black'}}>Select your clinic</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{crps.map((crp) => (
					<Button variant="info" onClick={() => setCrpOpenRxAccept(crp.id)}>{getClinicCenterStr(crp.clinic)}</Button>
				))}
			</Modal.Body>
		</Modal>
	)
	
	const [showReferralDetailsPopup, setShowReferralDetailsPopup] = useState(false);
	const referralDetailsPopup = (<ReferralDetailsPopup referral={referral} showModal={showReferralDetailsPopup} handleClose={() => setShowReferralDetailsPopup(false)}/>);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	function checkUrlPath(){
		const currentPathname = window.location.pathname;
		const pathParts = currentPathname.split('/');
		const lastPart = pathParts[pathParts.length - 1];
		return lastPart==='referrals';
	}

	function updateReferralData(){
		if (checkUrlPath()===true){
			dispatch(getClinicianReferralsList());
		} else {
			dispatch(getReferralById(referral.id));
		}
	}

	function getPatientDetails(isHidden=false){
		return(
				<Row>
					<Col>
						<Link to={`/patients/${patient.id}`}>
							<div className='patient-name bluegreen'>{isHidden===true ? hidden : getNameString(patient)}</div>
						</Link>
						<div className='patient-email'>{ 
							isHidden===true 
							? "Accept the referral to see patient contact details" 
							: <a className='patient-email bluegreen' href={`mailto:"${patient.primaryEmail}`}>{patient.primaryEmail}</a>
						}</div>
						{isHidden===true? null : <a className='patient-phone bluegreen' href={`tel:"${patient.primaryPhoneNumber}`}>{patient.primaryPhoneNumber}</a>}

					</Col>
				</Row>
		)
	}

	function getReferralDetails(){
		return (
			<Row onClick={() => setShowReferralDetailsPopup(true)} style={{ cursor: 'pointer' }}>
				<Col className='referal-info-container'>
					<div className='referal-name bluegreen mb-3 lines-max-2'>{referral.history}</div>
					<div className='referal-name bluegreen mt-3 lines-max-2'>{referral.mainDescription}</div>
				</Col>
			</Row>
		)
	}

	function getReferringClinicianDetails(){
		const { clinic } = referrer;

		return (
			<Link to={`/clinicians/${referrer.id}`}>
				<Row>	
					<Col>
						<div className='patient-name bluegreen'>{getNameString(referrer.userProfile)}</div>
						<div className='patient-name bluegreen'>{getJobRoleString(referrer.userProfile.jobRole)}</div>
						<div className='patient-name bluegreen'>{getClinicCenterStr(clinic)}</div>
					</Col>
				</Row>
			</Link>
		)
	}

	function getTargetClinicianDetails(){
		if (specifiedClinician===false){
			return (
				<Row>	
					<Col>
						<h1>Target Clinician Not Set</h1>
					</Col>
				</Row>
			)
		}
	
		const { clinic } = targetClinician;

		return (
				<Row>	
					<Col>
						<Link to={`/clinicians/${targetClinician.id}`}>
							<div className='referal-name bluegreen'>{getNameString(targetClinician.userProfile)}</div>
						</Link>
						<div className='referal-name bluegreen'>{getJobRoleString(targetClinician.userProfile.jobRole)}</div>
						<div className='referal-name bluegreen'>{getClinicCenterStr(clinic)}</div>
					</Col>
				</Row>
		)
	}

	function getCancelReferralButton(){

		const isCancelSuccessfull = (value) => {
			if(value===false){
				setDeleteRxRequestShow(true);
			} else {
				if (checkUrlPath()===true){
					dispatch(getClinicianReferralsList());
				} else {
					navigate(`/referrals`);
				}
			}
		}

		const onClick = () => dispatch(postReferralCancel(referral.id, isCancelSuccessfull));
		
		return (
			<Row className='mt-4'>
				<Col>
					<Button className='sel-button mt-2 w80' variant='danger' onClick={onClick}>Cancel</Button>
				</Col>
			</Row>
		)
	}
	
	function getAcceptReferralButton(){
	
		const onClick = () => {
			if (displayType==='Pending'){
				dispatch(postReferralToggleAccept(referral.id, {toAccept: true, crpId: targetClinician.id}, isAcceptToggleSuccessfull));
			} else if (displayType==='Open'){
				setSelectCrpOpenShow(true);
			}
		}

		return (
			<Row className='mt-4'>
				<Col>
					<Button className='sel-button mt-2 w80' variant='success' onClick={onClick}>Accept</Button>
				</Col>
			</Row>
		)
	}

	function getRejectReferralButton(){

		const isAcceptToggleSuccessfull = (value) => {
			if(value===true){
				updateReferralData();
			}
		}
	
		const onClick = () => dispatch(postReferralToggleAccept(referral.id, {toAccept: false, crpId: targetClinician.id}, isAcceptToggleSuccessfull));

		return (
			<Row>
				<Col>
					<Button className='sel-button mt-2 w80' variant='danger' onClick={onClick}>Reject</Button>
				</Col>
			</Row>
		)
	}

	function getCreatedOnFooter(){
		return (
			<Row>
				<Col>	
					<p className='patient-referal-send-on'>Sent On: {reformatDateTime(referral.createdOn)}</p>
				</Col>
			</Row>
		)
	}

	return (
		<div className='medr-rounded bg-medr-gray' id={rxCardId}>
			{getPatientDetails(!seePxDetails)}
			<hr />
			{getReferralDetails()}
			<hr />
			{seeReferrerDetails===true ? getReferringClinicianDetails() : null}
			{seeTargetClinicianDetails===true ? getTargetClinicianDetails() : null}	
			{seeCancelReferralButton===true ? getCancelReferralButton() : null}
			{deleteRxRequestShow===true ? deleteFailedPopup : null}
			{seeAcceptReferralButton===true ? getAcceptReferralButton() : null}
			{selectCrpOpenShow===true ? selectCrpOpenPopup : null}
			{seeRejectReferralButton===true ? getRejectReferralButton() : null}
			<hr className='mt-4' />
			{getCreatedOnFooter()}
			{showReferralDetailsPopup===true ? referralDetailsPopup : null}
		</div>
	)



}

ReferralCard.propTypes = {
	eleId: strPropType.isRequired,
	referral: referralPropType.isRequired,
	crps: clinicianProfilesPropType.isRequired,
}
const mapStateToProps = (state) => ({
	crps: state.profile.clinicianProfiles,
});

export default connect(mapStateToProps, {})(ReferralCard);

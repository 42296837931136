// import React, { useEffect, useState } from 'react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import JobRoleSearchBar from '../../components/clinicians/JobRoleSearchBar';
import InsuranceAgreementSearchBar from '../../components/clinicians/InsuranceAgreementSearchBar';
import PostcodeSearch from '../../components/clinicians/PostcodeSearch';
import ClinicianMapBox from '../../components/map/ClinicianMapBox';
import { getCliniciansList } from '../../actions/clinicianActions';
import * as CONSTS from '../../constants/searchConstants';
// import useUserLocation from '../../hooks/app/useUserLoaction';

function CliniciansListScreen() {

	const dispatch = useDispatch();
    // const { latitude, longitude, updateCoords } = useUserLocation();

    // updateCoords();

    const initData = {
        jobRoleId: 0,
        jobRoleSelected: '',
        jobSpecialtySelected: '',
        insuranceProviderId: 0,
        // searchLatitude: latitude!== null ? latitude : CONSTS.DEFAULT_LAT,
        // searchLongitude: longitude!== null ? longitude : CONSTS.DEFAULT_LNG,
        searchLatitude: CONSTS.DEFAULT_LAT,
        searchLongitude: CONSTS.DEFAULT_LNG,
    }

    const [formData, setFormData] = useState(initData);
    const { jobRoleId, insuranceProviderId, searchLatitude, searchLongitude, jobRoleSelected, jobSpecialtySelected } = formData;

    const refreshClinicians = () => dispatch(getCliniciansList(jobRoleId, insuranceProviderId, searchLatitude, searchLongitude, jobRoleSelected, jobSpecialtySelected));

    useEffect(() => {
        setFormData({...formData, jobRoleId: 0});
    }, [jobRoleSelected, jobSpecialtySelected]);
	
    useEffect(() => {
        refreshClinicians();
    }, [jobRoleId, insuranceProviderId, searchLatitude, searchLongitude, jobRoleSelected, jobSpecialtySelected]);
	
    const changeValues = (e) => {
        const { key, value } = e
        setFormData({...formData, [key]: value});
    }    

    const changePostcode = (e) => {
        setFormData({...formData, searchLatitude: e.searchLatitude, searchLongitude: e.searchLongitude});
    }

    const navigate = useNavigate();

    const onClinicianSelect = (id) => navigate(`/clinicians/${id}`);

	return (

		<>
            <Row className="py-1">
                <Col className='text-center'>
                    <h1>Search our clinicians</h1>
                </Col>
            </Row>
            <Row className='mb-4'>
                <Col sm={12} md={6}>
                    <JobRoleSearchBar changeValues={changeValues} filtered/>
                </Col>
                <Col sm={12} md={6}>
                    <InsuranceAgreementSearchBar changeValues={changeValues} />
                    <div className='clinicians-search-postcode'>
                        <PostcodeSearch changeValues={changePostcode} />
                    </div>
                </Col>
            </Row>
            <div className='clinicians-search-map mb-4'>
                 <ClinicianMapBox onClinicianClick={onClinicianSelect} enableClinicianSelect refreshClinicians={refreshClinicians}/>
            </div>
           
		</>
	);
}


export default CliniciansListScreen;

import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { clinicsPropType, boolPropType, funcPropType } from "../../constants/propTypes";
import ClinicsDisplayCard from "./ClinicDisplayCard";


function ClinicsDisplayBox({ clinics, editable, removeClinic}){

    let clinicsAdded;
    if (clinics===null || clinics===undefined){
        clinicsAdded = <p className='text-center'>No clinics added yet</p>;
    } else {
        clinicsAdded = clinics.map((clinic) => (<ClinicsDisplayCard key={`newCp${uuidv4()}`} values={clinic} editable={editable} removeClinic={removeClinic} />));
    }

    return (
        <div className='medr-rounded'>
            <h4 className='text-left py-2 bluegreen m-2'>Assigned clinics</h4>
            {clinicsAdded}
        </div>
    )
};

ClinicsDisplayBox.propTypes = {
    clinics: clinicsPropType.isRequired,
    editable: boolPropType.isRequired,
    removeClinic: funcPropType.isRequired,
};

export default ClinicsDisplayBox;
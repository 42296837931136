import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap'; 
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { Row, Col, Button } from 'react-bootstrap'; 
import { patientPropType } from '../../constants/propTypes';
import { getNameString, reformatDateTime, getAddressMultiline } from '../../actions/general';
import { hidden } from '../../constants/variables';
import { deletePatientById, getCurrentPatient } from '../../actions/patientActions';
// import { deletePatientById, getClinicianPatientList, getCurrentPatient } from '../../actions/patientActions';


function PatientBar({ patient }) {
    const dispatch = useDispatch();
    
    if (!patient) {
        return <div id='referral_missing'>Loading...</div>;
    }
    const {
        displayType, 
        detailsImage,
    } = patient;
	const showPatientImage = detailsImage!==null; 

    const showView ={
		Added:{
			patientTitle: true,
			patientContact: true,
            patientInfo: true,
			patientImage: showPatientImage,
			seeReferralButton: false,
			seeReferOnwardButton: false,
			seeMakeReferralButton:true,
			seeUpdatePatientButton: true,
			seeDeletePatientButton: true,
		},
		Sent:{
            patientTitle: true,
			patientContact: true,
            patientInfo: true,
			patientImage: showPatientImage,
			seeReferralButton: true,
            seeReferOnwardButton: false,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: true,
			seeDeletePatientButton: true,
		},
		Accepted:{
            patientTitle: true,
			patientContact: true,
            patientInfo: true,
			patientImage: showPatientImage,
			seeReferralButton: false,
            seeReferOnwardButton: true,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Pending:{
            patientTitle: false,
			patientContact: false,
            patientInfo: false,
			patientImage: false,
			seeReferralButton: false,
            seeReferOnwardButton: false,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Open:{
            patientTitle: false,
			patientContact: false,
            patientInfo: false,
			patientImage: false,
			seeReferralButton: false,
            seeReferOnwardButton: false,
			seeMakeReferralButton:false,
			seeUpdatePatientButton: false,
			seeDeletePatientButton: false,
		},
		Init: {
            patientTitle: false, 
			patientContact: false, 
            patientInfo: false,
			patientImage: false, 
			seeReferralButton: false, 
            seeReferOnwardButton: false,
			seeMakeReferralButton: false,
			seeUpdatePatientButton: false, 
			seeDeletePatientButton: false
		}
	}

    const [isOpen, setIsOpen] = useState(false);
    const handleClickOpen = () => setIsOpen(!isOpen);
	
    // const dispatch = useDispatch();
    const navigate = useNavigate();

    const [viewType , setViewType] = useState(showView[displayType]);

    const {
 		patientTitle, 
		patientContact, 
		// patientImage, 
		// seeReferralButton, 
        seeReferOnwardButton,
		seeMakeReferralButton,
		seeUpdatePatientButton, 
		seeDeletePatientButton
    } = viewType;

    useEffect(() => {setViewType(showView[displayType]);}, [displayType]);

    const pxHidden = (
        <div className='flex-fill text-center referral-bar-item lines-max-1'>
            {hidden}
        </div>
    )

    const pxName = (
        <div className='flex-fill text-center referral-bar-item lines-max-2'>
            <h5 className='m-0'>{getNameString(patient)}</h5>
        </div>
    )

    const pxDob = (
        <div className='flex-fill text-center referral-bar-item lines-max-1'>
            <p className='m-0'>{reformatDateTime(patient.dob)}</p>
        </div>
    )

    const pxAge = (
        <div className='flex-fill text-center referral-bar-item lines-max-1'>
            <p className='m-0'>{patient.currentAge} {patient.sex}</p>
        </div>
    )

    const pxInfo = (
        <div className='flex-fill referral-bar-bottom'>
            <h4 className='m-0 p-1 text-left'>Info:</h4>
            <p className='m-0  p-1 text-left'>Sex: {patient.sex}</p>
            <p className='m-0  p-1 text-left'>Age: {patient.currentAge} years old</p>
        </div>
    )

    const pxContact = (
        <div className='flex-fill referral-bar-bottom'>
            <h4 className='m-0 p-1 text-left'>Contact details:</h4>
            <p className='m-0  p-1 text-left'><a href={`mailto:"${patient.primaryEmail}`}>{patient.primaryEmail}</a></p>
            <p className='m-0  p-1 text-left'><a href={`tel:"${patient.primaryPhoneNumber}`}>{patient.primaryPhoneNumber}</a></p>
        </div>
    )

    const pxAddress = (
        <div className='flex-fill referral-bar-bottom'>
            <h4 className='m-0 p-1 text-left'>Address:</h4>
            {getAddressMultiline(patient.address)}
        </div>
    )


    // BUTTON FUNCTIONS



    function makeReferButton(){

        // const onClick = () => navigate(`/referral/add/patientId/${patient.id}`);
        const onClick = (event) => {
            event.stopPropagation();
            dispatch(getCurrentPatient(patient.id));
            navigate(`/referral/add/patientId/${patient.id}`);
        }

        return (
            <div className='flex-fill text-center referral-bar-item'>
                <Button className='sel-button w80' variant='success' onClick={onClick}>Refer</Button>
            </div>
        )
    }
    function makeReferOnwardButton(){

        // const onClick = () => navigate(`/referral/add/patientId/${patient.id}`);
        const onClick = (event) => {
            event.stopPropagation();
            dispatch(getCurrentPatient(patient.id));
            navigate(`/referral/add/patientId/${patient.id}`);
        }

        return (
            <div className='flex-fill text-center referral-bar-item'>
                <Button className='sel-button w80' variant='success' onClick={onClick}>Refer on</Button>
            </div>
        )
    }

    function updateButton(){

        // const onClick = () => navigate(`/patients/${patient.id}/edit`);
        const onClick = (event) => {
            event.stopPropagation();
            navigate(`/patients/${patient.id}/edit`);
        }
  
        return (
            <div className='flex-fill text-center referral-bar-item medr'>
                <Button className='sel-button w80' variant='info' onClick={onClick}>Update</Button>
            </div>
        )
    }

    function deleteButton(){
        const deleteSuccess = (value) => {
            if (value){
                // dispatch(getClinicianPatientList());
                navigate("/patients");
            }
        }

        // const onClick = () => dispatch(deletePatientById(patient.id, deleteSuccess));
        const onClick = (event) => {
            event.stopPropagation();
            dispatch(deletePatientById(patient.id, deleteSuccess));
        }
        
        return (
            <div className='flex-fill text-center referral-bar-item'>
                <Button className='sel-button w80' variant='danger' onClick={onClick}>Delete</Button>
            </div>
        )
    }

	return (
        <div 
            className={`medr-rounded-inv obj-clickable ${isOpen ? 'bar-open' : ''}`}
            onClick={handleClickOpen} 
            onKeyDown={handleClickOpen}
            role="button"
            tabIndex={0}
            id={`pxbar${patient.id}`}
            >
            <div className="d-flex align-items-center p-0 referral-bar">
                {patientTitle ? pxName : pxHidden}
                {patientTitle ? pxDob : pxAge}
                <div className="d-flex align-items-center p-0 bar-buttons">
                    {seeMakeReferralButton ? makeReferButton() : null}
                    {seeReferOnwardButton ? makeReferOnwardButton() : null}
                    {seeUpdatePatientButton ? updateButton() : null}
                    {seeDeletePatientButton ? deleteButton() : null}
                </div>
            </div>
            {isOpen ? (
                <div className="d-flex p-1 referral-info" >
                    {pxInfo}
                    {patientContact ? pxContact : null}
                    {patientContact ? pxAddress : null}
                    {/* {seePxDetails ? pxContact : pxContactHidden}
                    {pxHistory}
                    {pxReason} */}
                </div>
            )  : null}            
        </div>
	)

}

PatientBar.propTypes = {
	patient: patientPropType.isRequired
};

export default PatientBar;

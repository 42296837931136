import React from "react";
import { clinicanProfilePropType, funcPropType } from "../../../constants/propTypes";

function FavClinicianStar({ clinician, togglePopup}) {

    const { isFavourite } = clinician;
    // const [displayPopup, setDisplayPopup] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        togglePopup(true);
    }

    return (
        <button 
            className={`${isFavourite ? '' : 'un'}star-button`} 
            type='button' 
            onClick={onClick} 
            aria-label='Star'
            >
            <i className={`${isFavourite ? 'fas' : 'far'} fa-star`} />
        </button>
    )
}


FavClinicianStar.propTypes = {
    clinician: clinicanProfilePropType.isRequired,
    togglePopup: funcPropType,
};

FavClinicianStar.defaultProps = {
    togglePopup: null,
};

export default FavClinicianStar;
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import UserAppointmentsContactForm from './UserAppointmentsContactForm';
import { appointmentsContactListPropType } from '../../constants/propTypes';
import { updateReferralContacts } from '../../actions/profileActions';

function ProfileReferralContactsUpdate({ referralContacts }) {
    const dispatch = useDispatch();
	const [values, setValues] = useState({appointmentsContacts: referralContacts});

	const updateCurrentValues = (e) => setValues({...values, [e.key]: e.value});

    useEffect(() => {
		if (values.appointmentsContacts !== referralContacts){
			dispatch(updateReferralContacts(values.appointmentsContacts));
		}
    }, [values.appointmentsContacts]);

	return (
		<>
			<h4 className='text-left mt-4'>Referral contacts</h4>
			<UserAppointmentsContactForm values={values} changeValues={updateCurrentValues} commit/>            
		</>
	);
}


ProfileReferralContactsUpdate.propTypes = {
	referralContacts: appointmentsContactListPropType.isRequired
}

const mapStateToProps = (state) => ({
	referralContacts: state.profile.userProfile.appointmentsContact,
});

export default connect(mapStateToProps, {})(ProfileReferralContactsUpdate);
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

function PoliciesScreen() {
	return (
        <>
            <Row className='text-center'>
                <Col className='text-center'>
                    <h2>MedR Terms and Conditions</h2>
                </Col>
            </Row>
            <Row className='text-center'>
                <Col className='text-center'>
                    <embed src="/terms/MEDR_TERMS.pdf" type="application/pdf" height="500px" width="700px" className="responsive" />
                </Col>
            </Row>
            <Row className='text-center'>
                <Col className='test-center'>
                    <Button className="w50" variant="info" href="/terms/MEDR_TERMS.pdf">Download terms and conditions</Button>
                </Col>
            </Row>
        </>
	);
}


export default PoliciesScreen;

import React from "react";
import { Row,  Col, Button  } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';


function InsuranceDisplayCard({ values, isNew, editable, removeProvider  }){
    
    if (values===null || values===undefined){
        return (
            <div className='medr rounded'>
                <Row>
                    <Col>
                        <h4>Error retrieving insurance details</h4>
                    </Col>
                </Row>
            </div>
        )
    };

    const { provider } = values;

    const id = values.id ? values.id : null;

    const providerType = isNew ? 'newProvider' : 'existingProvider';

    return (
        <div className='medr-rounded-inv m-1' id={uuidv4()}>
            <div className="d-flex align-items-center p-0">
                <div className='flex-fill text-center'>
                    <h4 className='m-0'>{provider}</h4>
                </div>
                <div className='align-items-center'>
                {editable ? (
                    <Button key={`insuranceBtn${uuidv4()}`} className='p-0 insurance-del-btn' id={id} onClick={() => removeProvider({key: providerType, value: id, provider})}>
                        <i className="fa-solid fa-circle-minus"  />
                    </Button>
                ) : null}
                </div>
            </div>
        </div>
    )
};

InsuranceDisplayCard.propTypes = {
    values: PropTypes.shape({
        id: PropTypes.number,
        provider: PropTypes.string.isRequired,
    }).isRequired,
    isNew: PropTypes.bool.isRequired,
    editable: PropTypes.bool,
    removeProvider: PropTypes.func,
};

InsuranceDisplayCard.defaultProps = {
    editable: false,
    removeProvider: () => {},
};

export default InsuranceDisplayCard;
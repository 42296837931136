import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import InsuranceAgreementsForm from '../clinicians/InsuranceAgreementsForm';
import { clinicianProfilesPropType, insuranceAgreementsPropType } from '../../constants/propTypes';
import { updateClinicianInsuranceAgreements } from '../../actions/clinicianActions';
import { loadUser } from '../../actions/profileActions';

function ProfileChangeInsurance({ clinicalProfiles, agreements }) {
    const dispatch = useDispatch();

    const [dataHasChanged, setDataHasChanged] = useState(false);
    const [originalList, setOriginalList] = useState([]);
    const [existingAgreements, setExisitingAgreements] = useState([]);
    const [newAgreements, setNewAgreements] = useState([]);

    const resetProviders = () => {
        setOriginalList(clinicalProfiles[0].insuranceAgreements);
        setExisitingAgreements(clinicalProfiles[0].insuranceAgreements);
    }

    useEffect(() => {
        if (clinicalProfiles!==null && clinicalProfiles!==undefined && clinicalProfiles.length!==0) {
            resetProviders();
        }
    }, [])

    const handleAddExisitingProvider = (e) => {
        if (agreements!==null && agreements!==undefined && agreements.length>0 && e.value!==0){
            const isAgreementPresent = existingAgreements.some(item => item.id === e.value);
            const provider = agreements.filter(item => item.id === e.value)[0];
            if(!isAgreementPresent){
                setExisitingAgreements((prevAgreements)=> [...prevAgreements, provider]);
            }
        }
    }

    const handleAddNewProvider = (provider) => {
        setNewAgreements((prevAgreements)=> [...prevAgreements, provider]);
    }

    const handleRemoveProvider = (e) => {
        if (e.key==='newProvider'){
            const newList = newAgreements.filter(item => item.provider !== e.provider);
            setNewAgreements(newList);

        } else if (e.key==='existingProvider'){
            const newList = existingAgreements.filter(item => item.id !== e.value);
            setExisitingAgreements(newList);
        }
    }

    const isSuccess = (value) => {
        setDataHasChanged(!value);
        if (value===true){
            dispatch(loadUser());
        }
    }


    const onSubmit = () => {
        const formData = {newAgreements, existingAgreements};
        dispatch(updateClinicianInsuranceAgreements(formData, isSuccess));
    };

    const revertChanges = () => {
        resetProviders();
        setNewAgreements([]);
        setDataHasChanged(false);
    }

    useEffect(() => {
        if (newAgreements!==null && newAgreements!==undefined && newAgreements.length!==0) {
            setDataHasChanged(true);
        }

        if (existingAgreements.length!==originalList.length){
            setDataHasChanged(true);
        }

        originalList.forEach((provider) => {
            const providerId = provider.id;
            const isProviderPresent = existingAgreements.some(item => item.id === providerId);
            if (!isProviderPresent){
                setDataHasChanged(true);
            }
        });    
    }, [existingAgreements, newAgreements])
    
    // useEffect(() => {
    //     console.log('original ', originalList)
    //     console.log('exisiting ', existingAgreements)
    //     console.log('new ', newAgreements)
    // }, [ originalList, existingAgreements, newAgreements])
    
	return (
        <>
            <InsuranceAgreementsForm 
                newProviders={newAgreements}
                existingProviders={existingAgreements}
                addExisitngProvider={handleAddExisitingProvider} 
                addNewProvider={handleAddNewProvider} 
                removeProvider={handleRemoveProvider}
            />
            <Row className='mt-5'>
                <Col>
                    {dataHasChanged===true ? <Button className='sel-button w80' variant="primary" onClick={onSubmit}>Save changes</Button> : null}
                </Col>
                <Col>
                    {dataHasChanged===true ? <Button className='sel-button w80' variant="info" onClick={revertChanges}>Cancel</Button> : null}
                </Col>
            </Row>
        </>
	);
}

ProfileChangeInsurance.propTypes = {
    clinicalProfiles: clinicianProfilesPropType.isRequired,
    agreements: insuranceAgreementsPropType.isRequired,
};


const mapStateToProps = (state) => ({
	clinicalProfiles: state.profile.clinicianProfiles,
    agreements: state.clinicians.insuranceAgreements
});

export default connect(mapStateToProps, {})(ProfileChangeInsurance);
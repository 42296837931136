import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Tabs, Tab } from 'react-bootstrap';
import { patientsPropType } from '../../constants/propTypes';
import { getClinicianPatientList } from '../../actions/patientActions';
import PatientCardsDisplay from '../../components/patient/PatientCardsDisplay';

function PatientListScreen({ activePatients, inactivePatients, acceptedPatients}) {
// function PatientListScreen({ activePatients, inactivePatients, acceptedPatients, waitingPatients}) {

    /**
     * 1. Get the patients from the store
     */

    
    // RETREIVE the patients first
    const dispatch = useDispatch();
    useEffect(() => {window.scroll(0,0)}, []);
	useEffect(() => {
        const fetchData = async () => {
            await dispatch(getClinicianPatientList());
        }
        fetchData();
	}, [dispatch]);


	return (
        <div>
            <Tabs
                defaultActiveKey="activePatients"
                id="custom-tabs"
                className="custom-tabs mb-3"
                >
                <Tab eventKey="activePatients" title="Patients Added" className="custom-tabs">
                    <PatientCardsDisplay patientsList={activePatients} />
                </Tab>
                <Tab eventKey="pastPatients" title="Patients Referred" className="custom-tabs">
                    <PatientCardsDisplay patientsList={inactivePatients} />                
                </Tab>
                <Tab eventKey="acceptedPatients" title="Patients Accepted" className="custom-tabs">
                    <PatientCardsDisplay patientsList={acceptedPatients} />                
                </Tab>
                {/* <Tab eventKey="waitingPatients" title="Referral Pending" className="custom-tabs">
                    <PatientCardsDisplay patientsList={waitingPatients} />                
                </Tab> */}
            </Tabs>
		</div>
	);
}

PatientListScreen.propTypes = {
	activePatients: patientsPropType.isRequired,
	inactivePatients: patientsPropType.isRequired,
	acceptedPatients: patientsPropType.isRequired,
	// waitingPatients: patientsPropType.isRequired
};

const mapStateToProps = (state) => ({
	activePatients: state.patients.activePatients,
    inactivePatients: state.patients.pastPatients,
    acceptedPatients: state.patients.acceptedPatients,
    // waitingPatients: state.patients.waitingPatients,
});

export default connect(mapStateToProps, {})(PatientListScreen);

import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import  { insuranceAgreementsPropType, funcPropType } from '../../constants/propTypes';
import { getInsuranceAgreementsList } from "../../actions/clinicianActions";

function InsuranceSelectorGrid({ insuranceAgreements, existingProviders, toggleProvider }){

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getInsuranceAgreementsList());
    }, [dispatch]);

    const [providers, setProviders] = useState([]);

    useEffect(() => {
        if (!insuranceAgreements || insuranceAgreements.length === 0) {
            return;
        }

        const selectedIxisitngIds = existingProviders.map(item => item.id);
        const list = insuranceAgreements.map(provider => ({
            ...provider, 
            selected: selectedIxisitngIds.includes(provider.id)
        }));
        setProviders(list);
    }, [insuranceAgreements, existingProviders]);


    const toggleProviderSelected = useCallback((id) => {
        if  (id==null){
            return;
        } 
        const updatedProviders = providers.map((provider) => {
            if (provider.id === id) {
                return {...provider, selected: !provider.selected}; // Toggle selected and return a new object
            }
            return provider; // Return unmodified provider for those not matching the id
        });
        const isSelected = updatedProviders.filter(provider => provider.id === id)[0].selected;
        toggleProvider({value: id, isSelected});
        setProviders(updatedProviders);
    }, [providers, toggleProvider]);

    if (!providers.length) {
        return <div>Loading...</div>; // Or any other placeholder
    }

    const selectAll = () => {
        const updatedProviders = providers.map((provider) => {
            toggleProvider({value: provider.id, isSelected: true});
            return ({...provider, selected: true})
        }); // Toggle selected and return a new object
        setProviders(updatedProviders);
    }

    return (
            <>
                <Row>
                    <Col className='text-center'>
                        <Button className='sel-button w80' variant='primary' onClick={selectAll}>Select all insurers</Button>
                    </Col>
                </Row>
                <Row className='my-1 insurance-list'>
                    {providers.map((insurance) => (
                        <Col xs={12} md={4} lg={3} key={`insProv${insurance.id}col`}>
                            <Button key={`insProv${insurance.id}`} className='insurance-check-btn' variant={insurance.selected ? 'info' : 'primary'} onClick={() => toggleProviderSelected(insurance.id)} >
                                {insurance.provider}
                            </Button>
                        </Col>
                    ))}
                </Row>
            </>
    );
};

InsuranceSelectorGrid.propTypes = {
    insuranceAgreements: insuranceAgreementsPropType.isRequired,
    existingProviders: insuranceAgreementsPropType.isRequired,
    toggleProvider: funcPropType.isRequired,
}

const mapStateToProps = state => ({
    insuranceAgreements: state.clinicians.insuranceAgreements
})

export default connect(mapStateToProps, {})(InsuranceSelectorGrid);
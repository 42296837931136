import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setSearchPostcode } from '../../actions/searchActions';


function PostcodeSearch({ currentPostcode, changeValues, valueKeys, lat, lng }) {

    const { postcodeKey, latKey, lngKey } = valueKeys;

	const dispatch = useDispatch();
	const [postcode, setPostcode] = useState(currentPostcode);
    const [error, setError] = useState(false);

	const onChange = (e) => {setPostcode(e.target.value);}
    
    const onSubmit = async (e) => {
        e.preventDefault();
        setError(false);
        dispatch(setSearchPostcode(postcode));
        changeValues({key: postcodeKey, value: postcode});
	};
    
    useEffect(() => {
        if (lat!==null && lng!==null){
            changeValues({[latKey]: lat, [lngKey]: lng});
        } else {
            setError(true);
        }
    }, [lat, lng]);
    
	return (
        <Form  onSubmit={onSubmit}>
            <Row>
                <Col>
                    <Form.Group controlId="postcode">
                        <Form.Control
                            required
                            type="name"
                            placeholder={currentPostcode!=="" ? currentPostcode : "Enter postcode"}
                            name="postcode"
                            value={postcode}
                            onChange={(e) => onChange(e)}
                            isInvalid={error}
                        />
                        <Form.Control.Feedback type="invalid">
                            Error locating postcode
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col>
                    <Button type="submit" variant="primary">
                        Search
                    </Button>
                </Col>
            </Row>
        </Form>
	);
}

PostcodeSearch.propTypes = {
    currentPostcode: PropTypes.string,
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    changeValues: PropTypes.func.isRequired,
    valueKeys: PropTypes.shape({
        postcodeKey: PropTypes.string,
        latKey: PropTypes.string,
        lngKey: PropTypes.string
    }),
};

PostcodeSearch.defaultProps = {
    currentPostcode: '',
    valueKeys: {
        postcodeKey: 'postcode',
        latKey: 'searchLatitude',
        lngKey: 'searchLongitude'
    }
}

const mapStateToProps = (state) => ({
    lat: state.search.lat,
    lng: state.search.lng,
});

export default connect(mapStateToProps, {})(PostcodeSearch);

import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { clinicianProfilesPropType } from '../../constants/propTypes';
import { getNameString, getAddressStr } from '../../actions/general';
import PaginationBar from '../app/PaginationBar';
import useAdminCliniciansList from '../../hooks/admin/useAdminCliniciansList';

function AdminCrpCliniciansTable({ cliniciansList }){
    const nCliniciansWindow = 20
    const start = 0;
    const end = nCliniciansWindow;
    const [clinList, setClinLsit] = useState([])


    useEffect(() => {
        if (cliniciansList === null || cliniciansList === undefined || cliniciansList.length === 0) {
            setClinLsit([]);
            return;
        }
        setClinLsit(cliniciansList);
    }, [cliniciansList])

    const [
        clinicians,
        updateCliniciansSlice,
        cliniciansLength
    ] = useAdminCliniciansList(clinList, start, end);

    const headersRow = (
        <tr>
            <th>Row</th>
            <th>Name</th>
            <th>email</th>
            <th>Role</th>
            <th>Centre</th>
            <th>Registration</th>
        </tr>
    )

    const noCliniciansDisplay = (
        <div className='center-box-parent'>
            <div className='center-box-child'>
                <h4 className='medr-text-inv'>No clinicians to display at the moment</h4>
            </div>
        </div>
    )

    return (
        <>
            {cliniciansLength===0 && noCliniciansDisplay}
            {cliniciansLength>0 && (
                    <Table striped bordered hover responsive className="table-sm">
                        <thead>
                            {headersRow}
                        </thead>
                        <tbody>
                            {clinicians.map((clinician, index) => (
                                <tr key={clinician.id}>
                                    <td>{index+1}</td>
                                    <td>{getNameString(clinician.userProfile)}</td>
                                    <td>{clinician.userProfile.user.email}</td>
                                    <td>{clinician.userProfile.jobRole[0].jobTitle}</td>
                                    <td>{clinician.clinic.centreName}, {getAddressStr(clinician.clinic.address)}</td>
                                    <td>{clinician.userProfile.jobRole.registrationBody}: {clinician.userProfile.registrationNumber}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            )}
            {cliniciansLength > nCliniciansWindow && (
                <PaginationBar arrayLength={cliniciansLength} setSliceValues={updateCliniciansSlice} windowSize={nCliniciansWindow}/>
            )}
        </>
		)
}

AdminCrpCliniciansTable.propTypes = {
	cliniciansList: clinicianProfilesPropType.isRequired,
};

const mapStateToProps = (state) => ({
	cliniciansList: state.admin.clinicians.clinicians,
});

export default connect(mapStateToProps, {})(AdminCrpCliniciansTable);

import React, { useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';
import { numberPropType, funcPropType, strPropType, patientsPropType } from "../../constants/propTypes";
import { getClinicianPatientList } from "../../actions/patientActions";
import { getPatientNameString, reformatDate } from "../../actions/general";


function PatientSearchBar({ currentPatient, changeValue, activePatients, pastPatients, acceptedPatients, valueKey }){

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getClinicianPatientList());
    }, [dispatch]);

    const [patients, setPatients] = useState([...activePatients, ...pastPatients, ...acceptedPatients]);
    const [options, setOptions] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState(null);

    useEffect(() => {setPatients([...activePatients, ...pastPatients, ...acceptedPatients]);}, [activePatients, pastPatients, acceptedPatients]);

    useEffect(() => {
        if (patients === null || patients === undefined || patients.length === 0) return;

        setOptions(patients.map((patient) => ({
            label:  (<><strong style={{fontWeight: 'bold' }}>{getPatientNameString(patient)}</strong> - DOB:{reformatDate(patient.dob)} - {patient.address.postcode}</>),
            value: `${getPatientNameString(patient)} - DOB:${reformatDate(patient.dob)} - ${patient.address.postcode}`,
            pid: patient.id,
        })));
    }, [patients])

    useEffect(() => {
        if (currentPatient === null || options===null || options===undefined || options.length===0) return;

        const px = options.find(option => option.value === currentPatient);
        if (px === null || px === undefined) return;

        setSelectedPatient(px);
    }, [options, currentPatient])

    useEffect(() => {
        if (selectedPatient === null || selectedPatient===undefined) return;
        if (selectedPatient.value === currentPatient) return;
        changeValue({key: valueKey, value: selectedPatient.value});
    }, [selectedPatient])
    const customStyles = {
        singleValue:(provided:any) => ({
          ...provided,
          color:'#247986',
        }),
      }
    return (
        <Select
            name='patient-search'
            options={options}
            value={selectedPatient}
            onChange={opt => setSelectedPatient({key: valueKey, value: opt.pid})}
            placeholder='Select patient'
            styles={customStyles}
        />
    )
}

PatientSearchBar.propTypes = {
    currentPatient: numberPropType,
    changeValue: funcPropType.isRequired,
    activePatients: patientsPropType.isRequired,
    pastPatients: patientsPropType.isRequired,
    acceptedPatients: patientsPropType.isRequired,
    valueKey: strPropType,
};

PatientSearchBar.defaultProps = {
    currentPatient: null,
    valueKey: 'patientId',
};

const mapStateToProps = state => ({
    activePatients: state.patients.activePatients,
    pastPatients: state.patients.pastPatients,
    acceptedPatients: state.patients.acceptedPatients,
})

export default connect(mapStateToProps, {})(PatientSearchBar);
import React, { useState } from "react";
import { Row, Col, Button } from 'react-bootstrap';
import InsuranceProviderForm from './InsuranceProviderForm';
import InsuranceSelectorGrid from "../user/InsuranceSelectorGrid";
import InsuranceDisplayBox from './InsuranceDisplayBox';
import { funcPropType, insuranceAgreementsPropType } from '../../constants/propTypes';


function InsuranceAgreementsForm({ newProviders, existingProviders, addExisitngProvider, addNewProvider, removeProvider }) {
    const [showNewProviderForm, setShowNewProviderForm] = useState(false);
    
    const toggleProvider = (e) => {
        if (e.isSelected){
            addExisitngProvider({value: e.value})
        } else {
            removeProvider({key: 'existingProvider', value: e.value})
        }
    }

    const removingProvider = (e) => {
        if (e.key==='newProvider'){
            removeProvider(e);
        } else {
            removeProvider({key: 'existingProvider', value: e.value});
        }
    }

	return (
        <>
            <Row>
                <Col>
                    <h4>Assign existing clinic</h4>
                </Col>
            </Row>
            
            <InsuranceSelectorGrid existingProviders={existingProviders} toggleProvider={(e) => toggleProvider(e)} />

            <Row>
                <Col>
                    <Button className='sel-button w80 my-4' onClick={() => setShowNewProviderForm(!showNewProviderForm)}>{showNewProviderForm ? "Hide form" : "Enter new provider"}</Button>
                </Col>
            </Row>

            {showNewProviderForm ? 
                <InsuranceProviderForm handleAddProvider={addNewProvider}/>
            : null}


            <InsuranceDisplayBox newProviders={newProviders} editable removeProvider={removingProvider}/>
        </>
	);
}

InsuranceAgreementsForm.propTypes = {
    newProviders: insuranceAgreementsPropType.isRequired,
    existingProviders: insuranceAgreementsPropType.isRequired,
	addExisitngProvider: funcPropType.isRequired,
	addNewProvider: funcPropType.isRequired,
	removeProvider: funcPropType.isRequired,
};

export default InsuranceAgreementsForm;
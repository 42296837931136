import * as CONSTS from '../constants/adminConstants';
import { LOGOUT_SUCCESS } from '../constants/authConstants';

const initialState = {
	loading: false,
	usersList: [],
	unverifiedUsersList: [],
	patientsList: [],
	referralsList: [],
	clinicians:{
		staff: [],
		nonVerified: [],
		clinicians: [],
	},
};

// eslint-disable-next-line default-param-last
export default function admin(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case CONSTS.ADMIN_USERS_LIST_REQUEST:
		case CONSTS.ADMIN_PATIENTS_LIST_REQUEST:
		case CONSTS.ADMIN_REFERRALS_LIST_REQUEST:
		case CONSTS.ADMIN_CLINICIANS_LIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case CONSTS.ADMIN_USERS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				usersList: payload.users,
				unverifiedUsersList: payload.unverifiedUsers,
			};

		case CONSTS.ADMIN_PATIENTS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				patientsList: payload.patients,
			};

		case CONSTS.ADMIN_REFERRALS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				referralsList: payload.referrals,
			};

		case CONSTS.ADMIN_CLINICIANS_LIST_SUCCESS:
			return {
				...state,
				loading: false,
				clinicians: payload,
			};
		case LOGOUT_SUCCESS:
			return {...initialState};
		case CONSTS.ADMIN_USERS_LIST_FAIL:
		case CONSTS.ADMIN_PATIENTS_LIST_FAIL:
		case CONSTS.ADMIN_REFERRALS_LIST_FAIL:
		case CONSTS.ADMIN_CLINICIANS_LIST_FAIL:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}

import React from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { boolPropType, funcPropType, numberPropType} from "../../../constants/propTypes";
import { postRequestRemoveCrp } from "../../../actions/clinicianActions";


function ProfileRemoveClinic({ userProfileId, clinicId, showPopup, handleClose }) {
    const dispatch = useDispatch();

    const requestMade = (value) => {
        if (value) {
            handleClose();
        }
    }

    const onClick = () => {
        dispatch(postRequestRemoveCrp({ clinicId, userProfileId }, requestMade));
        requestMade();
    }
    
    return (
        <Modal show={showPopup} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='medr-inv'>Request to remove clinic</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this clinic?</p>
                    <p>Click remove clinic to notify the MedR team.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={onClick}>
                        Remove Clinic
                    </Button>
                    <Button variant="info" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
    )
}


ProfileRemoveClinic.propTypes = {
    userProfileId: numberPropType.isRequired,
    clinicId: numberPropType.isRequired,
    showPopup: boolPropType.isRequired,
    handleClose: funcPropType.isRequired,
};

export default ProfileRemoveClinic;
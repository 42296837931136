import React from 'react';
import { clinicanProfileRankPropType } from '../../constants/propTypes';
import { getNameString, getClinicCenterStr } from '../../actions/general';

function ClinicianHoveredBar({ clinician }) {
    
    
    function missingClinician(){
        return (
            <div className='inner-map-clinician-bar'>
                <h5>Select pin to see clinician information</h5>
            </div>
        )
    };

    function clinicianSelected(){

        const profilePicture = clinician.userProfile.profilePicture!==null ? clinician.userProfile.profilePicture : "/logos/color1_logo_transparent_background.png";

        return (
            <>   
                <div className='map-clinician-bar-left'>
                        <img src={profilePicture} alt="Clinician profile" />
                </div>
                <div className='map-clinician-bar-right'>
                    <div className='inner-map-clinician-bar'>
                        <b>{getNameString(clinician.userProfile)}</b>
                    </div>
                    <div className='inner-map-clinician-bar'>
                        <div className='column' style={{width: '40%'}}>
                            {clinician.userProfile.jobRole.map((jr) => (
                                <p>{jr.jobTitle}</p>
                            ))}
                        </div>
                        <div className='column' style={{width: '40%'}}>          
                            <p>{getClinicCenterStr(clinician.clinic)}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
   

    return (
        <div className='outer-map-clinician-bar'>
            <div className="medr-rounded-inv map-clinician-bar">
                {clinician===null ? missingClinician() : clinicianSelected()}
            </div>
        </div>
    )
}


ClinicianHoveredBar.propTypes = {
    clinician: clinicanProfileRankPropType,
};

ClinicianHoveredBar.defaultProps = {
    clinician: null,
}

export default ClinicianHoveredBar;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MapView from './Map';
import { funcPropType, boolPropType, clinicianProfilesPropType, numberPropType } from '../../constants/propTypes';
import ClinicianCardList from './ClinicianCardList';
import { initPostcode } from '../../constants/initDataConstants';   
import ClinicianHoveredBar from './ClinicianHoveredBar';

function ClinicianMapBox({ onClinicianClick, enableClinicianSelect, searchLat, searchLng, searchZoom, cliniciansList, refreshClinicians, disablePinNavigate}) {

    const onClick = (e) => enableClinicianSelect ? onClinicianClick(e) : null;
    const [lat, setLat] = useState(searchLat);
    const [lng, setLng] = useState(searchLng);
    const [zoom, setZoom] = useState(searchZoom);
    const [clinicians, setClinicians] = useState(cliniciansList);
    const [hoveredOverClinician, setHoveredOverClinician] = useState(null);
    const [hoveredClinicianId, setHoveredClinicianId] = useState(null);

    useEffect(() => {
        setHoveredClinicianId(hoveredOverClinician ? hoveredOverClinician.id : null);
    }, [hoveredOverClinician]);

    useEffect(() => {
        setLat(searchLat);
        setLng(searchLng);
        setZoom(searchZoom);
        setClinicians(cliniciansList);
    }, [searchLat, searchLng, searchZoom, cliniciansList]);

    const hoveredClinician = (clinician) => setHoveredOverClinician(clinician);

    return (
        <>  
            <ClinicianHoveredBar clinician={hoveredOverClinician}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className='map-view-container'>
                            <MapView 
                                lat={lat} 
                                lng={lng} 
                                zoom={zoom} 
                                clinicians={clinicians} 
                                hoveredClinician={hoveredClinician}
                                hoveredClinicianId={hoveredClinicianId}
                                disablePinNavigate={disablePinNavigate}/>
                        </div>
                    </div>
                    <div className="col-md-4 overflow-y map-items-list">
                        <ClinicianCardList 
                            onButtonClick={onClick} 
                            disabled={!enableClinicianSelect} 
                            refreshClinicians={refreshClinicians} 
                            hoveredClinician={hoveredClinician}
                            hoveredClinicianId={hoveredClinicianId}/>
                    </div>
                </div>
            </div>
        </>
    )
}


ClinicianMapBox.propTypes = {
    enableClinicianSelect: boolPropType.isRequired,
    onClinicianClick: funcPropType.isRequired,
    searchLat: numberPropType,
    searchLng: numberPropType,
    searchZoom: numberPropType,
    cliniciansList: clinicianProfilesPropType,
    refreshClinicians: funcPropType.isRequired,
    disablePinNavigate: boolPropType
};

ClinicianMapBox.defaultProps = {
    searchLat: initPostcode.lat,
    searchLng: initPostcode.lng,
    searchZoom: initPostcode.zoom,
    cliniciansList: [],
    disablePinNavigate: false
}

const mapStateToProps = (state) => ({
	searchLat: state.search.lat,
    searchLng: state.search.lng,
    searchZoom: state.search.mapsZoom,
    cliniciansList: state.clinicians.clinicianList
});

export default connect(mapStateToProps, {})(ClinicianMapBox);
